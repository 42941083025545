export function noteURLs(noteProviderId?: string, noteOrganizationId?: string) {
    return [
        { 
            type: 'provider', creatUrl: `provider-notes/create/${noteProviderId}`,
            fileUrl: `/providers/${noteProviderId}/documents/verificationNotes/`,
            deleteUrl: 'provider-notes/'
        },
        { 
            type: 'provider-location', creatUrl: `provider-location-notes/${noteProviderId}`,
            fileUrl: `/providers/${noteProviderId}/documents/locationNotes/`,
            deleteUrl: 'provider-location-notes/'
        },
        { 
            type: 'status-history', creatUrl: `user-status-history-notes`,
            fileUrl: `/organization/${noteOrganizationId}/documents/statusHistory/notes/`,
            deleteUrl: 'user-status-history-notes/'
        },
        { 
            type: 'organization', creatUrl: `organization-info-notes`,
            fileUrl: `/organization/${noteOrganizationId}/documents/notes/`,
            deleteUrl: 'organization-info-notes/'
        },
        { 
            type: 'organization-location', creatUrl: `location-notes`,
            fileUrl: `/organization/${noteOrganizationId}/documents/locationNotes/`,
            deleteUrl: 'location-notes/'
        },
    ];
}