import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { IOption } from "src/app/view-models/ng-select-option";
import { ResetForm } from "../../../../utils/resetForm";

@Component({
  selector: "certification-dea",
  templateUrl: "dea.component.html",
  styleUrls: ["dea.component.scss"]
})
export class ProviderDEAComponent implements OnInit {
  @Input('userId') userId: string;
  DEAForm: FormGroup;
  DEAArray: FormArray;
  DEAValue: any[];
  attachments: string[];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  drugSchedulesOptions: Array<IOption> = this.selectOptionService.getDrugSchedules();
  /** Input of Component - DEAs */
  @Input()
  get DEAs() {
    let DEAArray = this.DEAForm.controls
      .DEAArray as FormArray;
    this.DEAArray = DEAArray;
    return this.DEAArray.value;
  }

  @Output()
  DEAsChange = new EventEmitter();

  set DEAs(val) {
    if (val) {
      if (val.length > 0) {
        this.DEAValue = val;
        this.DEAArray = this.DEA_Value(val);
        this.DEAForm = this.builder.group({
          DEAArray: this.DEAArray
        });
        this.DEAsChange.emit(this.DEAArray);
      } else {
        // this.AddDEA();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
    // add N/A to biggining of states:
    this.stateOptions.unshift({ label: 'N/A', value: 'N/A' });
  }

  /**
   * Create Form
   *  If DEAs does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing DEAs
   */
  CreateForm() {
    this.DEAArray = this.DEAArray ? this.DEAArray : this.builder.array([]);
    this.DEAForm = this.builder.group({
      DEAArray: this.DEAArray
    });

    if (this.DEAArray.length == 0)
      this.AddDEA();
    // else this.DEAValue(this.DEAValue)
  }

  // Coverage Plan Form Array
  get DEA() {
    return <FormArray>this.DEAArray;
  }

  /**
   * Create an empty form for a new DEA
   * @returns FormGroup
   */
  CreateDEA(info: any = null): FormGroup {
    let form = this.builder.group({
      deaNum: [info ? info.deaNum : "", Validators.required],
      deaXWaiver: [info ? info.deaXWaiver : ""],
      issuanceStateDEA: [info ? info.issuanceStateDEA : ""],
      issuanceDEA: [info ? info.issuanceDEA : "", Validators.required],
      isDeaWorkingInState: [info ? info.isDeaWorkingInState : true],
      expireDEA: [info ? info.expireDEA : null],
      stateDEA: [info ? info.stateDEA : "", Validators.required],
      deaDrugSchedule: [info ? info.deaDrugSchedule : []],
      attachments: [info ? info.attachments : []],
      isPrimary: [info ? info.isPrimary : null],
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new DEA to form array
   */
  AddDEA(): void {
    let DEAArray = this.DEAForm.controls
      .DEAArray as FormArray;
    DEAArray.push(this.CreateDEA());

    this.DEAArray = DEAArray;
    this.DEAsChange.emit(this.DEAArray);
  }

  /**
   * Remove a DEA from form array
   * @param index Index of the DEA to remove
   */
  RemoveDEA(index: number): void {
    let DEAArray = this.DEAForm.get("DEAArray") as FormArray;

    if (DEAArray.length > 1) {
      DEAArray.removeAt(index);
    } else {
      ResetForm.resetFormArray(DEAArray);
      DEAArray = this.builder.array([]);
    }
    this.DEAArray = DEAArray;
    this.DEAsChange.emit(this.DEAArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let DEAArray = this.DEAForm.controls
      .DEAArray as FormArray;
    this.DEAArray = DEAArray;
    this.DEAsChange.emit(this.DEAArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert DEA array to form array
   * @param informations JSON array of DEAs
   * @returns Array of FormGroup of DEAs
   */
  DEA_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          deaNum: [info.deaNum, Validators.required],
          deaXWaiver: [info.deaXWaiver],
          issuanceStateDEA: [info.issuanceStateDEA],
          issuanceDEA: [
            this.service.ConvertStringToDate(info.issuanceDEA),
            Validators.required
          ],
          isDeaWorkingInState: [info.expireDEA ? true : info.isDeaWorkingInState],
          expireDEA: [this.service.ConvertStringToDate(info.expireDEA)],
          stateDEA: [info.stateDEA, Validators.required],
          deaDrugSchedule: [info.deaDrugSchedule],
          attachments: [info.attachments],
          isPrimary: [info.isPrimary],
          id: [info.id],
          certificationId: [info.certificationId],
        })
      );
    });
    return formArray;
  }

  getDEAAttachments(index: number) {
    return this.DEAArray.length && this.DEAArray.at(index).get("attachments").value ? this.DEAArray.at(index).get("attachments").value : [];
  }

  onChangeDEAAttachment(event, index: number) {
    this.DEAArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

  checkDEADrugSchedule(index: number, item: string) {
    return this.DEAArray && this.DEAArray.length &&
      this.DEAArray.at(index).get("deaDrugSchedule").value && 
      this.DEAArray.at(index).get("deaDrugSchedule").value.indexOf(item) >-1 ? 
        true : false;
  }

  onChangeDEADrugSchedule(index: number, item: string) {
    let drugSchedules : string[] = this.DEAArray.at(index).get("deaDrugSchedule").value;
    if(!drugSchedules) drugSchedules = [];
    if(drugSchedules.indexOf(item) <= -1) drugSchedules.push(item);
    else drugSchedules.splice(drugSchedules.indexOf(item), 1);
    this.DEAArray.at(index).get("deaDrugSchedule").setValue(drugSchedules);
    this.onChange(event);
  }

  isPrimaryChange(event: any, index: number){
    event && this.setAllPrimariesFalse();
    this.DEAArray.at(index).get("isPrimary").setValue(event);
    this.onChange(event);
  }

  setAllPrimariesFalse(){
      for (var item in this.DEAArray.controls) {
          (this.DEAArray as FormArray).at(parseInt(item)).patchValue({
              isPrimary: false
          });
      }
  }

}
