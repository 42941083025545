import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "certification-clia",
  templateUrl: "clia.component.html",
  styleUrls: ["clia.component.scss"]
})
export class ProviderCLIAComponent implements OnInit {
  @Input('userId') userId: string;
  CLIAForm: FormGroup;
  CLIAArray: FormArray;
  CLIAValue: any[];
  attachments: string[];
  /** Input of Component - CLIAs */
  @Input()
  get CLIAs() {
    let CLIAArray = this.CLIAForm.controls
      .CLIAArray as FormArray;
    this.CLIAArray = CLIAArray;
    return this.CLIAArray.value;
  }

  @Output()
  CLIAsChange = new EventEmitter();

  set CLIAs(val) {
    if (val) {
      if (val.length > 0) {
        this.CLIAValue = val;
        this.CLIAArray = this.CLIA_Value(val);
        this.CLIAForm = this.builder.group({
          CLIAArray: this.CLIAArray
        });
        this.CLIAsChange.emit(this.CLIAArray);
      } else {
        // this.AddCLIA();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If CLIAs does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing CLIAs
   */
  CreateForm() {
    this.CLIAArray = this.CLIAArray ? this.CLIAArray : this.builder.array([]);
    this.CLIAForm = this.builder.group({
      CLIAArray: this.CLIAArray
    });

    if (this.CLIAArray.length == 0)
      this.AddCLIA();
    // else this.CLIAValue(this.CLIAValue)
  }

  // Coverage Plan Form Array
  get CLIA() {
    return <FormArray>this.CLIAArray;
  }

  /**
   * Create an empty form for a new CLIA
   * @returns FormGroup
   */
  CreateCLIA(info: any = null): FormGroup {
    let form = this.builder.group({
      issuanceCLIA: [info ? info.issuanceCLIA : ""],
      numberCLIA: [info ? info.numberCLIA : "", Validators.required],
      attachments: [info ? info.attachments : []],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new CLIA to form array
   */
  AddCLIA(): void {
    let CLIAArray = this.CLIAForm.controls
      .CLIAArray as FormArray;
    CLIAArray.push(this.CreateCLIA());

    this.CLIAArray = CLIAArray;
    this.CLIAsChange.emit(this.CLIAArray);
  }

  /**
   * Remove a CLIA from form array
   * @param index Index of the CLIA to remove
   */
  RemoveCLIA(index: number): void {
    let CLIAArray = this.CLIAForm.get("CLIAArray") as FormArray;

    if (CLIAArray.length > 1) {
      CLIAArray.removeAt(index);
    } else {
      CLIAArray = this.builder.array([]);
    }
    this.CLIAArray = CLIAArray;
    this.CLIAsChange.emit(this.CLIAArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let CLIAArray = this.CLIAForm.controls
      .CLIAArray as FormArray;
    this.CLIAArray = CLIAArray;
    this.CLIAsChange.emit(this.CLIAArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert CLIA array to form array
   * @param informations JSON array of CLIAs
   * @returns Array of FormGroup of CLIAs
   */
  CLIA_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          issuanceCLIA: [this.service.ConvertStringToDate(info.issuanceCLIA)],
          numberCLIA: [info.numberCLIA, Validators.required],
          attachments: [info.attachments],
        })
      );
    });
    return formArray;
  }

  getCLIAAttachments(index: number) {
    return this.CLIAArray.at(index).get("attachments").value ? this.CLIAArray.at(index).get("attachments").value : [];
  }

  onChangeCLIAAttachment(event, index: number) {
    console.log(event);
    this.CLIAArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
