import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import * as _ from 'lodash'
import {FormArray, FormGroup} from '@angular/forms';
import {STATES_NAMES} from './app/constants';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { GoogleAddress } from './app/view-models/google-address';


declare const $: any;

export const PROVIDER = 'PROVIDER';
export const ORGANIZATION = 'ORGANIZATION';
export const PROVIDER_AGENT = 'PROVIDER AGENT';

export const getModelValues = model => {
  for (const key in model) {
    if (!model[key] && model[key] !== false) {
      delete model[key];
    }
  }

  return model;
};

export const convertUndefinedToNull = fields =>
  fields.map(
    field => (field == 'undefined' || _.isEmpty(field) ? null : field)
  );

export const dateToString = ({
                               date: {year = '', month = '', day = ''} = {}
                             }) => `${year}-${month}-${day}`;

export const stringToDate = (date = '') => {
  return new Date(date);
};

export const removeEmptyValues = modal => {
  for (const key in modal) {
    if (_.isString(modal[key]) && _.isEmpty(modal[key])) {
      delete modal[key];
    }
  }

  return modal;
};

export const removeRequiredFromDate = (form, fields = []) => {
  fields.map(field => {
    if (form && form.controls[field]) {
      form.controls[field].setErrors(null);
    }
  });
};

export const RemoveHourDate = (form: any, data: any, fields: any) => {
  const tempForm = form.value;
  for(let field of fields) {
    if(tempForm[field]) {
      data[field] = moment(tempForm[field]).locale('en').format('YYYY/MM/DD');

    }
  }
}

export const getType = type => {
  switch (type) {
    case 'State Controlled Substance Certificate':
      return 'CDS Certificate';
    case 'certificates of training':
      return 'Professional License';
    case 'Immunization Records':
    case 'Health Attestation':
      return 'Health Attestation/TB/Immunization';
    case 'Medical School Degree':
      return 'Professional Degree and Training';
    default:
      return type;
  }
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'Active',
  SUSPEND: 'Suspend'
};

export const CREDIT_CARD_STATUSES = {
  VALID: 'Valid',
  EXPIRY: 'Expiry',
  EXPIRY_SOON: 'Expiry Soon',
  EXPIRED: 'Expired'
};

const COMPONENT_FORM = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name'
};

export const scrollToId = id => {
  if (!$(`#${id}`).offset()) {
    return;
  }

  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $(`#${id}`).offset().top
    },
    2000
  );
};

export const assignRole = role => {
  if (role === 'provider') {
    return PROVIDER;
  } else if (role === 'organization') {
    return ORGANIZATION;
  } else if (role === 'providerAgent') {
    return PROVIDER_AGENT;
  }
  return role;
};

export const redirectSummary = {
  'Personal Information': 'personal-info',
  'Educational Information': 'educational-info',
  Training: 'training',
  Certification: 'certification',
  Specialities: 'specialities',
  'Current Privilege': 'current-privilege',
  'Primary Practice': 'primary-practise',
  'Work History': 'work-history',
  Insurance: 'professional-liability-insurance',
  'Disclosure Questions': 'disclosure-questions'
};

export const isFormGroupValid = (formGroup) => {
  if (!formGroup) {
    return false;
  }
  const {controls = {}} = formGroup;
  const formStatusArray = Object.keys(controls).map(key => {
    const formElement = formGroup.get(key);
    if (formElement) {
      if (!formElement.valid && formElement._status !== 'DISABLED') {
        return false;
      } else if (formElement instanceof FormGroup || formElement instanceof FormArray) {
        return isFormGroupValid(formElement)
      }
    }
    return true;
  })

  if (formStatusArray.includes(false)) {
    return false;
  }

  formGroup._status = 'VALID';
  return true
}

export const redirecNotifications = {
  'Peer Request': 'references',
  'Certification': 'common-form/certification'
};

export const navigateToUrl = (router, url, timeout) => {
  setTimeout(() => {
    router.navigate([url]);
  }, timeout);
}

export const getFormData = (data = {}) => {
  const formData: FormData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }

  return formData
}

// export const handleAddressChange = (address_components, ADDRESS_FIELDS, model) => {
//   for (let i = 0; i < address_components.length; i++) {
//     const addressType = address_components[i].types[0];
//     if (COMPONENT_FORM[addressType]) {
//       if (addressType === 'administrative_area_level_1') {
//         model[ADDRESS_FIELDS[addressType]] = address_components[i][COMPONENT_FORM[addressType]];
//       } else if(addressType === 'street_number') {
//         model[ADDRESS_FIELDS[addressType]] = address_components[i][COMPONENT_FORM[addressType]];
//       } else if (addressType === 'route') {
//         model[ADDRESS_FIELDS['street_number']] = model[ADDRESS_FIELDS['street_number']] + ' ' + address_components[i][COMPONENT_FORM[addressType]];
//       } else {
//         model[ADDRESS_FIELDS[addressType]] = address_components[i][COMPONENT_FORM[addressType]];
//       }
//     }
//   }
//   return model;
// }
export const HandleAddressChange = (address_components, ADDRESS_FIELDS) => {
  let model: GoogleAddress = {
    addressStreet: '',
    addressApartment: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
    addressCountry: '',
    name:''
  }
  for(let item of address_components) {
    let addressType = item.types[0];
    if (COMPONENT_FORM[addressType]) {
      if (addressType === 'administrative_area_level_1') {
        model[ADDRESS_FIELDS[addressType]] = item[COMPONENT_FORM[addressType]];
      } else if(addressType === 'street_number') {
        model[ADDRESS_FIELDS[addressType]] = item[COMPONENT_FORM[addressType]];
      } else if (addressType === 'route') {
        model[ADDRESS_FIELDS['street_number']] = model[ADDRESS_FIELDS['street_number']] + ' ' + item[COMPONENT_FORM[addressType]];
      } else if (addressType === 'country') {
        model[ADDRESS_FIELDS['country']] = model[ADDRESS_FIELDS['country']] + ' ' + item[COMPONENT_FORM[addressType]];
      } else {
        model[ADDRESS_FIELDS[addressType]] = item[COMPONENT_FORM[addressType]];
      }
    }
  }
  return model;
}

export const getDocumentTypes = () => {
  return [
    {'docType': 'Medical Degree'},
    {'docType': 'Medical License'},
    {'docType': 'Specialty Boards'},
    {'docType': 'DEA'},
    {'docType': 'State CDS'},
    {'docType': 'Malpractice'}
  ];
}

export const GetAdditionalDocumentTypes = () => {
  return [
    {'docType': 'Current Privileges'},
    {'docType': 'Work History'},
    {'docType': 'Misc'},
  ];
}
