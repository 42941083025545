import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Faq } from '../view-models/faq';


@Injectable()
export class FaqsService {
  constructor(public http: HttpClient) {
  }

  get() {
    return this.http.get(`${environment.API_URL}faqs`).toPromise()
      .then((res: Faq[]) => {
        return res;
      });
  }
}