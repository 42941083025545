import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  ALL_BOARDS,
  ALL_SPECIALTIES,
  Allied_Health_BOARD,
  ALLIED_PROVIDER_TYPE,
  ASSIGNS,
  COUNTRIES,
  CREDENTIAL_STATUS,
  DC,
  DC_BOARD,
  DDS_DMD,
  DDS_DMD_BOARD,
  DPM,
  DPM_BOARD,
  FACILITY_PERSONAL_TYPE,
  FACILITY_TYPE,
  LANGUAGE_CODES,
  MD_DO,
  MD_DO_BOARD,
  MD_DP_DC,
  PRIVILEGETYPE,
  PROVIDER_STATUSES,
  PROVIDER_TYPE,
  REASON,
  STATES_NAMES,
  STATUS_CODE_LICENSE,
  MILITARY_BRANCHES,
  DRUG_SCHEDULES_TYPE,
  PG_DEGREES,
  DEPARTMENTS,
  UG_DEGREES,
  HS_DEGREES,
  CREDENTIAL_ITEMS,
  DRUG_SCHEDULES_TYPE_CDS
} from "../constants";
import {IOption} from "../view-models/ng-select-option";


@Injectable()
export class SelectOptionService {
  private static readonly US_SCHOOLS = [
    {
      value: 'N/A',
      label: 'N/A',
    },
    {
      value: 'University of Alabama School of Dentistry',
      label: 'University of Alabama School of Dentistry',
    },
    {
      value: 'University of Alabama School of Medicine',
      label: 'University of Alabama School of Medicine',
    },
    {
      value: 'Univ of South Alabama College of Medicine',
      label: 'Univ of South Alabama College of Medicine',
    },
    {
      value: 'University of Arkansas College of Medicine',
      label: 'University of Arkansas College of Medicine',
    },
    {
      value: 'Arizona College of Osteopathic Medicine',
      label: 'Arizona College of Osteopathic Medicine',
    },
    {
      value: 'University of Arizona College of Medicine',
      label: 'University of Arizona College of Medicine',
    },
    {
      value: 'California College of Podiatric Medicine',
      label: 'California College of Podiatric Medicine',
    },
    {
      value: 'Cleveland Chiropractic Coll of Los Angeles',
      label: 'Cleveland Chiropractic Coll of Los Angeles',
    },
    {
      value: 'Keck School of Medicine',
      label: 'Keck School of Medicine',
    },
    {
      value: 'Life Chiropractic College West',
      label: 'Life Chiropractic College West',
    },
    {
      value: 'Loma Linda University School of Dentistry',
      label: 'Loma Linda University School of Dentistry',
    },
    {
      value: 'Loma Linda University School of Medicine',
      label: 'Loma Linda University School of Medicine',
    },
    {
      value: 'Los Angeles College of Chiropractic',
      label: 'Los Angeles College of Chiropractic',
    },
    {
      value: 'Palmer College of Chiropractic West',
      label: 'Palmer College of Chiropractic West',
    },
    {
      value: 'Quantum University/SCCC',
      label: 'Quantum University/SCCC',
    },
    {
      value: 'Stanford University School of Medicine',
      label: 'Stanford University School of Medicine',
    },
    {
      value: 'Touro Univ College of Osteopathic Medicine',
      label: 'Touro Univ College of Osteopathic Medicine',
    },
    {
      value: 'UCLA School of Medicine',
      label: 'UCLA School of Medicine',
    },
    {
      value: 'University of California',
      label: 'University of California',
    },
    {
      value: 'Univ of Calif, Irvine, College of Medicine',
      label: 'Univ of Calif, Irvine, College of Medicine',
    },
    {
      value: 'Univ of Calif, Los Angeles School of Dent',
      label: 'Univ of Calif, Los Angeles School of Dent',
    },
    {
      value: 'Univ of Calif, San Diego, School of Medicine',
      label: 'Univ of Calif, San Diego, School of Medicine',
    },
    {
      value: 'Univ of Calif, San Francisco, School of Dent',
      label: 'Univ of Calif, San Francisco, School of Dent',
    },
    {
      value: 'Univ of Calif, San Francisco, School of Med',
      label: 'Univ of Calif, San Francisco, School of Med',
    },
    {
      value: 'Univ of Southern Calif School of Dentistry',
      label: 'Univ of Southern Calif School of Dentistry',
    },
    {
      value: 'University of the Pacific School of Dentistry',
      label: 'University of the Pacific School of Dentistry',
    },
    {
      value: 'W. Univ of Health Sci, Osteo Med of Pacific',
      label: 'W. Univ of Health Sci, Osteo Med of Pacific',
    },
    {
      value: 'University of Colorado School of Dentistry',
      label: 'University of Colorado School of Dentistry',
    },
    {
      value: 'University of Colorado School of Medicine',
      label: 'University of Colorado School of Medicine',
    },
    {
      value: 'Univ of Bridgeport College of Chiropractic',
      label: 'Univ of Bridgeport College of Chiropractic',
    },
    {
      value: 'Univ of Connecticut School of Dental Med',
      label: 'Univ of Connecticut School of Dental Med',
    },
    {
      value: 'Univ of Connecticut School of Medicine',
      label: 'Univ of Connecticut School of Medicine',
    },
    {
      value: 'Yale University School of Medicine',
      label: 'Yale University School of Medicine',
    },
    {
      value: 'George Washington University',
      label: 'George Washington University',
    },
    {
      value: 'Georgetown University School of Medicine',
      label: 'Georgetown University School of Medicine',
    },
    {
      value: 'Howard University College of Dentistry',
      label: 'Howard University College of Dentistry',
    },
    {
      value: 'Howard University College of Medicine',
      label: 'Howard University College of Medicine',
    },
    {
      value: 'Barry Univ School of Graduate Med Sciences',
      label: 'Barry Univ School of Graduate Med Sciences',
    },
    {
      value: 'Nova Southeastern Univ College of Dentistry',
      label: 'Nova Southeastern Univ College of Dentistry',
    },
    {
      value: 'Nova Southeastern Univ Coll of Osteo Med',
      label: 'Nova Southeastern Univ Coll of Osteo Med',
    },
    {
      value: 'University of Florida College of Dentistry',
      label: 'University of Florida College of Dentistry',
    },
    {
      value: 'University of Florida College of Medicine',
      label: 'University of Florida College of Medicine',
    },
    {
      value: 'University of Miami School of Medicine',
      label: 'University of Miami School of Medicine',
    },
    {
      value: 'Univ of South Florida College of Medicine',
      label: 'Univ of South Florida College of Medicine',
    },
    {
      value: 'Emory University School of Medicine',
      label: 'Emory University School of Medicine',
    },
    {
      value: 'Life Chiropractic College',
      label: 'Life Chiropractic College',
    },
    {
      value: 'Medical College of GA School of Dentistry',
      label: 'Medical College of GA School of Dentistry',
    },
    {
      value: 'Medical College of GA School of Medicine',
      label: 'Medical College of GA School of Medicine',
    },
    {
      value: 'Mercer University School of Medicine',
      label: 'Mercer University School of Medicine',
    },
    {
      value: 'Morehouse School of Medicine',
      label: 'Morehouse School of Medicine',
    },
    {
      value: 'John A. Burns School of Medicine',
      label: 'John A. Burns School of Medicine',
    },
    {
      value: 'Coll of Podiatric Med/Surg Des Moines Univ',
      label: 'Coll of Podiatric Med/Surg Des Moines Univ',
    },
    {
      value: 'Des Moines Univ, Coll of Osteo/Med Surg',
      label: 'Des Moines Univ, Coll of Osteo/Med Surg',
    },
    {
      value: 'Palmer College of Chiropractic',
      label: 'Palmer College of Chiropractic',
    },
    {
      value: 'University of Iowa College of Dentistry',
      label: 'University of Iowa College of Dentistry',
    },
    {
      value: 'University of Iowa College of Medicine',
      label: 'University of Iowa College of Medicine',
    },
    {
      value: 'Chicago Med School, Finch Univ Health Sci',
      label: 'Chicago Med School, Finch Univ Health Sci',
    },
    {
      value: 'Loyola Univ Chicago, Stritch School of Med',
      label: 'Loyola Univ Chicago, Stritch School of Med',
    },
    {
      value: 'Midwestern Univ, Chicago Coll Osteo Med',
      label: 'Midwestern Univ, Chicago Coll Osteo Med',
    },
    {
      value: 'National College of Chiropractic',
      label: 'National College of Chiropractic',
    },
    {
      value: 'Northwestern University Dental School',
      label: 'Northwestern University Dental School',
    },
    {
      value: 'Northwestern University Medical School',
      label: 'Northwestern University Medical School',
    },
    {
      value: 'Rush Medical College of Rush University',
      label: 'Rush Medical College of Rush University',
    },
    {
      value: 'Scholl College of Podiatric Med-Finch Univ',
      label: 'Scholl College of Podiatric Med-Finch Univ',
    },
    {
      value: 'Southern Illinois Univ School of Dental Med',
      label: 'Southern Illinois Univ School of Dental Med',
    },
    {
      value: 'Southern Illinois Univ School of Medicine',
      label: 'Southern Illinois Univ School of Medicine',
    },
    {
      value: 'Univ of Chicago, The Pritzker School of Med',
      label: 'Univ of Chicago, The Pritzker School of Med',
    },
    {
      value: 'Univ of Illinois-Chicago College of Dentistry',
      label: 'Univ of Illinois-Chicago College of Dentistry',
    },
    {
      value: 'University of Illinois College of Medicine',
      label: 'University of Illinois College of Medicine',
    },
    {
      value: 'Indiana University School of Dentistry',
      label: 'Indiana University School of Dentistry',
    },
    {
      value: 'Indiana University School of Medicine',
      label: 'Indiana University School of Medicine',
    },
    {
      value: 'University of Kansas School of Medicine',
      label: 'University of Kansas School of Medicine',
    },
    {
      value: 'Pikeville College, School of Osteo Medicine',
      label: 'Pikeville College, School of Osteo Medicine',
    },
    {
      value: 'University of Kentucky College of Dentistry',
      label: 'University of Kentucky College of Dentistry',
    },
    {
      value: 'University of Kentucky College of Medicine',
      label: 'University of Kentucky College of Medicine',
    },
    {
      value: 'University of Louisville School of Dentistry',
      label: 'University of Louisville School of Dentistry',
    },
    {
      value: 'University of Louisville School of Medicine',
      label: 'University of Louisville School of Medicine',
    },
    {
      value: 'Louisiana State Univ School of Dentistry',
      label: 'Louisiana State Univ School of Dentistry',
    },
    {
      value: 'Louisiana State Univ School of Med-NOLA',
      label: 'Louisiana State Univ School of Med-NOLA',
    },
    {
      value: 'Louisiana State School of Med-Shreveport',
      label: 'Louisiana State School of Med-Shreveport',
    },
    {
      value: 'Tulane Univ School of Medicine',
      label: 'Tulane Univ School of Medicine',
    },
    {
      value: 'Boston University School of Medicine',
      label: 'Boston University School of Medicine',
    },
    {
      value: 'Boston Univ, Goldman School of Dent Med',
      label: 'Boston Univ, Goldman School of Dent Med',
    },
    {
      value: 'Harvard Medical School',
      label: 'Harvard Medical School',
    },
    {
      value: 'Harvard School of Dental Medicine',
      label: 'Harvard School of Dental Medicine',
    },
    {
      value: 'Tufts University School of Dental Medicine',
      label: 'Tufts University School of Dental Medicine',
    },
    {
      value: 'Tufts University School of Medicine',
      label: 'Tufts University School of Medicine',
    },
    {
      value: 'University of Massachusetts Medical School',
      label: 'University of Massachusetts Medical School',
    },
    {
      value: 'Johns Hopkins Univ School of Medicine',
      label: 'Johns Hopkins Univ School of Medicine',
    },
    {
      value: 'Uniformed Services Univ of Health Sciences',
      label: 'Uniformed Services Univ of Health Sciences',
    },
    {
      value: 'University of Maryland School of Medicine',
      label: 'University of Maryland School of Medicine',
    },
    {
      value: 'Univ of MD-Baltimore, Coll of Dent Surg',
      label: 'Univ of MD-Baltimore, Coll of Dent Surg',
    },
    {
      value: 'Univ of New England, Coll of Osteo Med',
      label: 'Univ of New England, Coll of Osteo Med',
    },
    {
      value: 'Michigan State Univ College of Human Med',
      label: 'Michigan State Univ College of Human Med',
    },
    {
      value: 'Michigan State Univ, College of Osteo Med',
      label: 'Michigan State Univ, College of Osteo Med',
    },
    {
      value: 'University of Detroit Mercy School of Dent',
      label: 'University of Detroit Mercy School of Dent',
    },
    {
      value: 'University of Michigan Medical School',
      label: 'University of Michigan Medical School',
    },
    {
      value: 'University of Michigan School of Dentistry',
      label: 'University of Michigan School of Dentistry',
    },
    {
      value: 'Wayne State Univ School of Medicine',
      label: 'Wayne State Univ School of Medicine',
    },
    {
      value: 'Mayo Medical School',
      label: 'Mayo Medical School',
    },
    {
      value: 'Northwestern College of Chiropractic',
      label: 'Northwestern College of Chiropractic',
    },
    {
      value: 'Univ of Minnesota, Duluth School of Med',
      label: 'Univ of Minnesota, Duluth School of Med',
    },
    {
      value: 'Univ of Minnesota Med School, Twin Cities',
      label: 'Univ of Minnesota Med School, Twin Cities',
    },
    {
      value: 'University of Minnesota School of Dentistry',
      label: 'University of Minnesota School of Dentistry',
    },
    {
      value: 'Cleveland Chiropractic Coll of Kansas City',
      label: 'Cleveland Chiropractic Coll of Kansas City',
    },
    {
      value: 'Kirksville College of Osteopathic Medicine',
      label: 'Kirksville College of Osteopathic Medicine',
    },
    {
      value: 'Logan Chiropractic College',
      label: 'Logan Chiropractic College',
    },
    {
      value: 'Saint Louis University School of Medicine',
      label: 'Saint Louis University School of Medicine',
    },
    {
      value: 'Univ of Health Sciences, Coll of Osteo Med',
      label: 'Univ of Health Sciences, Coll of Osteo Med',
    },
    {
      value: 'Univ of Missouri, Columbia School of Med',
      label: 'Univ of Missouri, Columbia School of Med',
    },
    {
      value: 'Univ of Missouri Kansas City School of Dent',
      label: 'Univ of Missouri Kansas City School of Dent',
    },
    {
      value: 'Univ of Missouri Kansas City School of Med',
      label: 'Univ of Missouri Kansas City School of Med',
    },
    {
      value: 'Washington Univ in St. Louis School of Med',
      label: 'Washington Univ in St. Louis School of Med',
    },
    {
      value: 'University of Mississippi School of Dentistry',
      label: 'University of Mississippi School of Dentistry',
    },
    {
      value: 'University of Mississippi School of Medicine',
      label: 'University of Mississippi School of Medicine',
    },
    {
      value: 'Duke University School of Medicine',
      label: 'Duke University School of Medicine',
    },
    {
      value: 'The Brody School of Med-East Carolina Univ',
      label: 'The Brody School of Med-East Carolina Univ',
    },
    {
      value: 'Univ of NC at Chapel Hill School of Dentistry',
      label: 'Univ of NC at Chapel Hill School of Dentistry',
    },
    {
      value: 'Univ of NC at Chapel Hill School of Med',
      label: 'Univ of NC at Chapel Hill School of Med',
    },
    {
      value: 'Wake Forest University School of Medicine',
      label: 'Wake Forest University School of Medicine',
    },
    {
      value: 'Univ of ND School of Med/Health Sci',
      label: 'Univ of ND School of Med/Health Sci',
    },
    {
      value: 'Creighton University School of Dentistry',
      label: 'Creighton University School of Dentistry',
    },
    {
      value: 'Creighton University School of Medicine',
      label: 'Creighton University School of Medicine',
    },
    {
      value: 'University of Nebraska College of Medicine',
      label: 'University of Nebraska College of Medicine',
    },
    {
      value: 'Univ of Nebraska Med Center, Coll of Dent',
      label: 'Univ of Nebraska Med Center, Coll of Dent',
    },
    {
      value: 'Dartmouth Medical School',
      label: 'Dartmouth Medical School',
    },
    {
      value: 'Robert Wood Johnson Medical School',
      label: 'Robert Wood Johnson Medical School',
    },
    {
      value: 'University of Med and Dent of New Jersey',
      label: 'University of Med and Dent of New Jersey',
    },
    {
      value: 'UMDNJ, New Jersey Dental School',
      label: 'UMDNJ, New Jersey Dental School',
    },
    {
      value: 'UMDNJ, School of Osteopathic Medicine',
      label: 'UMDNJ, School of Osteopathic Medicine',
    },
    {
      value: 'Univ of New Mexico School of Medicine',
      label: 'Univ of New Mexico School of Medicine',
    },
    {
      value: 'University of Nevada School of Medicine',
      label: 'University of Nevada School of Medicine',
    },
    {
      value: 'Albany Medical College',
      label: 'Albany Medical College',
    },
    {
      value: 'Albert Einstein College of Medicine',
      label: 'Albert Einstein College of Medicine',
    },
    {
      value: 'Columbia Univ College of Phys/Surgeons',
      label: 'Columbia Univ College of Phys/Surgeons',
    },
    {
      value: 'Columbia Univ School of Dent/Oral Surgery',
      label: 'Columbia Univ School of Dent/Oral Surgery',
    },
    {
      value: 'Joan & Sanford I. Weill-Cornell Univ',
      label: 'Joan & Sanford I. Weill-Cornell Univ',
    },
    {
      value: 'Mount Sinai School of Med of NYU',
      label: 'Mount Sinai School of Med of NYU',
    },
    {
      value: 'New York Chiropractic College',
      label: 'New York Chiropractic College',
    },
    {
      value: 'NY College of Osteo Med-NY Inst of Tech',
      label: 'NY College of Osteo Med-NY Inst of Tech',
    },
    {
      value: 'New York Medical College',
      label: 'New York Medical College',
    },
    {
      value: 'New York University Kriser Dental Center',
      label: 'New York University Kriser Dental Center',
    },
    {
      value: 'New York University School of Medicine',
      label: 'New York University School of Medicine',
    },
    {
      value: 'State Univ NY-Buffalo School of Dent Med',
      label: 'State Univ NY-Buffalo School of Dent Med',
    },
    {
      value: 'State Univ of NY-Buffalo School of Medicine',
      label: 'State Univ of NY-Buffalo School of Medicine',
    },
    {
      value: 'State Univ of NY-Stony Brook Dent School',
      label: 'State Univ of NY-Stony Brook Dent School',
    },
    {
      value: 'State Univ of NY-Stony Brook School of Med',
      label: 'State Univ of NY-Stony Brook School of Med',
    },
    {
      value: 'State Univ of New York College of Medicine',
      label: 'State Univ of New York College of Medicine',
    },
    {
      value: 'State Univ of NY Upstate Medical University',
      label: 'State Univ of NY Upstate Medical University',
    },
    {
      value: 'Univ of Rochester School of Med and Dent',
      label: 'Univ of Rochester School of Med and Dent',
    },
    {
      value: 'Case Western Reserve Univ School of Dent',
      label: 'Case Western Reserve Univ School of Dent',
    },
    {
      value: 'Case Western Reserve Univ School of Med',
      label: 'Case Western Reserve Univ School of Med',
    },
    {
      value: 'Medical College of Ohio',
      label: 'Medical College of Ohio',
    },
    {
      value: 'Northeastern Ohio Univ College of Medicine',
      label: 'Northeastern Ohio Univ College of Medicine',
    },
    {
      value: 'Ohio College of Podiatric Medicine',
      label: 'Ohio College of Podiatric Medicine',
    },
    {
      value: 'Ohio State University College of Dentistry',
      label: 'Ohio State University College of Dentistry',
    },
    {
      value: 'Ohio State Univ College of Med/Pub Health',
      label: 'Ohio State Univ College of Med/Pub Health',
    },
    {
      value: 'Ohio Univ College of Osteopathic Medicine',
      label: 'Ohio Univ College of Osteopathic Medicine',
    },
    {
      value: 'University of Cincinnati College of Medicine',
      label: 'University of Cincinnati College of Medicine',
    },
    {
      value: 'Wright State Univ School of Medicine',
      label: 'Wright State Univ School of Medicine',
    },
    {
      value: 'Oklahoma State Univ, Osteopathic Med',
      label: 'Oklahoma State Univ, Osteopathic Med',
    },
    {
      value: 'University of Oklahoma College of Dentistry',
      label: 'University of Oklahoma College of Dentistry',
    },
    {
      value: 'University of Oklahoma College of Medicine',
      label: 'University of Oklahoma College of Medicine',
    },
    {
      value: 'Oregon Health & Science School of Med',
      label: 'Oregon Health & Science School of Med',
    },
    {
      value: 'Oregon Health Sciences School of Dentistry',
      label: 'Oregon Health Sciences School of Dentistry',
    },
    {
      value: 'Western States Chiropractic College',
      label: 'Western States Chiropractic College',
    },
    {
      value: 'Jefferson Medical College of TJU',
      label: 'Jefferson Medical College of TJU',
    },
    {
      value: 'Lake Erie College of Osteopathic Medicine',
      label: 'Lake Erie College of Osteopathic Medicine',
    },
    {
      value: 'MCP Hahnemann Univ School of Medicine',
      label: 'MCP Hahnemann Univ School of Medicine',
    },
    {
      value: 'Pennsylvania State Univ College of Medicine',
      label: 'Pennsylvania State Univ College of Medicine',
    },
    {
      value: 'Philadelphia College of Osteo Medicine',
      label: 'Philadelphia College of Osteo Medicine',
    },
    {
      value: 'Temple University School of Dentistry',
      label: 'Temple University School of Dentistry',
    },
    {
      value: 'Temple University School of Medicine',
      label: 'Temple University School of Medicine',
    },
    {
      value: 'Temple Univ School of Podiatric Medicine',
      label: 'Temple Univ School of Podiatric Medicine',
    },
    {
      value: 'Univ of Pennsylvania School of Dental Med',
      label: 'Univ of Pennsylvania School of Dental Med',
    },
    {
      value: 'Univ of Pennsylvania School of Medicine',
      label: 'Univ of Pennsylvania School of Medicine',
    },
    {
      value: 'Univ of Pittsburgh School of Dental Med',
      label: 'Univ of Pittsburgh School of Dental Med',
    },
    {
      value: 'University of Pittsburgh School of Medicine',
      label: 'University of Pittsburgh School of Medicine',
    },
    {
      value: 'Ponce School of Medicine',
      label: 'Ponce School of Medicine',
    },
    {
      value: 'Univ Central del Caribe School of Medicine',
      label: 'Univ Central del Caribe School of Medicine',
    },
    {
      value: 'University of Puerto Rico School of Medicine',
      label: 'University of Puerto Rico School of Medicine',
    },
    {
      value: 'University of Puerto Rico School of Dentistry',
      label: 'University of Puerto Rico School of Dentistry',
    },
    {
      value: 'Brown Medical School',
      label: 'Brown Medical School',
    },
    {
      value: 'Medical Univ of SC College of Dental Med',
      label: 'Medical Univ of SC College of Dental Med',
    },
    {
      value: 'Medical Univ of SC College of Medicine',
      label: 'Medical Univ of SC College of Medicine',
    },
    {
      value: 'Sherman College of Chiropractic',
      label: 'Sherman College of Chiropractic',
    },
    {
      value: 'Univ of South Carolina School of Medicine',
      label: 'Univ of South Carolina School of Medicine',
    },
    {
      value: 'Univ of South Dakota School of Medicine',
      label: 'Univ of South Dakota School of Medicine',
    },
    {
      value: 'East Tennessee State University',
      label: 'East Tennessee State University',
    },
    {
      value: 'Meharry Medical College School of Dentistry',
      label: 'Meharry Medical College School of Dentistry',
    },
    {
      value: 'Meharry Medical College School of Med',
      label: 'Meharry Medical College School of Med',
    },
    {
      value: 'University of Tennessee College of Dentistry',
      label: 'University of Tennessee College of Dentistry',
    },
    {
      value: 'University of Tennessee College of Medicine',
      label: 'University of Tennessee College of Medicine',
    },
    {
      value: 'Vanderbilt University School of Medicine',
      label: 'Vanderbilt University School of Medicine',
    },
    {
      value: 'Baylor College of Dentistry',
      label: 'Baylor College of Dentistry',
    },
    {
      value: 'Baylor College of Medicine',
      label: 'Baylor College of Medicine',
    },
    {
      value: 'Parker College of Chiropractic',
      label: 'Parker College of Chiropractic',
    },
    {
      value: 'Texas Chiropractic College',
      label: 'Texas Chiropractic College',
    },
    {
      value: 'Texas Tech Univ School of Medicine',
      label: 'Texas Tech Univ School of Medicine',
    },
    {
      value: 'Texas A & M Univ System College of Med',
      label: 'Texas A & M Univ System College of Med',
    },
    {
      value: 'UNT Health Sciences, College of Osteo Med',
      label: 'UNT Health Sciences, College of Osteo Med',
    },
    {
      value: 'Univ of Texas at Houston Dental School',
      label: 'Univ of Texas at Houston Dental School',
    },
    {
      value: 'Univ of Texas at San Antonio Dental School',
      label: 'Univ of Texas at San Antonio Dental School',
    },
    {
      value: 'Univ of Texas Medical Branch at Galveston',
      label: 'Univ of Texas Medical Branch at Galveston',
    },
    {
      value: 'Univ of Texas Medical School at Houston',
      label: 'Univ of Texas Medical School at Houston',
    },
    {
      value: 'Univ of Texas Medical School at San Antonio',
      label: 'Univ of Texas Medical School at San Antonio',
    },
    {
      value: 'UT Southwestern-Dallas SW Med School',
      label: 'UT Southwestern-Dallas SW Med School',
    },
    {
      value: 'University of Utah School of Medicine',
      label: 'University of Utah School of Medicine',
    },
    {
      value: 'Eastern VA Med School-Hampton Roads',
      label: 'Eastern VA Med School-Hampton Roads',
    },
    {
      value: 'Univ of VA School of Med Health System',
      label: 'Univ of VA School of Med Health System',
    },
    {
      value: 'Virginia Commonwealth Univ-Dental School',
      label: 'Virginia Commonwealth Univ-Dental School',
    },
    {
      value: 'Virginia Commonwealth Univ-Med School',
      label: 'Virginia Commonwealth Univ-Med School',
    },
    {
      value: 'Univ of Vermont College of Medicine',
      label: 'Univ of Vermont College of Medicine',
    },
    {
      value: 'Univ of Washington School of Dentistry',
      label: 'Univ of Washington School of Dentistry',
    },
    {
      value: 'Univ of Washington School of Medicine',
      label: 'Univ of Washington School of Medicine',
    },
    {
      value: 'Marquette University School of Dentistry',
      label: 'Marquette University School of Dentistry',
    },
    {
      value: 'Medical College of Wisconsin',
      label: 'Medical College of Wisconsin',
    },
    {
      value: 'University of Wisconsin Medical School',
      label: 'University of Wisconsin Medical School',
    },
    {
      value: 'Joan C. Edwards Medical Sch-Marshall Univ',
      label: 'Joan C. Edwards Medical Sch-Marshall Univ',
    },
    {
      value: 'West Virginia School of Osteopathic Med',
      label: 'West Virginia School of Osteopathic Med',
    },
    {
      value: 'West Virginia University School of Dentistry',
      label: 'West Virginia University School of Dentistry',
    },
    {
      value: 'West Virginia University School of Medicine',
      label: 'West Virginia University School of Medicine',
    },
  ];
  private static readonly CANADIAN_SCHOOLS = [
    {
      value: 'N/A',
      label: 'N/A',
    },
    {
      value: 'Dalhousie University Faculty of Dentistry',
      label: 'Dalhousie University Faculty of Dentistry',
    },
    {
      value: 'Dalhousie University Faculty of Medicine',
      label: 'Dalhousie University Faculty of Medicine',
    },
    {
      value: 'Laval University Faculty of Dentistry',
      label: 'Laval University Faculty of Dentistry',
    },
    {
      value: 'Laval University Faculty of Medicine',
      label: 'Laval University Faculty of Medicine',
    },
    {
      value: 'McGill University Faculty of Dentistry',
      label: 'McGill University Faculty of Dentistry',
    },
    {
      value: 'McGill University Faculty of Medicine',
      label: 'McGill University Faculty of Medicine',
    },
    {
      value: 'McMaster University School of Medicine',
      label: 'McMaster University School of Medicine',
    },
    {
      value: 'Memorial Univ of Newfoundland Fac of Med',
      label: 'Memorial Univ of Newfoundland Fac of Med',
    },
    {
      value: 'Queen&#x27;s Univ Faculty of Health Sciences',
      label: 'Queen&#x27;s Univ Faculty of Health Sciences',
    },
    {
      value: 'The Univ of Western Ontario Medical/Dental',
      label: 'The Univ of Western Ontario Medical/Dental',
    },
    {
      value: 'Universite de Montreal Faculty of Medicine',
      label: 'Universite de Montreal Faculty of Medicine',
    },
    {
      value: 'Univ de Sherbrooke Faculty of Medicine',
      label: 'Univ de Sherbrooke Faculty of Medicine',
    },
    {
      value: 'University of Alberta Faculty of Dentistry',
      label: 'University of Alberta Faculty of Dentistry',
    },
    {
      value: 'University of Alberta Faculty of Medicine',
      label: 'University of Alberta Faculty of Medicine',
    },
    {
      value: 'Univ of British Columbia Faculty of Dentistry',
      label: 'Univ of British Columbia Faculty of Dentistry',
    },
    {
      value: 'Univ of British Columbia Faculty of Medicine',
      label: 'Univ of British Columbia Faculty of Medicine',
    },
    {
      value: 'University of Calgary Faculty of Medicine',
      label: 'University of Calgary Faculty of Medicine',
    },
    {
      value: 'University of Manitoba Faculty of Dentistry',
      label: 'University of Manitoba Faculty of Dentistry',
    },
    {
      value: 'University of Manitoba Faculty of Medicine',
      label: 'University of Manitoba Faculty of Medicine',
    },
    {
      value: 'University of Montreal Faculty of Dentistry',
      label: 'University of Montreal Faculty of Dentistry',
    },
    {
      value: 'University of Ottawa Faculty of Medicine',
      label: 'University of Ottawa Faculty of Medicine',
    },
    {
      value: 'Univ of Saskatchewan College of Dentistry',
      label: 'Univ of Saskatchewan College of Dentistry',
    },
    {
      value: 'Univ of Saskatchewan College of Medicine',
      label: 'Univ of Saskatchewan College of Medicine',
    },
    {
      value: 'University of Toronto Faculty of Dentistry',
      label: 'University of Toronto Faculty of Dentistry',
    },
    {
      value: 'University of Toronto Faculty of Medicine',
      label: 'University of Toronto Faculty of Medicine',
    },
    {
      value: 'Univ of Western Ontario Faculty of Dentistry',
      label: 'Univ of Western Ontario Faculty of Dentistry',
    },
  ];

  getProviders(): Array<any> {
    return this.getKeyValues(PROVIDER_TYPE);
  }
  getAlliedProviderTypes(): Array<{ label: string; value: string }> {
    return this.getKeyValues(ALLIED_PROVIDER_TYPE);
  }
  getFacility(): Array<any> {
    return this.getKeyValues(FACILITY_TYPE);
  }
  getFacilityPersonal(): Array<any> {
    return this.getKeyValues(FACILITY_PERSONAL_TYPE);
  }
  getProviderStatuses(): Array<any> {
    return this.getKeyValues(PROVIDER_STATUSES);
  }
  getCountries(): Array<any> {
    return this.getKeyValues(COUNTRIES);
  }
  getAssign(): Array<any> {
    return this.getKeyValues(ASSIGNS);
  }

  getLicenseCode(): Array<any> {
    return this.getKeyValues(STATUS_CODE_LICENSE);
  }

  getAllSpecialities(): Array<any> {
    return this.getKeyValues(ALL_SPECIALTIES);
  }
  getMD_DO(): Array<any> {
    return this.getKeyValues(MD_DO);
  }

  getMD_DP_DC(): Array<any> {
    return this.getKeyValues(MD_DP_DC);
  }

  getMD_DO_BOARD(): Array<any> {
    return this.getKeyValues(MD_DO_BOARD);
  }
  getDDS_DMD_BOARD(): Array<any> {
    return this.getKeyValues(DDS_DMD_BOARD);
  }
  getDPM_BOARD(): Array<any> {
    return this.getKeyValues(DPM_BOARD);
  }
  getDC_BOARD(): Array<any> {
    return this.getKeyValues(DC_BOARD);
  }
  getALL_BOARDS(): Array<any> {
    return this.getKeyValues(ALL_BOARDS);
  }

  getDDS_DMD(): Array<any> {
    return this.getKeyValues(DDS_DMD);
  }

  getAllied_Health_BOARD(): Array<any> {
    return this.getKeyValues(Allied_Health_BOARD);
  }

  getDC(): Array<any> {
    return this.getKeyValues(DC);
  }

  getDPM(): Array<any> {
    return this.getKeyValues(DPM);
  }

  getAlliedProviders(): Array<any> {
    return this.getKeyValues(ALLIED_PROVIDER_TYPE);
  }

  getStatesName(): Array<any> {
    return this.getKeyValues(STATES_NAMES);
  }

  getUSSchools(): Array<any> {
    return SelectOptionService.US_SCHOOLS;
  }

  getAllSchools(): Array<any> {
    return [...this.getUSSchools(), ...this.getCanadianSchools()];
  }

  getCanadianSchools(): Array<any> {
    return SelectOptionService.CANADIAN_SCHOOLS;
  }

  getLanguages(): Array<any> {
    return this.getKeyValues(LANGUAGE_CODES);
  }
  getReason(): Array<any> {
    return this.getKeyValues(REASON);
  }
  getPrivilegeType(): Array<any> {
    return this.getKeyValues(PRIVILEGETYPE);
  }
  getCredentialingStatus():Array<IOption>{
    return this.getKeyValues(CREDENTIAL_STATUS)
  }
  getMilitaryServiceBranches(): Array<any> {
    return this.getKeyValues(MILITARY_BRANCHES);
  }
  getDrugSchedules(): Array<any> {
    return this.getKeyValues(DRUG_SCHEDULES_TYPE);
  }
  getDrugSchedulesCDS(): Array<any> {
    return this.getKeyValues(DRUG_SCHEDULES_TYPE_CDS);
  }
  getPGDegrees(): Array<any> {
    return this.getKeyValues(PG_DEGREES);
  }
  getUGDegrees(): Array<any> {
    return this.getKeyValues(UG_DEGREES);
  }
  getHSDegrees(): Array<any> {
    return this.getKeyValues(HS_DEGREES);
  }
  getDepartments(): Array<any> {
    return this.getKeyValues(DEPARTMENTS);
  }
  getCredentialItems(): Array<any> {
    return this.getKeyValues(CREDENTIAL_ITEMS);
  }

  private loadOptions(options: Array<any>): Observable<Array<any>> {
    return new Observable((obs) => {
      setTimeout(() => {
        obs.next(this.cloneOptions(options));
        obs.complete();
      }, 5000);
    });
  }

  private cloneOptions(options: Array<any>): Array<any> {
    return options.map((option) => ({
      value: option.value,
      label: option.label,
    }));
  }

  getKeyValues(object) {
    return Object.keys(object).map((key) => ({
      label: object[key],
      value: key,
    }));
  }

  sortOptions(arrToSort) {
    const targetArr: any = [];
    const tempArr: any = [];

    for (let i = 0; i < arrToSort.length; i++) {
        if (arrToSort[i].label === 'N/A') {
            targetArr.push(arrToSort[i])
        } else {
            tempArr.push(arrToSort[i])
        }
    }

    tempArr.sort((opt1: any, opt2: any) => {
        return (opt1.label.toLowerCase() < opt2.label.toLowerCase()) ?
            -1 :
            (opt1.label.toLowerCase() > opt2.label.toLowerCase()) ?
                1 : 0;
    })
    arrToSort = targetArr.concat(tempArr);
    return arrToSort;
  }
}
