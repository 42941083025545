import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/view-models/user';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;
  time;
  appLogo: any = this.userService.correctLogo();
  constructor(private userService: UserService) {
    super();
  }

  ngOnInit() {
    this.userService.MyUserInfo().subscribe((userInfo: User) => {
      this.appLogo = this.userService.correctLogo(userInfo)
    })
  }
  reformatPhoneNumber(phone){
    if (phone)  return phone.split('-').join('')
    return null
  }

  getAddress(personalInfo){
    const addressFields=['addressStreet','addressName','addressCity','addressState','addressZip']
    const address=[]
    addressFields.forEach(field=>{
      if (this.isKeyExist(personalInfo,field)) address.push(personalInfo[field])
    })
    return address.join(' ')
  }

  addrOfBirth(dobAddress: string) {
    if (dobAddress) {
      return dobAddress.split(",").reverse().join(",");
    }
    return null;
  }
}
