import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ssn"
})
export class SsnPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (value.length > 0 && value.length === 9) {
      return `${value[0]}${value[1]}${value[2]}-${value[3]}${value[4]}-${value[5]}${value[6]}${value[7]}${value[8]}`;
    } else return value;
  }
}
