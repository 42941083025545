import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SideNavService {

    private menuSizeSource = new BehaviorSubject<number>(0);
    public _size: Observable<number> = this.menuSizeSource.asObservable();

    constructor() { }

    set size(size: any) {
        this.menuSizeSource.next(size);
    }
    get size() {
        return this._size;
    }
    large() {
        this.size = 328;
    }
    small() {
        this.size = 128;
    }
    
}