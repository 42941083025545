import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Payer } from 'src/app/view-models/payer';
import { State } from 'src/app/state/app.state';
import { Store, select } from '@ngrx/store';
import * as MainState from '../../../pages/state/main.reducer';
import * as moment from 'moment';
import { User } from 'src/app/view-models/user';
import { OrganizationInfoService } from 'src/app/services/organizationInfo.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProviderPayerService } from 'src/app/services/provider-payer.service';
import { EnrollProvider } from 'src/app/view-models/enroll-provider';
import { DateModel } from 'src/app/view-models/date-model';
import { ActivatedRoute } from '@angular/router';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonFormService } from 'src/app/services/common-form.service';

@Component({
    selector: 'eddy-enroll-provider',
    templateUrl: 'enroll-provider.component.html',
    styleUrls: ['enroll-provider.component.scss'],
    providers: [NgbDatepickerConfig],
})

export class EnrollProviderComponent implements OnInit {
    enrollProvider: FormGroup;
    payerList: Payer[];
    payer: Payer;
    userInfo: User;
    providerId: number;
    selectedDay: string = '';
    disableDate : boolean;
  constructor(
        private service: ProviderPayerService,
        private builder: FormBuilder,
        private store: Store<State>,
        private organizationInfoService: OrganizationInfoService,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<EnrollProviderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        datepickerConfig: NgbDatepickerConfig,
        private commonFormService: CommonFormService,


    ) {
        datepickerConfig.minDate = { year: 1900, month: 1, day: 1 };
        datepickerConfig.maxDate = { year: 2099, month: 12, day: 31 };
    }

    ngOnInit() {
        this.providerId = this.data.providerId;
        this.CreateForm();
        this.GetPayerList();
        if(this.data.info)
          if(this.data.info.status === 'active')
            this.disableDate=true;
    }
    CreateForm() {
        this.enrollProvider = this.builder.group({
            orgPayerId: [''],
            in_out_network: [true],
            providerId: [''],
            date_sent: [''],
            follow_up_date: [''],
            date_notified: [''],
            effective_date: [''],
            renewal_date: [''],
            term_date: [''],
            status: [''],
            id: ['']
        });
        this.enrollProvider.get('orgPayerId').valueChanges.subscribe((result) => {
            for (let item of this.payerList) {
                if (item.id === Number(result)) {
                    this.payer = item;
                }
            }
        });
    }
    PatchValue() {
        this.enrollProvider.patchValue({
            orgPayerId: this.data.info.orgPayerId,
            in_out_network: this.data.info.in_out_network,
            providerId: this.providerId,
            date_sent: this.commonFormService.ConvertStringToDate(this.data.info.date_sent),
            follow_up_date: this.commonFormService.ConvertStringToDate(this.data.info.follow_up_date),
            date_notified: this.commonFormService.ConvertStringToDate(this.data.info.date_notified),
            effective_date: this.commonFormService.ConvertStringToDate(this.data.info.effective_date),
            renewal_date: this.commonFormService.ConvertStringToDate(this.data.info.renewal_date),
            term_date: this.commonFormService.ConvertStringToDate(this.data.info.term_date),
            status: this.data.info.status,
        })
    }
    GetPayerList() {
        this.store.pipe(select(MainState.GetMyInfo)).subscribe((myInfo: User) => {
            if (myInfo.organization) {
                this.userInfo = myInfo;
                this.organizationInfoService.GetOrganizationPayer().subscribe((result: Payer[]) => {
                    this.payerList = result;
                    if (this.data.info) {
                        this.PatchValue();
                    }
                });
            }
        });
    }
    selectChangeHandler (event: any) {
      this.selectedDay = event.target.value;
      this.disableDate = this.selectedDay === 'active';
    }

    clearFollowUpDate() {
        this.enrollProvider.controls.follow_up_date.setValue(null)
    }

    EnrollProvider() {
        let info = this.enrollProvider.value;
        let item = {
            orgPayerId: Number(info.orgPayerId),
            in_out_network: info.in_out_network,
            providerId: Number(this.providerId),
            date_sent: this.commonFormService.ConvertDateToString(info.date_sent),
            follow_up_date: this.commonFormService.ConvertDateToString(info.follow_up_date),
            date_notified: this.commonFormService.ConvertDateToString(info.date_notified),
            effective_date: this.commonFormService.ConvertDateToString(info.effective_date),
            renewal_date: this.commonFormService.ConvertDateToString(info.renewal_date),
            term_date: this.commonFormService.ConvertDateToString(info.term_date),
            status: info.status,

        }

        this.service.EnrollProvider(item).subscribe((result) => {
            this.enrollProvider.reset();
            this.dialogRef.close(true);
        })
    }
    UpdateEnrollProvider() {
        let info = this.enrollProvider.value;
        let item = {
            id: this.data.info.id,
            orgPayerId: Number(info.orgPayerId),
            in_out_network: info.in_out_network,
            providerId: Number(this.providerId),
            date_sent: this.commonFormService.ConvertDateToString(info.date_sent),
            follow_up_date: this.commonFormService.ConvertDateToString(info.follow_up_date),
            date_notified: this.commonFormService.ConvertDateToString(info.date_notified),
            effective_date: this.commonFormService.ConvertDateToString(info.effective_date),
            renewal_date: this.commonFormService.ConvertDateToString(info.renewal_date),
            term_date: this.commonFormService.ConvertDateToString(info.term_date),
            status: info.status,
        }

        this.service.EditEnrollProvider(item).subscribe((result) => {
            this.enrollProvider.reset();
            this.dialogRef.close(true);
        })
    }
    // ConvertDate(date) {
    //     if (date) {
    //         // let CD = new Date(`${date.year}-${date.month}-${date.day}`);
    //       return `${date.year}-${date.month}-${date.day}`
    //     }
    //     return null;
    // }

    onDateChange(e) {
        // this.service.onDateChange(e);
        e.target.value.split('-')[0] > 1900 ? Array.from(document.querySelectorAll('input')).forEach(input => input.blur()) : null;
    }

    // ConvertStringToDate(date: any): DateModel {
    //     let dateT = new Date(date);
    //     if(date) {
    //         let inputDate: DateModel = {
    //             year: Number(dateT.toISOString().substr(0,4)),
    //             month: Number(dateT.toISOString().substr(5,2)),
    //             day: Number(dateT.toISOString().substr(8,2)),
    //         }
    //         return inputDate;
    //     }
    //     let inputDate: DateModel = null;
    //     return inputDate;
    // }
    Close() {
        this.enrollProvider.reset();
        this.dialogRef.close();
    }

}
