import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { IOption } from "src/app/view-models/ng-select-option";
import { ResetForm } from "../../../../utils/resetForm";

@Component({
  selector: "certification-cds",
  templateUrl: "cds.component.html",
  styleUrls: ["cds.component.scss"]
})
export class ProviderCDSComponent implements OnInit {
  @Input('userId') userId: string;
  CDSForm: FormGroup;
  CDSArray: FormArray;
  CDSValue: any[];
  attachments: string[];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  drugSchedulesOptions: Array<IOption> = this.selectOptionService.getDrugSchedulesCDS();
  /** Input of Component - CDSs */
  @Input()
  get CDSs() {
    let CDSArray = this.CDSForm.controls
      .CDSArray as FormArray;
    this.CDSArray = CDSArray;
    return this.CDSArray.value;
  }

  @Output()
  CDSsChange = new EventEmitter();

  set CDSs(val) {
    if (val) {
      if (val.length > 0) {
        this.CDSValue = val;
        this.CDSArray = this.CDS_Value(val);
        this.CDSForm = this.builder.group({
          CDSArray: this.CDSArray
        });
        this.CDSsChange.emit(this.CDSArray);
      } else {
        // this.AddCDS();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
    // add N/A to biggining of states:
    this.stateOptions.unshift({ label: 'N/A', value: 'N/A' });
  }

  /**
   * Create Form
   *  If CDSs does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing CDSs
   */
  CreateForm() {
    this.CDSArray = this.CDSArray ? this.CDSArray : this.builder.array([]);
    this.CDSForm = this.builder.group({
      CDSArray: this.CDSArray
    });

    if (this.CDSArray.length == 0)
      this.AddCDS();
    // else this.CDSValue(this.CDSValue)
  }

  // Coverage Plan Form Array
  get CDS() {
    return <FormArray>this.CDSArray;
  }

  /**
   * Create an empty form for a new CDS
   * @returns FormGroup
   */
  CreateCDS(info: any = null): FormGroup {
    let form = this.builder.group({
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
      cdsNum: [info ? info.cdsNum : "", Validators.required],
      stateRegCDS: [info ? info.stateRegCDS : null, Validators.required],
      cdsDrugSchedule: [info ? info.cdsDrugSchedule : []],
      issuanceCDS: [info ? info.issuanceCDS : "", Validators.required],
      expireCDS: [info ? info.expireCDS : "", Validators.required],
      attachments: [info ? info.attachments : []],
      isPrimary: [info ? info.isPrimary : null],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new CDS to form array
   */
  AddCDS(): void {
    let CDSArray = this.CDSForm.controls
      .CDSArray as FormArray;
    CDSArray.push(this.CreateCDS());

    this.CDSArray = CDSArray;
    this.CDSsChange.emit(this.CDSArray);
  }

  /**
   * Remove a CDS from form array
   * @param index Index of the CDS to remove
   */
  RemoveCDS(index: number): void {
    let CDSArray = this.CDSForm.get("CDSArray") as FormArray;

    if (CDSArray.length > 1) {
      CDSArray.removeAt(index);
    } else {
      ResetForm.resetFormArray(CDSArray);
      CDSArray = this.builder.array([]);
    }
    this.CDSArray = CDSArray;
    this.CDSsChange.emit(this.CDSArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let CDSArray = this.CDSForm.controls
      .CDSArray as FormArray;
    this.CDSArray = CDSArray;
    this.CDSsChange.emit(this.CDSArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert CDS array to form array
   * @param informations JSON array of CDSs
   * @returns Array of FormGroup of CDSs
   */
  CDS_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          id: [info.id],
          certificationId: [info.certificationId],
          cdsNum: [info.cdsNum, Validators.required],
          stateRegCDS: [info.stateRegCDS, Validators.required],
          cdsDrugSchedule: [info.cdsDrugSchedule],
          issuanceCDS: [
            this.service.ConvertStringToDate(info.issuanceCDS),
            Validators.required
          ],
          expireCDS: [
            this.service.ConvertStringToDate(info.expireCDS),
            Validators.required
          ],
          attachments: [info.attachments],
          isPrimary: [info.isPrimary],
        })
      );
    });
    return formArray;
  }

  getCDSAttachments(index: number) {
    return this.CDSArray.length && this.CDSArray.at(index).get("attachments").value ? this.CDSArray.at(index).get("attachments").value : [];
  }

  onChangeCDSAttachment(event, index: number) {
    console.log(event);
    this.CDSArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

  isPrimaryChange(event: any, index: number){
    event && this.setAllPrimariesFalse();
    this.CDSArray.at(index).get("isPrimary").setValue(event);
    this.onChange(event);
  }

  setAllPrimariesFalse(){
      for (var item in this.CDSArray.controls) {
          (this.CDSArray as FormArray).at(parseInt(item)).patchValue({
              isPrimary: false
          });
      }
  }

  checkCDSDrugSchedule(index: number, item: string) {
    return this.CDSArray && this.CDSArray.length &&
      this.CDSArray.at(index).get("cdsDrugSchedule").value && 
      this.CDSArray.at(index).get("cdsDrugSchedule").value.indexOf(item) >-1 ? 
        true : false;
  }

  onChangeCDSDrugSchedule(index: number, item: string) {
    let drugSchedules : string[] = this.CDSArray.at(index).get("cdsDrugSchedule").value;
    if(!drugSchedules) drugSchedules = [];
    if(drugSchedules.indexOf(item) <= -1) drugSchedules.push(item);
    else drugSchedules.splice(drugSchedules.indexOf(item), 1);
    this.CDSArray.at(index).get("cdsDrugSchedule").setValue(drugSchedules);
    this.onChange(event);
  }
}
