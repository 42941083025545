import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomizedAlerts } from '../view-models/customized-alerts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomizedAlertsInfo } from '../view-models/customized-alerts-info';


@Injectable()
export class CustomizedAlertsService {
  constructor(public http: HttpClient) {
  }

  GetAlertInfo(): Observable<CustomizedAlertsInfo> {
    return this.http.get(`${environment.API_URL}org-custom-alert`)
        .pipe(
            map((res: CustomizedAlertsInfo) => {
                return res;
            })
        );
    }
  AlertInfo( alertInfo : CustomizedAlerts ): Observable<CustomizedAlerts> {
    return this.http.put(`${environment.API_URL}org-custom-alert`, alertInfo)
        .pipe(
            map((res: CustomizedAlerts) => {
                return res;
            })
        );
    }

}