import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonFormState } from 'src/app/view-models/common-form-state';
import { CommonFormActions, CommonFormActionTypes } from './common-form.actions';

const initialState: CommonFormState = {
    PersonalInfo: null,
    EducationalInfo: null,
    Training: null,
    Certification: null,
    Specialties: null,
    CurrentPrivilege: null,
    PrimaryPractice: null,
    WorkHistory: null,
    Insurance: null,
    MilitaryService: null,
    ProfessionalReference: null,
    DisclosureQuestions: null,
    Summary: null,
    MetaData:{hideAllied:true}

}
const GetCommonFormFeatureState = createFeatureSelector<CommonFormState>('CommonForm');
export const GetPersonalInfo = createSelector(
    GetCommonFormFeatureState,
    state => state.PersonalInfo
);
export const GetEducationalInfo = createSelector(
    GetCommonFormFeatureState,
    state => state.EducationalInfo
);
export const GetTraining = createSelector(
    GetCommonFormFeatureState,
    state => state.Training
);
export const GetCertification = createSelector(
    GetCommonFormFeatureState,
    state => state.Certification
);
export const GetSpecialties = createSelector(
    GetCommonFormFeatureState,
    state => state.Specialties
);
export const GetCurrentPrivilege = createSelector(
    GetCommonFormFeatureState,
    state => state.CurrentPrivilege
);
export const GetPrimaryPractice = createSelector(
    GetCommonFormFeatureState,
    state => state.PrimaryPractice
);
export const GetWorkHistory = createSelector(
    GetCommonFormFeatureState,
    state => state.WorkHistory
);
export const GetInsurance = createSelector(
    GetCommonFormFeatureState,
    state => state.Insurance
);
export const GetMilitaryService = createSelector(
    GetCommonFormFeatureState,
    state => state.MilitaryService
);
export const GetProfessionalReference = createSelector(
    GetCommonFormFeatureState,
    state => state.ProfessionalReference
);
export const GetDisclosureQuestions = createSelector(
    GetCommonFormFeatureState,
    state => state.DisclosureQuestions
);
export const GetSummary = createSelector(
    GetCommonFormFeatureState,
    state => state.Summary
);
export const GetMetaData = createSelector(
    GetCommonFormFeatureState,
    state => state.MetaData
);


export function CommonFormReducer(state = initialState, action: CommonFormActions): CommonFormState {
    switch (action.type) {
        case CommonFormActionTypes.LoadPersonalInfoSuccess:
            return {
                ...state,
                PersonalInfo: action.payload
            };
        case CommonFormActionTypes.LoadEducationalInfoSuccess:
            return {
                ...state,
                EducationalInfo: action.payload
            };
        case CommonFormActionTypes.LoadTrainingSuccess:
            return {
                ...state,
                Training: action.payload
            };
        case CommonFormActionTypes.LoadCertificationSuccess:
            return {
                ...state,
                Certification: action.payload
            };
        case CommonFormActionTypes.LoadSpecialtiesSuccess:
            return {
                ...state,
                Specialties: action.payload
            };
        case CommonFormActionTypes.LoadCurrentPrivilegeSuccess:
            return {
                ...state,
                CurrentPrivilege: action.payload
            };
        case CommonFormActionTypes.LoadPrimaryPracticeSuccess:
            return {
                ...state,
                PrimaryPractice: action.payload
            };
        case CommonFormActionTypes.LoadWorkHistorySuccess:
            return {
                ...state,
                WorkHistory: action.payload
            };
        case CommonFormActionTypes.LoadInsuranceSuccess:
            return {
                ...state,
                Insurance: action.payload
            };
        case CommonFormActionTypes.LoadMilitaryServiceSuccess:
            return {
                ...state,
                MilitaryService: action.payload
            };
        case CommonFormActionTypes.LoadProfessionalReferenceSuccess:
            return {
                ...state,
                ProfessionalReference: action.payload
            };
        case CommonFormActionTypes.LoadDisclosureQuestionsSuccess:
            return {
                ...state,
                DisclosureQuestions: action.payload
            };
        case CommonFormActionTypes.LoadSummarySuccess:
            return {
                ...state,
                Summary: action.payload
            };
        case CommonFormActionTypes.MetaData:
            return {
                ...state,
                MetaData: action.payload
            };
        default:
            return state;
    }
}
