import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { State } from "src/app/state/app.state";
import { IOption } from "src/app/view-models/ng-select-option";
import { PersonalInformation } from "src/app/view-models/personal-information";
import { Specialties } from "src/app/view-models/specialities";
import * as CommonFormState from "../../../../../pages/provider/components/common-form/state/common-form.reducer";
import { ResetForm } from "../../../../utils/resetForm";
@Component({
  selector: "certification-license",
  templateUrl: "license.component.html",
  styleUrls: ["license.component.scss"]
})
export class ProviderLicenseComponent implements OnInit {
  @Input('userId') userId: string;
  LicenseForm: FormGroup;
  LicenseArray: FormArray;
  LicenseValue: any[];
  attachments: string[];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  licenseTypeOptions: Array<IOption> = this.selectOptionService.getProviders();
  providerType: string;
  specialty: any;
  /** Input of Component - Licenses */
  @Input()
  get Licenses() {
    let LicenseArray = this.LicenseForm.controls
      .LicenseArray as FormArray;
    this.LicenseArray = LicenseArray;
    return this.LicenseArray.value;
  }

  @Output()
  LicensesChange = new EventEmitter();

  set Licenses(val) {
    if (val) {
      if (val.length > 0) {
        this.LicenseValue = val;
        this.LicenseArray = this.License_Value(val);
        this.LicenseForm = this.builder.group({
          LicenseArray: this.LicenseArray
        });
        this.LicensesChange.emit(this.LicenseArray);
      } else {
        // this.AddLicense();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private service: CommonFormService,
    private store: Store<State>,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
    // add N/A to biggining of states:
    this.stateOptions.unshift({ label: 'N/A', value: 'N/A' });
    this.getSpecialties();
    this.getProviderType()
    for (let item of this.licenseTypeOptions) {
      if (item.value == '031') {
        this.licenseTypeOptions.splice(this.licenseTypeOptions.indexOf(item), 1)
      }
    }
  }

  /**
   * Create Form
   *  If Licenses does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing Licenses
   */
  CreateForm() {
    this.LicenseArray = this.LicenseArray ? this.LicenseArray : this.builder.array([]);
    this.LicenseForm = this.builder.group({
      LicenseArray: this.LicenseArray
    });

    if (this.LicenseArray.length == 0)
      this.AddLicense();
    // else this.LicenseValue(this.LicenseValue)
  }

  // Coverage Plan Form Array
  get License() {
    return <FormArray>this.LicenseArray;
  }

  /**
   * Create an empty form for a new License
   * @returns FormGroup
   */
  CreateLicense(info: any = null): FormGroup {
    let form = this.builder.group({
      status: [info ? info.status : 'Individual', Validators.required],
      stateStLicense: [info ? info.stateStLicense : null, Validators.required],
      numStLicense: [info ? info.numStLicense : "", Validators.required],
      statusCodeStLicense: [info ? info.statusCodeStLicense : null],
      typeStLicense: [this.setTypeStLicense(info)],
      isParticipating: [info ? info.isParticipating : false],
      issuanceStLicense: [info ? info.issuanceStLicense : null],
      expireStLicense: [info ? info.expireStLicense : null],
      attachments: [info ? info.attachments : []],
      isPrimary: [info ? info.isPrimary : null],
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new License to form array
   */
  AddLicense(): void {
    let LicenseArray = this.LicenseForm.controls
      .LicenseArray as FormArray;
    LicenseArray.push(this.CreateLicense());

    this.LicenseArray = LicenseArray;
    this.LicensesChange.emit(this.LicenseArray);
  }

  /**
   * Remove a License from form array
   * @param index Index of the License to remove
   */
  RemoveLicense(index: number): void {
    let LicenseArray = this.LicenseForm.get("LicenseArray") as FormArray;

    if (LicenseArray.length > 1) {
      LicenseArray.removeAt(index);
    } else {
      ResetForm.resetFormArray(LicenseArray);
      LicenseArray = this.builder.array([]);
    }
    this.LicenseArray = LicenseArray;
    this.LicensesChange.emit(this.LicenseArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let LicenseArray = this.LicenseForm.controls
      .LicenseArray as FormArray;
    this.LicenseArray = LicenseArray;
    this.LicensesChange.emit(this.LicenseArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert License array to form array
   * @param informations JSON array of Licenses
   * @returns Array of FormGroup of Licenses
   */
  License_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          status: [info.status, Validators.required],
          stateStLicense: [info.stateStLicense, Validators.required],
          numStLicense: [info.numStLicense, Validators.required],
          statusCodeStLicense: [info.statusCodeStLicense],
          typeStLicense: [this.setTypeStLicense(info)],
          isParticipating: [info.isParticipating],
          issuanceStLicense: [this.service.ConvertStringToDate(info.issuanceStLicense)],
          expireStLicense: [this.service.ConvertStringToDate(info.expireStLicense)],
          attachments: [info.attachments],
          isPrimary: [info.isPrimary],
          id: [info.id],
          certificationId: [info.certificationId],
        })
      );
    });
    return formArray;
  }

  getLicenseAttachments(index: number) {
    return this.LicenseArray.length && this.LicenseArray.at(index).get("attachments").value ? this.LicenseArray.at(index).get("attachments").value : [];
  }

  onChangeLicenseAttachment(event, index: number) {
    console.log(event);
    this.LicenseArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

  getProviderType() {
    this.store
			.pipe(select(CommonFormState.GetPersonalInfo))
			.subscribe((info: PersonalInformation) => {
        this.providerType = info.providerType;
        if(this.providerType && this.providerType === "081")
          this.licenseTypeOptions = this.selectOptionService.sortOptions(this.selectOptionService.getAlliedProviders());
      })
  }

  getSpecialties() {
    this.store
			.pipe(select(CommonFormState.GetSpecialties))
			.subscribe((info: Specialties) => {
        this.specialty = info && info.specialityData && info.specialityData.length ? info.specialityData[0] : null;
      })
  }

  isPrimaryChange(event: any, index: number){
    event && this.setAllPrimariesFalse();
    this.LicenseArray.at(index).get("isPrimary").setValue(event);
    event && this.LicenseArray.at(index).get("typeStLicense").setValue(this.setTypeStLicense(this.LicenseArray.at(index).value));
    this.onChange(event);
  }

  setAllPrimariesFalse(){
      for (var item in this.LicenseArray.controls) {
          (this.LicenseArray as FormArray).at(parseInt(item)).patchValue({
              isPrimary: false
          });
      }
  }

  setTypeStLicense(info: any) {
    return info && info.isPrimary && this.providerType && this.providerType === "081" ? 
            this.getSpecialty() :
              info && info.typeStLicense ? 
                info.typeStLicense : null
  }

  getSpecialty() {
    return this.service.getSpecialtyCode(this.specialty, this.providerType)
  }
}
