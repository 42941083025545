import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class SourceVerificationService {
  constructor(public http: HttpClient, private toastr: ToastrService) { }

  /*Sign In Method*/
  NPPES(userId): Observable<any> {
    return this.http.get(`${environment.API_URL}users/npi-info/${userId}`).pipe(
      map(res => {
        return res;
      })
    );
  }
  OIG(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL2}organization/providers/${userId}/runOIG`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  CAQH(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}populate-pdf/getProviderCAQHInfo/${userId}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  OPTOUT(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}populate-pdf/getProviderOptOutInfo/${userId}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  StateLicense(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}populate-pdf/getStateLicenseInfo/${userId}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  DEA(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}populate-pdf/getDeaInfo/${userId}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  sourceVerificationReceive(providerId: string, receiveData:any): Observable<any> {
    return this.http.put(`${environment.API_URL}provider-source-verification/dateRecieved/${providerId}`, receiveData)
      .pipe(
        map((result: any) => {
          return result
        })
      )
  }

  sourceVerificationChangeContact(providerId: string, receiveData:any): Observable<any> {
    return this.http.put(`${environment.API_URL}provider-source-verification/contact-attempt/${providerId}`, receiveData)
      .pipe(
        map((result: any) => {
          return result
        })
      )
  }

  SAM(providerId):Observable<{haveResult:boolean;path:string;message:string;}>{
    return this.http.get<{haveResult:boolean;path:string;message:string;}>(`${environment.API_URL}certification/sam/${providerId}`)
  }

  getPSVItemsAndNotes(providerId: string) : Promise<any> {
    return this.http.get(`${environment.API_URL}provider-source-verification/required/${providerId}`).toPromise()
  }

  clearPSVItemsAndNotes(providerId: string) : Promise<any> {
    return this.http.get(`${environment.API_URL}provider-source-verification/clear/${providerId}`).toPromise()
  }

}
