import { Component, OnInit, Input } from '@angular/core';
import { Ages_Treated, Areas_Of_Specialization, Therapeutic_Modalities } from 'src/app/constants';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

@Component({
  selector: 'app-speciallities',
  templateUrl: './speciallities.component.html',
  styleUrls: ['./speciallities.component.scss']
})
export class SpeciallitiesComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;
  public agestreated = Ages_Treated;
  public areasOfSpecialization = Areas_Of_Specialization;
  public therapeuticModalities = Therapeutic_Modalities;
  public providerSpecialities = {};

  constructor() {
    super();
  }

  ngOnInit() {  
      this.SpecialitiesFilter(this.applicantDetail.specialities.specialityData, this.agestreated, 'areasTreated');
      this.SpecialitiesFilter(this.applicantDetail.specialities.specialityData, this.areasOfSpecialization.partA, 'areasOfSpecialization');
      this.SpecialitiesFilter(this.applicantDetail.specialities.specialityData, this.areasOfSpecialization.partB, 'areasOfSpecialization');
      this.SpecialitiesFilter(this.applicantDetail.specialities.specialityData, this.therapeuticModalities, 'therapeutic');
  }

  SpecialitiesFilter(specialitiesArr, enumArr, newKey) {
    enumArr.forEach(element => {
        specialitiesArr.forEach((speciality, index) => {
          if(this.providerSpecialities[index] === undefined) {
            this.providerSpecialities[index] = {};
          }
          if(this.providerSpecialities[index][newKey] === undefined) {
            this.providerSpecialities[index][newKey] = [];
          }
          if(speciality[element.field] === true) {
            this.providerSpecialities[index][newKey].push(element.title)
          }
        })
    });
  }
}
