import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  Form,
  ValidationErrors
} from "@angular/forms";
import { SelectOptionService } from "src/app/services/select-option.service";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { State } from "src/app/state/app.state";
import { Certification } from "src/app/view-models/certification";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "eddy-provider-ATLS-documents",
  templateUrl: "ATLS.component.html",
  styleUrls: ["ATLS.component.scss"]
})
export class ATLSDocumentsComponent implements OnInit {
  isAdvTrauma = true;
  certificationForm: FormGroup;
  certificationInfo: Certification;
  arrayList = [];
  dateList = [
    "expireAdvTraum"
  ];

  @Input()
  get certification() {
    let certification = (this.certificationForm as FormGroup).value;
    this.ConvertDate(certification);
    return certification;
  }

  @Output()
  onChangeATLS = new EventEmitter();

  set certification(val) {
    if (val) {
      this.certificationInfo = val
      this.PatchValue(val)
    } else {
      this.CreateForm();
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private router: Router,
    private store: Store<State>,
    private service: CommonFormService
  ) { }

  ngOnInit() {
    this.CreateForm();
  }

  CreateForm() {
    this.certificationForm = this.builder.group({
      isAdvTrauma: [true, Validators.required],
      numberATLS: ['', Validators.required],
      expireAdvTraum: [null, Validators.required],
    });

    if (this.certificationInfo) {
      this.PatchValue(this.certificationInfo);
    }
  }

  requiredIfValidator(predicate) {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  FormValueChange() {

  }

  PatchValue(certification: Certification) {
    if (certification && this.certificationForm) {
      this.certificationInfo = certification;
      for (let item of Object.keys(certification)) {
        if (this.certificationForm.get(item)) {
          if (this.arrayList.indexOf(item) < 0) {
            if (this.dateList.indexOf(item) >= 0) {
              this.certificationForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(certification[item])
                );
            } else {
              this.certificationForm.get(item).patchValue(certification[item]);
            }
          }
        }
      }
    }
  }


  // Other Functions
  ConvertDate(info: Certification) {
    for (let item of Object.keys(info)) {
      if (this.arrayList.indexOf(item) >= 0) {
        // Check Date Time in Array
        for (let arrayItem of info[item]) {
          for (let key of Object.keys(arrayItem)) {
            if (this.dateList.indexOf(key) >= 0) {
              arrayItem[key] = this.service.ConvertDateToString(arrayItem[key]);
            }
          }
        }
      } else {
        if (this.dateList.indexOf(item) >= 0) {
          info[item] = this.service.ConvertDateToString(info[item]);
        }
      }
    }
  }

  onDateChange(e) {
    // this.service.onDateChange(e)
    this.onChange(null);
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let certification = this.certificationForm.value;
    this.ConvertDate(certification);

    const isValid = this.certificationForm.valid;
    if(!isValid) this.service.ValidateAllFormFields(this.certificationForm);
    this.onChangeATLS.emit({ ...certification, isValid });
  }

}
