import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "certification-vaccine",
  templateUrl: "vaccine.component.html",
  styleUrls: ["vaccine.component.scss"]
})
export class ProviderVaccineComponent implements OnInit {
  @Input('userId') userId: string;
  vaccineForm: FormGroup;
  vaccineArray: FormArray;
  vaccineValue: any[];
  attachments: string[];
  /** Input of Component - vaccines */
  @Input()
  get vaccines() {
    let vaccineArray = this.vaccineForm.controls
      .vaccineArray as FormArray;
    this.vaccineArray = vaccineArray;
    return this.vaccineArray.value;
  }

  @Output()
  vaccinesChange = new EventEmitter();

  set vaccines(val) {
    if (val) {
      if (val.length > 0) {
        this.vaccineValue = val;
        this.vaccineArray = this.VaccineValue(val);
        this.vaccineForm = this.builder.group({
          vaccineArray: this.vaccineArray
        });
        this.vaccinesChange.emit(this.vaccineArray);
      } else {
        // this.AddVaccine();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If vaccines does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing vaccines
   */
  CreateForm() {
    this.vaccineArray = this.vaccineArray ? this.vaccineArray : this.builder.array([]);
    this.vaccineForm = this.builder.group({
      vaccineArray: this.vaccineArray
    });

    if (this.vaccineArray.length == 0)
      this.AddVaccine();
    // else this.VaccineValue(this.vaccineValue)
  }

  // Coverage Plan Form Array
  get vaccine() {
    return <FormArray>this.vaccineArray;
  }

  /**
   * Create an empty form for a new vaccine
   * @returns FormGroup
   */
  CreateVaccine(info: any = null): FormGroup {
    let form = this.builder.group({
      issuanceVaccine: [info ? info.issuanceVaccine : ""],
      attachments: [info ? info.attachments : []],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new vaccine to form array
   */
  AddVaccine(): void {
    let vaccineArray = this.vaccineForm.controls
      .vaccineArray as FormArray;
    vaccineArray.push(this.CreateVaccine());

    this.vaccineArray = vaccineArray;
    this.vaccinesChange.emit(this.vaccineArray);
  }

  /**
   * Remove a vaccine from form array
   * @param index Index of the vaccine to remove
   */
  RemoveVaccine(index: number): void {
    let vaccineArray = this.vaccineForm.get("vaccineArray") as FormArray;

    if (vaccineArray.length > 1) {
      vaccineArray.removeAt(index);
    } else {
      vaccineArray = this.builder.array([]);
    }
    this.vaccineArray = vaccineArray;
    this.vaccinesChange.emit(this.vaccineArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let vaccineArray = this.vaccineForm.controls
      .vaccineArray as FormArray;
    this.vaccineArray = vaccineArray;
    this.vaccinesChange.emit(this.vaccineArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert vaccine array to form array
   * @param informations JSON array of vaccines
   * @returns Array of FormGroup of vaccines
   */
  VaccineValue(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          issuanceVaccine: [this.service.ConvertStringToDate(info.issuanceVaccine)],
          attachments: [info.attachments],
        })
      );
    });
    return formArray;
  }

  getVaccineAttachments(index: number) {
    return this.vaccineArray.at(index).get("attachments").value ? this.vaccineArray.at(index).get("attachments").value : [];
  }

  onChangeVaccineAttachment(event, index: number) {
    console.log(event);
    this.vaccineArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
