import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AttachmentsService } from "src/app/services/attachments.service";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { IOption } from "src/app/view-models/ng-select-option";
import { AttachmentFile } from "src/app/view-models/attachment-file";
import { environment } from "src/environments/environment";
import { ShowPDFComponent } from "../../show-pdf/show-pdf.component";
import { OrganizationSettingService } from "src/app/services/organization-settings.service";

enum FileDocumentOption {
  archive = "ARCHIVE",
  template = "TEPMPLATE",
}

@Component({
  selector: "app-provider-supporting-documents",
  templateUrl: "./provider-supporting-documents.component.html",
  styleUrls: ["./provider-supporting-documents.component.scss"],
})
export class ProviderSupportingDocumentsComponent implements OnInit {
  providerSupportingDocCategoryList: IOption[] = [];
  providerSupportingDocCategorySelected = null;
  allAttachments: AttachmentFile[] = [];
  providerSupportingDocList: { [key: string]: Array<IOption> } = {};
  attachmentTypes: { [key: string]: string } = {};
  providerSupportingDocSelected;
  providerSupportingTableData: AttachmentFile[] = [];
  @Input("providerId") providerId;
  public moment = moment;
  providerType: string;
  fileDocument: Array<IOption> = [];
  selectedFileDocument: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private attachmentService: AttachmentsService,
    private orgSettingService: OrganizationSettingService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
  
    this.fillProviderSupportingDocs();
      this.getAllAttachments(this.providerId);
    this.attachmentService.GetAttachmentsTypesList().subscribe((res) => {
      this.attachmentTypes = res;
    });
  }

  fillProviderSupportingDocs() {
    this.attachmentService
      .GetAttachmentsCategorizedTypesList()
      .subscribe((res) => {
        this.providerSupportingDocCategoryList = Object.keys(res).map(
          (key) => ({ value: key, label: res[key].lable })
        );
        this.providerSupportingDocCategoryList = [{value: "all", label: "View All"}].concat(this.providerSupportingDocCategoryList);
        Object.keys(res).map((key) => {
          this.providerSupportingDocList[key] = res[key].subCategory;
        });
      });
  }

  getAllAttachments(id) {
    this.attachmentService.getAllAttachments("all", id).subscribe(
      (res: any) => {
        this.allAttachments = res;
      },
      (error) => {
        console.error("Error in fetching attachments", error);
      }
    );
  }

  fillDocumentListTable(optionValue: IOption) {
    let selectedType = optionValue.value;
    const regExp = {
      Corporate: "W9|Corporate LLC DBA",
    };
    const re = new RegExp(regExp[selectedType] || selectedType, "gi");
    this.providerSupportingTableData = this.allAttachments.filter(
      (attachment) => attachment.type && attachment.type.match(re)
    );
  }

  changeProviderSupportingCategory($event: any) {
    this.providerSupportingDocSelected = null;
    this.providerSupportingTableData = $event && $event.value=="all" ? 
      this.allAttachments
      : [];
  }

  extractFileNameAndExt(fileName: string): { fileName: string; ext: string[] } {
    const splitedFileName = fileName.split(".");
    const ext = splitedFileName.length > 1 ? splitedFileName.splice(-1, 1): [""];
    return {
      fileName: splitedFileName.join(),
      ext,
    };
  }

  showDocument(filePath: string, docType?: string, userId?: string) {
    let urlPDF = `${environment.Base_URL}${filePath}`;
    if (docType === "Document" || docType === "Note")
      urlPDF = `${environment.Base_URL}/providers/${userId}/documents/verificationNotes/${filePath}`;
    const dialogRef = this.dialog.open(ShowPDFComponent, {
      width: "90%",
      panelClass: "cred-modal",
      data: {
        urlPDF,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  showFileTemplateDoc(templateId: string) {
    this.orgSettingService
      .GetFileTemplate(templateId, this.providerId)
      .subscribe((file) => {
        this.showDocument(file.mergedPdfPath);
      });
  }

  get fileDocumentOption() {
    return FileDocumentOption;
  }

}
