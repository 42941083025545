import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "certification-visa",
  templateUrl: "visa.component.html",
  styleUrls: ["visa.component.scss"]
})
export class ProviderVisaComponent implements OnInit {
  @Input('userId') userId: string;
  visaForm: FormGroup;
  visaArray: FormArray;
  visaValue: any[];
  attachments: string[];
  /** Input of Component - visas */
  @Input()
  get visas() {
    let visaArray = this.visaForm.controls
      .visaArray as FormArray;
    this.visaArray = visaArray;
    return this.visaArray.value;
  }

  @Output()
  visasChange = new EventEmitter();

  set visas(val) {
    if (val) {
      if (val.length > 0) {
        this.visaValue = val;
        this.visaArray = this.VisaValue(val);
        this.visaForm = this.builder.group({
          visaArray: this.visaArray
        });
        this.visasChange.emit(this.visaArray);
      } else {
        // this.AddVisa();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If visas does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing visas
   */
  CreateForm() {
    this.visaArray = this.visaArray ? this.visaArray : this.builder.array([]);
    this.visaForm = this.builder.group({
      visaArray: this.visaArray
    });

    if (this.visaArray.length == 0)
      this.AddVisa();
    // else this.VisaValue(this.visaValue)
  }

  // Coverage Plan Form Array
  get visa() {
    return <FormArray>this.visaArray;
  }

  /**
   * Create an empty form for a new visa
   * @returns FormGroup
   */
  CreateVisa(info: any = null): FormGroup {
    let form = this.builder.group({
      expireVisa: [info ? info.expireVisa : "", Validators.required],
      visaNum: [info ? info.visaNum : ""],
      issuanceVisa: [info ? info.issuanceVisa : ""],
      attachments: [info ? info.attachments : []],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new visa to form array
   */
  AddVisa(): void {
    let visaArray = this.visaForm.controls
      .visaArray as FormArray;
    visaArray.push(this.CreateVisa());

    this.visaArray = visaArray;
    this.visasChange.emit(this.visaArray);
  }

  /**
   * Remove a visa from form array
   * @param index Index of the visa to remove
   */
  RemoveVisa(index: number): void {
    let visaArray = this.visaForm.get("visaArray") as FormArray;

    if (visaArray.length > 1) {
      visaArray.removeAt(index);
    } else {
      visaArray = this.builder.array([]);
    }
    this.visaArray = visaArray;
    this.visasChange.emit(this.visaArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let visaArray = this.visaForm.controls
      .visaArray as FormArray;
    this.visaArray = visaArray;
    this.visasChange.emit(this.visaArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert visa array to form array
   * @param informations JSON array of visas
   * @returns Array of FormGroup of visas
   */
  VisaValue(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          expireVisa: [this.service.ConvertStringToDate(info.expireVisa), Validators.required],
          visaNum: [info.visaNum],
          issuanceVisa: [this.service.ConvertStringToDate(info.issuanceVisa)],
          attachments: [info.attachments],
        })
      );
    });
    return formArray;
  }

  getVisaAttachments(index: number) {
    return this.visaArray.at(index).get("attachments").value ? this.visaArray.at(index).get("attachments").value : [];
  }

  onChangeVisaAttachment(event, index: number) {
    console.log(event);
    this.visaArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
