import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
