import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable()
export class SubscriptionService {
  constructor(public http: HttpClient) {
  }

  GetMySubscription() {
    return this.http.get(`${environment.API_URL}subscription/me`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  GetSubscriptions() {
    return this.http.get(`${environment.API_URL}subscription`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  GetPlans() {
    return this.http.get(`${environment.API_URL}subscription/plans`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  CreateSubscription(subscriptionId: number) {
    return this.http.post(`${environment.API_URL}subscription/${subscriptionId}`, {})
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  UpdateSubscription(subscriptionId: number) {
    return this.http.put(`${environment.API_URL}subscription/${subscriptionId}`, {})
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  OrganizationCheckout(detail: any) {
    return this.http.post(`${environment.API_URL}subscription/organization/checkout`, detail)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  CheckPayment() {
    return this.http.get(`${environment.API_URL}subscription/organization/payment`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
}
