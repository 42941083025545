import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CAQH } from '../view-models/caqh';
import { SourceVerificationInfo } from '../view-models/source-verification-info';
import {IOrganizationProviderSetting} from "../view-models/organization";
import { IProviderLocationNote } from '../view-models/provider-location-note';
import { IStatusHistories } from '../view-models/history-status';

@Injectable()
export class OrganizationProviderService {
    constructor(
        private http: HttpClient
    ) { }

    /*get providers list Info */
    getProviderByStatus(status, pageNo, pageSize, filterData = {}) {
        const paramsObj = { status, pageNo, pageSize, ...filterData };

        // join dates (object to string):
        if (paramsObj['fromApptDate']['year']) paramsObj['fromApptDate'] = paramsObj['fromApptDate']['year'] + '-' + paramsObj['fromApptDate']['month'] + '-' + paramsObj['fromApptDate']['day'];
        if (paramsObj['toApptDate']['year']) paramsObj['toApptDate'] = paramsObj['toApptDate']['year'] + '-' + paramsObj['toApptDate']['month'] + '-' + paramsObj['toApptDate']['day'];
        if (paramsObj['status'] === null) paramsObj['status'] = '';
        paramsObj['sortASC'] = paramsObj['sortASC'] ? 'asc' : 'desc';

        let params = new HttpParams();
        Object.keys(paramsObj).forEach(key => {
            params = params.append(key, paramsObj[key]);
        });
        return this.http
            .get(`${environment.API_URL}organization-info/providers`, { params })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    csvInfo() {
        return this.http
            .get(`${environment.API_URL}organization-info/providers/csv-info`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    assignProvider(id, status) {
        return this.http
            .post(`${environment.API_URL}organization-info/assign-providers`, { userIds: id, status })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    assignProviderToAdmLocation(providerIds: string[], adminIds: string[], orgId) {
        return this.http
            .post(`${environment.API_URL}location-admin/assignUserToProvivder`, { providerIds: providerIds, adminIds: adminIds, orgId: orgId })
            .pipe(
                map((res: any) => {
                    return res;
                })
            )
    }

    assignLocations(id, location) {
        return this.http
            .post(`${environment.API_URL}provider-location/assignLocations/`, { locationIds: location, userIds: id })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );

    }
    getProviderApplicantDetails(id) {
        return this.http
            .get(`${environment.API_URL}organization-info/providers/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    getSpecificProvider(id) {
        return this.http
            .get(`${environment.API_URL}personal-info/user/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    private providerDetails = new BehaviorSubject<any>(null);
    public _providerDetails: Observable<any> = this.providerDetails.asObservable();

    set details(data: any) {
        this.providerDetails.next(data);
    }
    get details() {
        return this._providerDetails;
    }

    getProviderDetails(id) {
        return this.http
            .get(`${environment.API_URL}organization-info/providers/user/${id}`)
            .pipe(
                map((res: any) => {
                    this.details = res;
                    return res;
                })
            );
    }
    setProviderPsvflag(userId,psvFlag:boolean):Observable<any>{
     return  this.http.post(`${environment.API_URL}users/change-psvflag/${userId}`,{psvFlag})
    }
    /**
     * Call Create or Update Provider Endpoint at Organization Side
     * @param providerInfo User Info and Personal Info
     * @returns Response of Create or Update Provider Endpoint
     */
    CreateProvider(providerInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider`, providerInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Specialties Endpoint at Organization Side
     * @param specialtiesInfo Specialties Info
     * @returns Response of Create or Update Specialties Endpoint
     */
    CreateProviderSpecialties(specialtiesInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/specialities`, specialtiesInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Work History Endpoint at Organization Side
     * @param workHistoriesInfo Work History Info
     * @returns Response of Create or Update Work History Endpoint
     */
    CreateProviderWorkHistories(workHistoriesInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/work-history`, workHistoriesInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Insurance Endpoint at Organization Side
     * @param insuranceInfo Insurance Info
     * @returns Response of Create or Update Insurance Endpoint
     */
    CreateProviderInsurance(insuranceInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/insurance`, insuranceInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update PrimaryPractice Endpoint at Organization Side
     * @param primaryPracticeInfo primaryPractice Info
     * @returns Response of Create or Update PrimaryPractice Endpoint
     */
    CreateProviderPrimaryPractice(primaryPracticeInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/primary-practice`, primaryPracticeInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Educational Info Endpoint at Organization Side
     * @param educationalInfo Educational Info
     * @returns Response of Create or Update EducationalInfo Endpoint
     */
    CreateProviderEducationalInfo(educationalInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/education-info`, educationalInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Training Info Endpoint at Organization Side
     * @param trainingInfo Training Info
     * @returns Response of Create or Update Training Endpoint
     */
    CreateProviderTraining(trainingInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/training`, trainingInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Military Services Info Endpoint at Organization Side
     * @param militaryServiceInfo User Military Services Info
     * @returns Response of Create or Update Military Services Endpoint
     */
    CreateProviderMilitaryService(militaryServiceInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/military-service`, militaryServiceInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Affilations Endpoint at Organization Side
     * @param affiliationInfo User Affiliations
     * @returns Response of Create or Update Affilations Endpoint
     */
    CreateProviderAffiliation(affiliationInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/affiliation`, affiliationInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    /**
     * Call Create or Update Certifications Endpoint at Organization Side
     * @param certInfo User Certifications
     * @returns Response of Create or Update Certifications Endpoint
     */
    CreateProviderExpirables(certInfo) {
        return this.http
            .post(`${environment.API_URL}organization-info/add-provider/expirable`, certInfo)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    uploadBulkProviders(csvFile) {
        const formData: FormData = new FormData();
        formData.append('file', csvFile);
        return this.http.post(`${environment.API_URL}import-providers-request`, formData)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    getBulkImportStatus(pageNo, pageSize) {
        let params = new HttpParams();
        params = params.append('pageNo', pageNo);
        params = params.append('pageSize', pageSize);
        return this.http.get(`${environment.API_URL}import-providers-request`, { params }).pipe(map((res: any) => {
            return res;
        }));
    }

    getFileReviewInfo(userId) {
        return this.http.get(`${environment.API_URL}allowed-organization/file-review/${userId}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    postFileReviewInfo(userId) {
        return this.http.post(`${environment.API_URL}allowed-organization/file-review/${userId}`, {})
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    providerChangeStatus(userId, status) {
        return this.http.put(`${environment.API_URL}users/change-status/${userId}`, { status })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    providerChangeCredStatus(userId, credentialingStatus) {
        return this.http.put(`${environment.API_URL}allowed-organization/change-credentialing-status/${userId}`, { credentialingStatus })
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    //provider forms
    GetFiles() {
        return this.http.get(`${environment.API_URL}populate-pdf/get/list`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    //provider forms
    GetCVInfo() {
        return this.http.get(`${environment.API_URL}populate-pdf/provider/cv`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    //CAQH
    GetCAQHInfo(userId: number): Observable<CAQH> {
        return this.http.get(`${environment.API_URL}populate-pdf/caqh/info?userId=${userId}`)
            .pipe(
                map((res: CAQH) => {
                    return res;
                })
            );
    }

    getDownloadToken() {
        return this.http.get(`${environment.API_URL}users/get-token`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    getPopulatedPdf(token, id, userId, type) {
        return this.http.get(`${environment.API_URL}populate-pdf/${userId}?token=${token}&id=${id}&appType=${type}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }



    getPayerTracks(id) {
        return this.http.get(`${environment.API_URL}payer-tracks/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    editPayerTrack(data) {
        // append file to formData:
        const formData: FormData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }

        return this.http.put(`${environment.API_URL}payer-tracks/`, formData)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    DeletePayerTrack(id: number) {
        return this.http.delete(`${environment.API_URL}payer-tracks/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            )
    }

    addPayerTracks(data) {
        // append file to formData:
        const formData: FormData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }

        return this.http.post(`${environment.API_URL}payer-tracks/`, formData)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    getProviderSourceVerification(providerId) {
        return this.http.get(`${environment.API_URL}provider-source-verification/${providerId}`).pipe(
            map((res: SourceVerificationInfo[]) => {
                return res;
            })
        );
    }

    reviewProviderSourceVerification(providerId, psvId, reviewedAt) {
        return this.http.post(`${environment.API_URL}provider-source-verification/reviewed/${providerId}`, { psvId, reviewedAt }).pipe(
            map((res: any) => {
                return res;
            })
        )
    }
    runLicense(providerId, id): Observable<any> {
        const paramsObj = { id };
        let params = new HttpParams();
        Object.keys(paramsObj).forEach(key => {
            params = params.append(key, paramsObj[key]);
        });
        return this.http.get(`${environment.API_URL}certification/verify/license/${providerId}`, { params })
            .pipe(
                map((result: any) => {
                    return result
                })
            )
    }
    getImages(url): Observable<any> {
        return this.http.get(url).pipe(map(res => res))
    }
    runDEA(providerId, id): Observable<any> {
        const paramsObj = { id };
        let params = new HttpParams();
        Object.keys(paramsObj).forEach(key => {
            params = params.append(key, paramsObj[key]);
        });
        return this.http.get(`${environment.API_URL}certification/verify/dea/${providerId}`, { params })
            .pipe(
                map((result: any) => {
                    return result
                })
            )
    }
    delete(Id: number) {
        return this.http.delete(`${environment.API_URL}provider-notes/${Id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    getProviderSetting(providerId):Observable<IOrganizationProviderSetting> {
        return this.http.get<IOrganizationProviderSetting>(`${environment.API_URL2}organization/providers/${providerId}/psvnote-setting`).pipe(
            map((res) => {
                return res;
            })
        );
    }

    updateProviderTaxonomyCodes(formData) {
        return this.http.put(`${environment.API_URL}taxonomy`, formData)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    updateExpireStLicense(body, userId: number) {
        return this.http.put(`${environment.API_URL}organization-info/update-expireStLicense/${userId}`, body)
            .pipe(
                map((res: any) => {
                    return res;
                })
            )
    }

    assignBulkLable(body):Observable<any>{
        return this.http.post(`${environment.API_URL}organization-info/provider-info/bulklabel`,body)
    }

    runVerification(userId:string|number):Observable<any>{
      return this.http.get(`${environment.API_URL}certification/verify/license-dea/${userId}`)
    }

    /** Provider Status History APIs */

    getUserStatusHistories(providerId) {
        return this.http.get(`${environment.API_URL}user-status-history/${providerId}`).pipe(
            map((res: IStatusHistories[]) => {
                return res;
            })
        );
    }

    getStatusHistoryNote(statusId: number) {
        return this.http.get(`${environment.API_URL}user-status-history-notes/${statusId}`).pipe(
            map((res: any[]) => {
                return res;
            })
        );
    }
}
