import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterListBy',
    pure: false
})
export class FilterListPipe implements PipeTransform {
    transform(items: any[], filter): any {
        if (!items || !filter) {
            return items;
        }

        let result = items;
        for (let key of Object.keys(filter)) {
            result = result.filter(item => item[key].indexOf(filter[key]) !== -1);
            // 
        }

        return result;
    }
}