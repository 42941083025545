import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentModel } from '../view-models/agent';
import { AgentStatusChange } from '../view-models/agent-status-change';
import { AddAgent } from '../view-models/add-agent';

@Injectable()
export class AgentService {
    constructor(
        private http: HttpClient
    ) { }
    GetAgents(): Observable<AgentModel[]> {
        return this.http.get(`${environment.API_URL}agent`)
            .pipe(
                map((response: AgentModel[]) => {
                    return response;
                })
            )
    }
    ChangeAgentStatus(payload: AgentStatusChange): Observable<any> {
        return this.http.post(`${environment.API_URL}agent/change-status`, payload)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    AddNewAgent(payload: AddAgent, userId: null = null): Observable<any> {
        const apiURI = userId ? `organization-info/add-provider/agent` : `agent`; 
        return this.http.post(`${environment.API_URL}${apiURI}`, { ...payload, providerId: userId })
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    DeleteAgent(agentId: string): Observable<any> {
        return this.http.delete(`${environment.API_URL}agent/${agentId}`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    GetAllAgents(): Observable<any> {
        return this.http.get(`${environment.API_URL}agent/all-request?status=approved`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    getAgent(agentId: string): Observable<any> {
        return this.http.get(`${environment.API_URL}agent/${agentId}`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }

}