import * as _ from 'lodash';
import * as moment from 'moment'

export class Utilities {
    public _ = _;
    public moment = moment;

    isKeyExist(object = {}, key = '') {
        return object ? object.hasOwnProperty(key) : false;
    }

    getInformations(fields = []) {
        return _.compact(fields).join(' ');
    }

    isValueExist(array = [], item) {
        return array.includes(item);
    }
}