import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable()
export class ProviderNotesService {
  constructor(public http: HttpClient) { }

  get(providerId) {
    return this.http
      .get(`${environment.API_URL}provider-notes/${providerId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  get2(providerId) {
    return this.http
      .get(`${environment.API_URL2}organization/providers/${providerId}/psv_notes`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  post(noteInfo, providerId, runFlag = true, existDocumentPath = null) {
    return this.http
      .post(`${environment.API_URL}provider-notes/${providerId}?runFlag=${runFlag}`, { ...noteInfo, existDocumentPath })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  create(formData, providerId) {
    return this.http
      .post(`${environment.API_URL}provider-notes/create/${providerId}`, formData)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  psv(providerId) {
    return this.http
      .get(`${environment.API_URL}psv-notes/pdf/${providerId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  delete(Id: number) {
    return this.http.delete(`${environment.API_URL}provider-notes/${Id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  setFlag(noteId: number | string, flag: boolean): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${environment.API_URL}provider-notes/setFlag`, { runFlag: flag, id: noteId })
  }

  saveNote(createUrl: string, data: FormData) {
    return this.http
      .post(`${environment.API_URL}${createUrl}`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  deleteNote(deleteUrl: string) {
    return this.http.delete(`${environment.API_URL}${deleteUrl}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
