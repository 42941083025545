import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Affiliation } from '../view-models/affiliation';
import { ListRequest } from '../view-models/list-request';
import { Contact } from '../view-models/contact';

@Injectable()
export class ContactService {

    constructor(
        private http: HttpClient
    ) { }
    getContact(): Observable<any> {
        return this.http.get(`${environment.API_URL}organization-contact/`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    addContact(contact){
        return this.http.post(`${environment.API_URL}organization-contact/`, contact)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    deleteContact(id){
        return this.http.delete(`${environment.API_URL}organization-contact/${id}`, )
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    updateContact(id, body){
        return this.http.put(`${environment.API_URL}organization-contact/${id}`,body )
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
}