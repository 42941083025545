import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MainState } from 'src/app/view-models/main-state';
import { MainActions, MainActionTypes } from './main.actions';

const initialState: MainState = {
    user: null,
    loading: true,
}
const GetMainFeatureState = createFeatureSelector<MainState>('Main');
export const GetMyInfo = createSelector(
    GetMainFeatureState,
    state => state.user
);
export const GetLoading = createSelector(
    GetMainFeatureState,
    state => state.loading
);

export function MainReducer(state = initialState, action: MainActions): MainState {
    switch (action.type) {
        case MainActionTypes.SetUser: 
            return { 
                ...state,
                user: action.payload
            };
        case MainActionTypes.LoadUserSuccess: 
            return { 
                ...state,
                user: action.payload
            };
        case MainActionTypes.SetLoading: 
            return { 
                ...state,
                loading: action.payload
            };
        default: 
            return state;
    }
}