import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { NbToastrService } from "@nebular/theme";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { ToastrService } from "ngx-toastr";
import { ProviderNotesService } from "src/app/services/provider-notes.service";
import { noteURLs } from "src/app/view-models/note-urls";
import { environment } from "src/environments/environment";
import { ConfirmDeleteModalComponent } from "../confirm-delete-modal/confirm-delete-modal.component";
import { ShowPDFComponent } from "../show-pdf/show-pdf.component";

@Component({
    selector: "eddy-notes",
    templateUrl: "./notes.component.html",
    styleUrls: ["./notes.component.scss"],
})
export class NotesComponent implements OnInit {
    noteForms: FormGroup;
    uploadedFile;
    file;
    selectedFileName;
    fileAcceptable: string[] = ["application/pdf", "image/png", "image/jpeg"];
    @Input() uploadFileType?: string;
    @Input() notesList: any[] = [];
    @Input() providerId?: any;
    @Input() organizationId?: any;
    @Input() sourceId: any;
    @Input() sourceName: string;
    @Input() isHasFlag?= true;
    @Input() noteType: string;
    @Output("changeNotes") changeNotes: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public dialog: MatDialog,
        private builder: FormBuilder,
        private toastr: ToastrService,
        private providerNoteService: ProviderNotesService,
        private modalService: NgbModal,
        private toastrService: NbToastrService) {
    }

    ngOnInit(): void {
        this.CreateForm();
    }

    CreateForm() {
        this.noteForms = this.builder.group({
            title: [""],
            comment: [""],
            file: [""],
            runFlag: [false]
        });
    }

    uploadFile(item, type = "Note", title?: string, comment?: string) {
        const url = noteURLs(this.providerId, this.organizationId).find(url => url.type === this.noteType);
        const formData: FormData = new FormData();
        if (this.uploadedFile) formData.append("file", this.uploadedFile);
        if (this.noteForms.value.title) formData.append("title", this.noteForms.value.title || title);
        formData.append("comment", this.noteForms.value.comment ? this.noteForms.value.comment : comment ? comment : "");
        if (this.isHasFlag) formData.append("runFlag", this.noteForms.value.runFlag || 'false');
        let credentialItem = item;
        if (credentialItem) formData.append("credentialItem", credentialItem);
        if (this.providerId) formData.append("userId", this.providerId);
        formData.append("type", type);
        if (this.providerId) formData.append('source', "ProviderSourceVerification");
        if (this.sourceId) formData.append(this.sourceName ? this.sourceName : 'sourceId', this.sourceId);
        this.providerNoteService.saveNote(url.creatUrl, formData).subscribe(
            (res: any) => {
                if (res.id) {
                    this.toastr.success("file uploaded successfully", "File Uploaded");
                    this.noteForms.reset();
                    this.selectedFileName = null;
                    this.uploadedFile = null;
                    this.changeNotes.emit();
                }
            },
            (err: any) => {
                console.log(err);
                this.toastr.error("file is too large", "Upload Failed");
            }
        );
    }

    dropped(files: NgxFileDropEntry[]) {
        if (files[0].fileEntry.isFile) {
            this.file = files[0];
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                if (!this.fileAcceptable.includes(file.type)) {
                    this.uploadedFile = null;
                    this.selectedFileName = null;
                    return this.toastrService.danger(
                        "only PNG, JPG, JPEG, PDF are acceptable.",
                        "Upload Failed"
                    );
                }
                this.uploadedFile = file;
                this.selectedFileName = file.name;
            });
        }
    }

    ShowFile(fileKey, noteId?: number | string, runFlag?: boolean) {
      debugger
        const url = noteURLs(this.providerId, this.organizationId).find(url => url.type === this.noteType);
        const dialogRef = this.dialog.open(ShowPDFComponent, {
            width: "90%",
            panelClass: "cred-modal",
            data: {
                urlPDF: `${environment.Base_URL}${url.fileUrl}${fileKey}`,
                noteId,
                runFlag,
                isHasFlag: this.isHasFlag
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.changeNotes.emit();
        });
    }

    DeleteFile(item) {
        let modal = this.modalService.open(ConfirmDeleteModalComponent);
        modal.componentInstance.text =
            "Are you sure you want to delete this notes ?";
        modal.result.then((result) => {
            if (result) {
                const url = noteURLs(this.providerId, this.organizationId).find(url => url.type === this.noteType);
                this.providerNoteService.deleteNote(url.deleteUrl + item.id).subscribe((res: any) => {
                    // this.specificNotes = [];
                    this.changeNotes.emit();
                    this.toastr.success(res.message, "Note Deleted");
                });
            }
        });
    }

    Close() {
        this.modalService.dismissAll();
    }

    getFileLink(fileKey) {
        return `${environment.Base_URL}/providers/${this.providerId}/documents/verificationNotes/${fileKey}`;
    }

    getNoteCreatorName(item: any) {
        if (item && item.noteCreator && 'firstName' in item.noteCreator)
            return `${item.noteCreator.firstName} ${item.noteCreator.lastName}`
    }

}