import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE_CODES, COUNTRIES } from '../constants';

@Pipe({
    name: 'country'
})
export class CountryPipe implements PipeTransform {
    transform(keys) {
        if (COUNTRIES[keys]) {
            return COUNTRIES[keys] || keys;

        }
        return '';
    }
}
