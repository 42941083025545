import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { Areas_Of_Specialization, Specialty_Certifications, Therapeutic_Modalities } from "src/app/constants";
import { SelectOptionService } from "src/app/services/select-option.service";
import { CoveringPractitioner } from "src/app/view-models/covering-practitioner";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "primary-practice-covering-practitioner",
  templateUrl: "covering-practitioner.component.html",
  styleUrls: ["covering-practitioner.component.scss"]
})
export class CoveringPractitionerComponent implements OnInit {
  @Input('userId') userId: string;
  coveringPractitionerForm: FormGroup;
  coveringPractitionerArray: FormArray;
  coveringPractitionerValue: CoveringPractitioner[];

  /** Input of Component - coveringPractitioners */
  @Input()
  get coveringPractitioners() {
    let coveringPractitionerArray = this.coveringPractitionerForm.controls
      .coveringPractitionerArray as FormArray;
    this.coveringPractitionerArray = coveringPractitionerArray;
    return this.coveringPractitionerArray.value;
  }

  @Output()
  coveringPractitionersChange = new EventEmitter();

  set coveringPractitioners(val) {
    if (val) {
      if (val.length > 0) {
        this.coveringPractitionerValue = val;
        this.coveringPractitionerArray = this.CoveringPractitionerValue(val);
        this.coveringPractitionerForm = this.builder.group({
          coveringPractitionerArray: this.coveringPractitionerArray
        })
        this.coveringPractitionersChange.emit(this.coveringPractitionerArray);
      } else {
        // this.AddCoveringPractitioner();
      }
    }
  }

  MD_DO_Options: Array<IOption>;
  DC_Options: Array<IOption>;
  DPM_Options: Array<IOption>;
  MD_DO_BOARD_OPTION: Array<IOption>;
  MD_DP_DC_Options: Array<IOption> = this.selectOptionService.getMD_DP_DC();
  DDS_DMD_Options: Array<IOption>;
  Allied_Health_Options: Array<IOption> = this.selectOptionService.getAllied_Health_BOARD();
  ALLIED_PROVIDERS_Options: Array<IOption>;
  areasOfSpecialization = Areas_Of_Specialization;
  therapeuticModalities = Therapeutic_Modalities;
  specialtyCertifications = Specialty_Certifications;
  allSpecialities_Options: Array<IOption> = this.selectOptionService.getAllSpecialities();
  DDS_DMD_BOARD_OPTION: Array<IOption>
  DPM_BOARD_OPTION: Array<IOption>
  DC_BOARD_OPTION: Array<IOption>
  ALL_BOARDS_OPTION: Array<IOption>
  specialtyOptins: Array<IOption>
  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
  ) {
    this.CreateForm();
    this.specialtyOptins = this.allSpecialities_Options
    this.MD_DO_Options = this.SortSpecialities(this.selectOptionService.getMD_DO());
    this.DC_Options = this.SortSpecialities(this.selectOptionService.getDC());
    this.DPM_Options = this.SortSpecialities(this.selectOptionService.getDPM());
    this.DDS_DMD_Options = this.SortSpecialities(this.selectOptionService.getDDS_DMD());
    this.ALLIED_PROVIDERS_Options = this.SortSpecialities(this.selectOptionService.getAlliedProviders());
    this.MD_DO_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getMD_DO_BOARD())
    this.DDS_DMD_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDDS_DMD_BOARD())
    this.DPM_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDPM_BOARD())
    this.DC_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDC_BOARD())
    this.ALL_BOARDS_OPTION = this.SortSpecialities(this.selectOptionService.getALL_BOARDS())
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If coveringPractitioners does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing coveringPractitioners
   */
  CreateForm() {
    this.coveringPractitionerArray = this.builder.array([]);
    this.coveringPractitionerForm = this.builder.group({
      coveringPractitionerArray: this.coveringPractitionerArray
    });

    if (!this.coveringPractitionerValue || this.coveringPractitionerValue.length == 0)
      this.AddCoveringPractitioner();
    else this.CoveringPractitionerValue(this.coveringPractitionerValue)

  }

  // Coverage Plan Form Array
  get coveringPractitioner() {
    return <FormArray>this.coveringPractitionerArray;
  }

  /**
   * Create an empty form for a new coverage plan
   * @returns FormGroup
   */
  CreateCoveringPractitioner(info: CoveringPractitioner = null): FormGroup {
    let form = this.builder.group({
      providerType: [info ? info.providerType : ""],
      phoneNumber: [info ? info.phoneNumber : ""],
      providerName: [info ? info.providerName : ""],
      specialty: [info ? info.specialty : ""],
    });

    form.get("providerType").valueChanges.subscribe(selectedValue => {
      this.onChange(null)
    })

    // form.get("phoneNumber").valueChanges.subscribe(selectedValue => {
    //   console.log("phoneNumber", selectedValue);
    //   if (selectedValue.length == 12)
    //     this.onChange(null)
    // })

    form.get("specialty").valueChanges.subscribe(selectedValue => {
      this.onChange(null)
    })

    return form;
  }

  /**
   * Add a new coverage plan to form array
   */
  AddCoveringPractitioner(): void {
    let coveringPractitionerArray = this.coveringPractitionerForm.controls.coveringPractitionerArray as FormArray;
    coveringPractitionerArray.push(this.CreateCoveringPractitioner());

    this.coveringPractitionerArray = coveringPractitionerArray;
    this.coveringPractitionersChange.emit(this.coveringPractitionerArray);
  }

  /**
   * Remove a coverage plan from form array
   * @param index Index of the coverage plan to remove
   */
  RemoveCoveringPractitioner(index: number): void {
    let coveringPractitionerArray = this.coveringPractitionerForm.get("coveringPractitionerArray") as FormArray;

    if (coveringPractitionerArray.length > 1) {
      coveringPractitionerArray.removeAt(index);
    } else {
      coveringPractitionerArray = this.builder.array([]);
    }
    this.coveringPractitionerArray = coveringPractitionerArray;
    this.coveringPractitionersChange.emit(this.coveringPractitionerArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let coveringPractitionerArray = this.coveringPractitionerForm.controls
      .coveringPractitionerArray as FormArray;
    this.coveringPractitionerArray = coveringPractitionerArray;
    this.coveringPractitionersChange.emit(this.coveringPractitionerArray);
  }

  /**
   * Convert coverage plan array to form array
   * @param informations JSON array of coverage plans
   * @returns Array of FormGroup of coverage plans
   */
  CoveringPractitionerValue(informations: CoveringPractitioner[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      let cpform = this.CreateCoveringPractitioner(info);
      formArray.push(cpform);
    });
    return formArray;
  }

  SortSpecialities(arrToSort) {
    const targetArr: any = [];
    const tempArr: any = [];

    for (let i = 0; i < arrToSort.length; i++) {
      if (arrToSort[i].label === 'N/A') {
        targetArr.push(arrToSort[i])
      } else {
        tempArr.push(arrToSort[i])
      }
    }

    tempArr.sort((opt1: any, opt2: any) => {
      return (opt1.label.toLowerCase() < opt2.label.toLowerCase()) ?
        -1 :
        (opt1.label.toLowerCase() > opt2.label.toLowerCase()) ?
          1 : 0;
    })
    arrToSort = targetArr.concat(tempArr);
    return arrToSort;
  }

  getProviderType(index: number): string {
    return this.coveringPractitionerForm.controls.coveringPractitionerArray['controls'][index].controls.providerType.value
  }

}
