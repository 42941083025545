import { Pipe, PipeTransform } from '@angular/core';
import { MD_DO } from '../constants';

@Pipe({
  name: 'MDDO'
})
export class MdDoPipe implements PipeTransform {
  transform(key) {
    return MD_DO[key] || key;
  }
}
