import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as MainState from '../pages/state/main.reducer';
import { Store, select } from '@ngrx/store';
import { State } from '../state/app.state';
import { User } from '../view-models/user';
@Injectable()
export class ProviderAllowedAuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<State>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkRole();
  }

  checkRole() {
    this.store.pipe(select(MainState.GetMyInfo)).subscribe((user: User) => {
      if(user) {
        if(user.role === 'provider' || user.role === 'providerAgent') {
          return true;
        }
      }
    })
    return false;
  }
}
