import {
  Component, EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {CountryCode} from './resource/country-code';
import * as lpn from 'google-libphonenumber';
import {Country} from './model/country.model';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as _ from 'lodash';

const PHONE_NUMBER_TYPE = {
  FIXED_LINE: 0,
  MOBILE: 1,
  FIXED_LINE_OR_MOBILE: 2,
  TOLL_FREE: 3,
  PREMIUM_RATE: 4,
  SHARED_COST: 5,
  VOIP: 6,
  PERSONAL_NUMBER: 7,
  PAGER: 8,
  UAN: 9,
  VOICEMAIL: 10,
  UNKNOWN: -1
}

@Component({
  selector: 'app-intl-tel-input',
  templateUrl: './intl-tel-input.component.html',
  styles: [
    'li.country:hover { background-color: rgba(0, 0, 0, 0.05); }',
    '.selected-flag.dropdown-toggle:after { content: none; }',
    '.flag-container.disabled {cursor: default !important; }',
    '.intl-tel-input .country-list {left: 0; width: 480px}',
    '.intl-tel-input.allow-dropdown .flag-container.disabled:hover .selected-flag { background: none; }'
  ],
  providers: [
    CountryCode,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntlTelInputComponent),
      multi: true
    },
    // {
    //   provide: NG_VALIDATORS,
    //   useValue: phoneNumberValidator,
    //   multi: true,
    // }
  ],
})
export class IntlTelInputComponent implements OnInit, ControlValueAccessor {
  @Input() ngModel;
  @Input() preferredCountries: Array<string> = [];
  @Input() enablePlaceholder = true;
  @Input() phoneType = 'MOBILE';
  @Output() getInternationalPhone = new EventEmitter<any>();
  public phoneNumber = null;

  allCountries: Array<Country> = [];
  preferredCountriesInDropDown: Array<Country> = [];
  selectedCountry: Country = new Country();
  phoneUtil = lpn.PhoneNumberUtil.getInstance();
  disabled = false;

  propagateChange = (_: any) => {
  };

  constructor(private countryCodeData: CountryCode) {
  }

  ngOnInit() {
    this.fetchCountryData();
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach(iso2 => {
        const preferredCountry = this.allCountries.filter((c) => {
          return c.iso2 === iso2;
        });

        this.preferredCountriesInDropDown.push(preferredCountry[0]);
      });
    }

    if (this.preferredCountriesInDropDown.length) {
      this.selectedCountry = this.preferredCountriesInDropDown[0];
    } else {
      this.selectedCountry = this.allCountries[0];
    }
  }

  public onPhoneNumberChange(): void {
    this.ngModel = this.phoneNumber;

    let number = '';
    try {
      number = this.phoneUtil.parse(this.phoneNumber, this.selectedCountry.iso2.toUpperCase());
    } catch (e) {
    }

    this.propagateChange(number === '' ? '' : this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL));
    this.getInternationalPhone.emit(number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : '')
  }

  autoCountrySelect() {
    if (this.phoneNumber.length && this.phoneNumber[0] === '+') {
      const countryCode = (this.phoneNumber.substr(1, this.phoneNumber.indexOf(' '))).slice(0, -1);
      let country = null;
      if (countryCode === '1') {
        country = this.allCountries.find(item => item.name === 'United States')
      } else {
        country = this.allCountries.find(item => item.dialCode === countryCode);
      }
      this.phoneNumber = this.phoneNumber.slice(countryCode.length + 2);
      if (country) {
        if(document.getElementById('focusable')) {
          this.onCountrySelect(country, document.getElementById('focusable'))
        }
      }
    }
  }

  public onCountrySelect(country: Country, el): void {
    this.selectedCountry = country;
    if (this.phoneNumber.length > 0) {
      this.ngModel = this.phoneNumber;

      let number = '';
      try {
        number = this.phoneUtil.parse(this.phoneNumber, this.selectedCountry.iso2.toUpperCase());
      } catch (e) {

      }

      this.propagateChange(number === '' ? '' : this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL))
    }

    el.focus();
  }

  public onInputKeyPress(event): void {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach(c => {
      const country = new Country();

      country.name = c[0].toString();
      country.iso2 = c[1].toString();
      country.dialCode = c[2].toString();
      country.priority = +c[3] || 0;
      country.areaCode = +c[4] || null;
      country.flagClass = country.iso2.toLocaleLowerCase();

      if (this.enablePlaceholder) {
        country.placeHolder = this.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
      }

      this.allCountries.push(country);
    });
  }

  protected getPhoneNumberPlaceHolder(countryCode: string): string {
    try {
      return this.phoneUtil.format(this.phoneUtil.getExampleNumberForType(countryCode, PHONE_NUMBER_TYPE[this.phoneType || 'MOBILE']), lpn.PhoneNumberFormat.NATIONAL);
    } catch (e) {
      console.error('CountryCode: "' + countryCode + '" ' + e);
      return e;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.phoneNumber = obj;
      this.onPhoneNumberChange();
      this.autoCountrySelect()
    }
  }

  getCountryMask(): String {
    const {placeHolder = ''} = this.selectedCountry
    return _.replace(placeHolder, /[0-9]/g, '0')
  }
}
