import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class RequestSupportReplyService {
  constructor(public http: HttpClient) {
  }
  readReplies(id) {
    return this.http.get(`${environment.API_URL}request-support-reply/${id}`).
    pipe(map((res: any) => {
        return res;
      }));
  }
  sendReply(body) {
    return this.http.post(`${environment.API_URL}request-support-reply`, body).toPromise()
      .then(res => {
        return res;
    });
  } 
}
