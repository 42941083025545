import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationService {

    constructor(
        private http: HttpClient
    ) { }

    GetNotifications(): Observable<any> {
        let payload = {
            status: ["new", "unread", "read"],
        }
        return this.http.post(`${environment.API_URL}notifications/recent?pageNo=1&pageSize=10`,payload)
        .pipe(
            map((result: any) => {
                return result;
            })
        )
    }
    Notifaction(id): Observable<any> {
        return this.http.get(`${environment.API_URL}notifications/${id}`)
        .pipe(
            map((result: any) => {
                return result;
            })
        )

    }
    DeleteNotification(id: string): Observable<any> {
        return this.http.delete(`${environment.API_URL}notifications/${id}`)
        .pipe(
            map((result: any) => {
                return result;
            })
        )
    }
    DeleteAllNotifications(): Observable<any> {
        return this.http.delete(`${environment.API_URL}notifications`)
        .pipe(
            map((result: any) => {
                return result;
            })
        )
    }
}