import {Pipe, PipeTransform} from '@angular/core';
import { STATES_NAMES } from '../constants';

@Pipe({
  name: 'stateCode'
})
export class StateCodePipe implements PipeTransform {
  transform(key) {
    return STATES_NAMES[key] || key;
  }
}
