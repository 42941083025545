import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ProviderEnrollment } from '../view-models/provider-enrollment';
import { PayerEnrollment } from '../view-models/payer-enrollment';



@Injectable()
export class ReportingService {
  constructor(public http: HttpClient) {
  }

  getCertification(id) {
    return this.http.get(`${environment.API_URL}report/certification/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  getInsurance(id) {
    return this.http.get(`${environment.API_URL}report/insurance/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  getSpeciality(id) {
    return this.http.get(`${environment.API_URL}report/speciality/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  getProvider(id) {
    return this.http.get(`${environment.API_URL}report/providers/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  getComprehensive(id) {
    return this.http.get(`${environment.API_URL}report/comprehensive/${id}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  GetPayers(): Observable<any[]> {
    return this.http.get(`${environment.API_URL}report/payers`)
      .pipe(
        map((res: PayerEnrollment[]) => {
          return res;
        })
      )
  }
  GetProviders(): Observable<any[]> {
    return this.http.get(`${environment.API_URL}report/org-payers`)
      .pipe(
        map((res: ProviderEnrollment[]) => {
          return res;
        })
      )
  }
  GetCaqh(): Observable<any[]> {
    return this.http.get(`${environment.API_URL}report/enroll/caqh`)
      .pipe(
        map((res: any[]) => {
          return res;
        })
      )
  }
  GetPrivileges(): Observable<any[]> {
    return this.http.get(`${environment.API_URL}report/providers/current-privileges`)
      .pipe(
        map((res: any[]) => {
          return res;
        })
      )
  }
  GetLocations(): Observable<any[]> {
    return this.http.get(`${environment.API_URL2}organization/reporting/provider_reports/${'location'}`)
      .pipe(
        map((res: any[]) => {
          return res;
        })
      )
  }
  GetFolowUp(): Observable<any[]> {
    return this.http.get(`${environment.API_URL}report/payers/follow-up`)
      .pipe(
        map((res: any[]) => {
          return res;
        })
      )
  }

  GetMetrics() {
    return this.http.get(`${environment.API_URL}report/metrics`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  GetComprehensiveLocation() {
    return this.http.get(`${environment.API_URL}report/comprehensiveLocationReport`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  GetPerLocation() {
    return this.http.get(`${environment.API_URL}report/perLocationReport`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }
  GetPerLocation_Users() {
    return this.http.get(`${environment.API_URL}report/providers/perLocation-user`)
      .pipe(
        map((res: any) => {

          return res;
        })
      )
  }
}
