import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
    selector: 'eddy-show-message',
    templateUrl: 'show-message.component.html'
})

export class ShowMessageComponent implements OnInit {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

    ngOnInit() { }
}
