import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'addSplit'
})

export class AddSplitPipe implements PipeTransform {
    transform(value: any): any {
        if(value) {
            let temp = value.split(',');
            return _.compact(temp).join(', \n');;
        }else if(!value) {
            return 'N/A';
        }
    }
}