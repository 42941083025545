import {Pipe, PipeTransform} from '@angular/core';
import { STATES_NAMES } from '../constants';
import * as moment from 'moment';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  transform(key) {
    // let tempDate = new Date(key);
    let dateT = new Date(key);
    let date = `${dateT.toISOString().substr(5,2)}/${dateT.toISOString().substr(8,2)}/${dateT.toISOString().substr(0,4)}`
    return date;
    }
}
