import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { User } from "../view-models/user";
import { map } from "rxjs/operators";
import { Provider } from "@angular/compiler/src/compiler_facade_interface";
import { ORGANIZATIONS_LOGO } from "../constants";

@Injectable()
export class UserService {
  constructor(public http: HttpClient) {
    this.MyUserInfo();
  }

  /*Sign In Method*/
  login(userName: string, password: string): Promise<any> {
    return this.http
      .post(`${environment.API_URL}auth`, { userName, password })
      .toPromise()
      .then((res: any) => {
        localStorage.setItem("auth_token", res.token);
        return res;
      });
  }

  /*Sign Up Method*/
  signUp(user: any) {
    return this.http
      .post(`${environment.API_URL}provider-registration`, user)
      .toPromise()
      .then((res: any) => {
        const {
          token,
          user: { organization = null }
        } = res;
        localStorage.setItem("auth_token", token);
        return res;
      });
  }

  // Get My User Info
  private currentUserInfo = new BehaviorSubject("");
  public $currentUserInfo = this.currentUserInfo.asObservable()

  MyUserInfo(): Observable<User> {
    return this.http.get<User>(`${environment.API_URL}users/me`).pipe(
      map((userInfo: User) => {
        this.currentUserInfo.next(userInfo.accessLevel);
        return userInfo;
      })
    );
  }

  orgUsersList() {
    return this.http
      .get(`${environment.API_URL}users/org-users`)
      .toPromise()
      .then((response: User[]) => {
        return response;
      });
  }

  DeleteOrgUser(id) {
    return this.http
      .delete(`${environment.API_URL}organization-info/org-user/${id}`)
      .toPromise()
      .then((response: User) => {
        return response;
      });
  }

  // Get Provider Info
  GetProviderInfo(): Observable<Provider> {
    return this.http.get(`${environment.API_URL}provider-registration`).pipe(
      map((provider: Provider) => {
        return provider;
      })
    );
  }
  UpdateProviderInfo(info): Observable<Provider> {
    return this.http
      .put(`${environment.API_URL}provider-registration`, info)
      .pipe(
        map((provider: Provider) => {
          return provider;
        })
      );
  }
  UpdateProviderAvatar(image): Observable<Provider> {
    const formData: FormData = new FormData();
    formData.append("fileKey", image, image.name);
    return this.http
      .put(`${environment.API_URL}provider-registration/changeAvatar`, formData)
      .pipe(
        map((provider: Provider) => {
          return provider;
        })
      );
  }
  UpdateProviderPassword(password): Observable<Provider> {
    return this.http
      .put(`${environment.API_URL}provider-registration`, password)
      .pipe(
        map((provider: Provider) => {
          return provider;
        })
      );
  }
  updateUser(body) {
    return this.http
      .put(`${environment.API_URL}users/updateMe`, body)
      .toPromise()
      .then((res: User) => {
        return res;
      });
  }

  createOrgUser(user) {
    return this.http
      .post(`${environment.API_URL}users/create-org-user`, user)
      .toPromise()
      .then(res => {
        return res;
      });
  }

  signUpOrganization(body: any) {
    return this.http
      .post(`${environment.API_URL}organization-info/signup`, body)
      .pipe(
        map((res: any) => {
          const {
            token,
            user: { organization = null }
          } = res;
          localStorage.setItem("auth_token", token);
          return res;
        })
      );
  }

  getOrgUser(id) {
    return this.http.get(`${environment.API_URL}users/${id}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateOrgUser(id, body) {
    return this.http.put(`${environment.API_URL}users/${id}`, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  Signature(file): Observable<any> {
    return this.http
      .post(`${environment.API_URL}provider-registration/signature/`, file)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  attestSignature(file): Observable<any> {
    return this.http
      .post(`${environment.API_URL}provider-registration/attest-signature/`, file)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  Forgot(email): Observable<any> {
    return this.http
      .post(`${environment.API_URL}users/forgot-password`, { email })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  resetPassword(Password, resePassId): Observable<any> {
    return this.http
      .post(`${environment.API_URL}recover-password/${resePassId}`, {
        Password
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  AgentSetPassword(token, password = null): Promise<any> {
    if (!password) {
      return this.http
        .get(`${environment.API_URL}agent/set-password/${token}`)
        .toPromise()
        .then((res) => {
          return res;
        });
    } else {
      return this.http
        .post(`${environment.API_URL}agent/set-password/${token}`, { password })
        .toPromise()
        .then((res) => {
          return res;
        });
    }

  }


  toggleProviderFlag(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}users/toggle-flag/${userId}`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  generateCommonFormFile(): Observable<any> {
    return this.http
      .get(`${environment.API_URL}users/generateCV`,{ responseType: "blob" })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  correctLogo(userInfo? : any ):{logo: string, newLogo: string}{
        if(!userInfo && window.location.host.indexOf(ORGANIZATIONS_LOGO.MATCH_ME.host) < 0) return {
          logo: "",
          newLogo: ""
        }
        if(window.location.host.indexOf(ORGANIZATIONS_LOGO.MATCH_ME.host) > -1 ||
            (userInfo && userInfo.role === "provider" && userInfo.allowed &&
              userInfo.allowed.length && userInfo.allowed.findIndex(x=> x.primaryEmail === ORGANIZATIONS_LOGO.MATCH_ME.email) > -1) ||
            (userInfo.organization && userInfo.organization.primaryEmail === ORGANIZATIONS_LOGO.MATCH_ME.email)) 
            return {
              logo: ORGANIZATIONS_LOGO.MATCH_ME.logo,
              newLogo: ORGANIZATIONS_LOGO.MATCH_ME.newLogo
            }
        
        return  {
          logo: ORGANIZATIONS_LOGO.EDDYNOW.logo,
          newLogo: ORGANIZATIONS_LOGO.EDDYNOW.newLogo
        }
    
  }
}
