import { Action } from '@ngrx/store';
import { Provider } from 'src/app/view-models/provider';
import { AgentModel } from 'src/app/view-models/agent';
import { AttachmentItemCount } from 'src/app/view-models/attachment-item-count';
import { CVModel } from 'src/app/view-models/cv-model';

export enum ProviderActionTypes {
    SetProvider = '[Provider] Set Provider Info',
    LoadProvider = '[Provider] Load Provider Info',
    LoadProviderSuccess = '[Provider] Load Provider Info Successfully',
    LoadAgents = '[Provider] Load Agents Info',
    LoadAgentsSuccess = '[Provider] Load Agents Info Successfully',
    LoadAttachments = '[Provider] Load Attachments',
    LoadAattachmentsSuccess = '[Provider] Load Attachments Successfully',
    LoadCVInfo = '[Provider] Load CV Info',
    LoadCVInfoSuccess = '[Provider] Load CV Info Successfully',
    InitiazeMain = '[Provider] Initiaze Provider State',
}


export class SetProvider implements Action {
    readonly type = ProviderActionTypes.SetProvider;
    constructor(public payload: Provider){}
}
export class LoadProvider implements Action {
    readonly type = ProviderActionTypes.LoadProvider;
}
export class LoadProviderSuccess implements Action {
    readonly type = ProviderActionTypes.LoadProviderSuccess;
    constructor(public payload: Provider){}
}
export class LoadAgents implements Action {
    readonly type = ProviderActionTypes.LoadAgents;
}
export class LoadAgentsSuccess implements Action {
    readonly type = ProviderActionTypes.LoadAgentsSuccess;
    constructor(public payload: AgentModel[]){}
}
export class LoadAttachments implements Action {
    readonly type = ProviderActionTypes.LoadAttachments;
}
export class LoadAttachmentsSuccess implements Action {
    readonly type = ProviderActionTypes.LoadAattachmentsSuccess;
    constructor(public payload: AttachmentItemCount[]){}
}
export class LoadCVInfo implements Action {
    readonly type = ProviderActionTypes.LoadCVInfo;
}
export class LoadCVInfoSuccess implements Action {
    readonly type = ProviderActionTypes.LoadCVInfoSuccess;
    constructor(public payload: CVModel){}
}
export class InitiazeProvider implements Action {
    readonly type = ProviderActionTypes.InitiazeMain;
}
export type ProviderActions = InitiazeProvider
| SetProvider
| LoadProvider
| LoadProviderSuccess
| LoadAgents
| LoadAgentsSuccess
| LoadCVInfo
| LoadCVInfoSuccess
| LoadAttachments
| LoadAttachmentsSuccess;