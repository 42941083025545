import { Pipe, PipeTransform } from '@angular/core';
import { MILITARY_BRANCHES } from '../constants';

@Pipe({
    name: 'militaryBranches'
})
export class MilitaryServiceBranchesPipe implements PipeTransform {
    transform(key) {
        return key ? MILITARY_BRANCHES[key] : ''
    }
}
