import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { Areas_Of_Specialization, Specialty_Certifications, Therapeutic_Modalities } from "src/app/constants";
import { SelectOptionService } from "src/app/services/select-option.service";
import { NonEnglishLanguages } from "src/app/view-models/non-english-languages";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "personal-info-non-english-languages",
  templateUrl: "non-english-languages.component.html",
  styleUrls: ["non-english-languages.component.scss"]
})
export class NonEnglishLanguagesComponent implements OnInit {
  nonEnglishLanguageForm: FormGroup;
  nonEnglishLanguageArray: FormArray;
  nonEnglishLanguageValue: NonEnglishLanguages[];

  /** Input of Component - nonEnglishLanguages */
  @Input()
  get nonEnglishLanguages() {
    let nonEnglishLanguageArray = this.nonEnglishLanguageForm.controls
      .nonEnglishLanguageArray as FormArray;
    this.nonEnglishLanguageArray = nonEnglishLanguageArray;
    this.nonEnglishLanguageValue = this.nonEnglishLanguageArray.value;
    return this.nonEnglishLanguageValue;
  }

  @Output()
  nonEnglishLanguagesChange = new EventEmitter();

  set nonEnglishLanguages(val) {
    if (val) {
      if (val.length > 0) {
        this.nonEnglishLanguageValue = val;
        this.nonEnglishLanguageArray = this.NonEnglishLanguagesValue(val);
        this.nonEnglishLanguageForm = this.builder.group({
          nonEnglishLanguageArray: this.nonEnglishLanguageArray
        });
        // this.nonEnglishLanguageForm.setControl("nonEnglishLanguageArray", val);
        this.nonEnglishLanguagesChange.emit(this.nonEnglishLanguageArray);
      } else {
        // this.AddNonEnglishLanguages();
      }
    }
  }

  languagesOptions: Array<IOption> = this.selectOptionService.getLanguages();
  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If nonEnglishLanguages does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing nonEnglishLanguages
   */
  CreateForm() {
    this.nonEnglishLanguageArray = this.builder.array([]);
    this.nonEnglishLanguageForm = this.builder.group({
      nonEnglishLanguageArray: this.nonEnglishLanguageArray
    });

    if (!this.nonEnglishLanguageValue || this.nonEnglishLanguageValue.length == 0)
      this.AddNonEnglishLanguages();
    else this.NonEnglishLanguagesValue(this.nonEnglishLanguageValue)

  }

  // Non English Language Form Array
  get nonEnglishLanguage() {
    return <FormArray>this.nonEnglishLanguageArray;
  }

  /**
   * Create an empty form for a new Non English Language
   * @returns FormGroup
   */
  CreateNonEnglishLanguages(info: NonEnglishLanguages = null): FormGroup {
    let form = this.builder.group({
      language: [info ? info.language : ""],
      read: [info ? info.read : false],
      write: [info ? info.write : false],
      speak: [info ? info.speak : false],
    });

    return form;
  }

  /**
   * Add a new Non English Language to form array
   */
  AddNonEnglishLanguages(): void {
    let nonEnglishLanguageArray = this.nonEnglishLanguageForm.controls.nonEnglishLanguageArray as FormArray;
    nonEnglishLanguageArray.push(this.CreateNonEnglishLanguages());

    this.nonEnglishLanguageArray = nonEnglishLanguageArray;
    this.nonEnglishLanguagesChange.emit(this.nonEnglishLanguageArray);
  }

  /**
   * Remove a Non English Language from form array
   * @param index Index of the Non English Language to remove
   */
  RemoveNonEnglishLanguages(index: number): void {
    let nonEnglishLanguageArray = this.nonEnglishLanguageForm.get("nonEnglishLanguageArray") as FormArray;

    if (nonEnglishLanguageArray.length > 1) {
      nonEnglishLanguageArray.removeAt(index);
    } else {
      nonEnglishLanguageArray = this.builder.array([]);
    }
    this.nonEnglishLanguageArray = nonEnglishLanguageArray;
    this.nonEnglishLanguagesChange.emit(this.nonEnglishLanguageArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let nonEnglishLanguageArray = this.nonEnglishLanguageForm.controls
      .nonEnglishLanguageArray as FormArray;
    this.nonEnglishLanguageArray = nonEnglishLanguageArray;
    this.nonEnglishLanguagesChange.emit(this.nonEnglishLanguageArray);
  }

  /**
   * Convert Non English Language array to form array
   * @param informations JSON array of Non English Languages
   * @returns Array of FormGroup of Non English Languages
   */
  NonEnglishLanguagesValue(informations: NonEnglishLanguages[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      let cpform = this.CreateNonEnglishLanguages(info);
      formArray.push(cpform);
    });
    return formArray;
  }

}
