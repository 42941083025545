import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class SearchService {

  private _searchString = new BehaviorSubject<string>('');
  public searchString: Observable<string> = this._searchString.asObservable();
  
  constructor() {}

  setSearchString(string: string) {
    this._searchString.next(string);
  }

}
