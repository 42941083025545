import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'removeNumbers',
  pure: false
})
export class RemoveNumbersPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) return null
    return value.split(/\d+/gm).join('')
  }
}
