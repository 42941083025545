import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input('question') question;
  @Input('answer') answer;

  isOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  changeAccordion(event) {
    this.isOpen = event.nextState;
  }

}
