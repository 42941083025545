import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProviderPayerService } from 'src/app/services/provider-payer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateTask } from 'src/app/view-models/create-task';
import { OrganizationPayerService } from 'src/app/services/organization-payer.service';
import { UpdateTask } from 'src/app/view-models/update-task';
import { DateModel } from 'src/app/view-models/date-model';

@Component({
    selector: 'eddy-create-task',
    templateUrl: 'create-task.component.html',
    styleUrls: ['create-task.component.scss'],
})

export class CreateTaskComponent implements OnInit {
    task: FormGroup;
    newTask: CreateTask;
    updateTask: UpdateTask;
    constructor(
        public dialogRef: MatDialogRef<CreateTaskComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private service: OrganizationPayerService,
        private builder: FormBuilder
    ) { }

    ngOnInit() {
        this.task = this.builder.group({
            title: ['', Validators.required],
            description: ['', Validators.required],
            task_date: ['', Validators.required],
            task_time: ['', Validators.required]
        })
        if(this.data.detail) {
            this.task.patchValue({
                title: this.data.detail.title,
                description: this.data.detail.description,
                task_date: this.ConvertStringToDate(this.data.detail.task_date),  
                taske_time: this.data.detail.task_time,  
            })
        }
    }
    CreateTask() {
        this.newTask = {
            title: this.task.value.title,
            description: this.task.value.description,
            task_date: this.service.ConvertDateToString(this.task.value.task_date), 
            task_time: this.task.value.task_time
        }
        this.service.CreateTask(this.newTask).subscribe((result) => {
            this.dialogRef.close(true);
        })
    }
    UpdateTask() {
        this.updateTask = {
            id: this.data.taskId,
            title: this.task.value.title,
            description: this.task.value.description,
            task_date: this.service.ConvertDateToString(this.task.value.task_date),  
            task_time: this.task.value.task_time
        }
        this.service.UpdateTask(this.updateTask).subscribe((result) => {
            this.dialogRef.close(true);
        })
    }
    ConvertStringToDate(date: any): DateModel {
        let dateT = new Date(date);
        let inputDate: DateModel = {
            year: Number(dateT.toISOString().substr(0,4)),
            month: Number(dateT.toISOString().substr(5,2)),
            day: Number(dateT.toISOString().substr(8,2)),
        }
        return inputDate;
    }
    onDateChange(e) {
        // this.service.onDateChange(e);
        e.target.value.split('-')[0] > 1900 ? Array.from(document.querySelectorAll('input')).forEach( input => input.blur() ) : null;
    }    
}