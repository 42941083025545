import { Action } from '@ngrx/store';
import { Provider } from 'src/app/view-models/provider';
import { AgentModel } from 'src/app/view-models/agent';
import { CommonFormStatus } from 'src/app/view-models/common-form-status';
import { MetaData, PersonalInformation } from 'src/app/view-models/personal-information';
import { DisclosureQuestions } from 'src/app/view-models/DisclosureQuestions';
import { EducationalInfo } from 'src/app/view-models/educational-info';
import { Training } from 'src/app/view-models/training';
import { Certification } from 'src/app/view-models/certification';
import { Specialties } from 'src/app/view-models/specialities';
import { CurrentPrivilege } from 'src/app/view-models/current-privilege';
import { PrimaryPractice } from 'src/app/view-models/primary-practice';
import { WorkHistory } from 'src/app/view-models/work-history';
import { Insurance } from 'src/app/view-models/insurance';
import { CommonFormState } from "../../../../../view-models/common-form-state";
import { MilitaryService } from 'src/app/view-models/military-service';
import { Reference } from 'src/app/view-models/reference';

export enum CommonFormActionTypes {
    LoadPersonalInfo = '[Common Form] Load Personal Info',
    LoadPersonalInfoSuccess = '[Common Form] Load Personal Info Successfully',
    LoadEducationalInfo = '[Common Form] Load Educational Info',
    LoadEducationalInfoSuccess = '[Common Form] Load Educational Info Successfully',
    LoadTraining = '[Common Form] Load Training',
    LoadTrainingSuccess = '[Common Form] Load Training Successfully',
    LoadCertification = '[Common Form] Load Certification',
    LoadCertificationSuccess = '[Common Form] Load Certification Successfully',
    LoadSpecialties = '[Common Form] Load Specialties',
    LoadSpecialtiesSuccess = '[Common Form] Load Specialties Successfully',
    LoadCurrentPrivilege = '[Common Form] Load CurrentPrivilege',
    LoadCurrentPrivilegeSuccess = '[Common Form] Load CurrentPrivilege Successfully',
    LoadPrimaryPractice = '[Common Form] Load Primary Practice',
    LoadPrimaryPracticeSuccess = '[Common Form] Load Primary Practice Successfully',
    LoadWorkHistory = '[Common Form] Load Work History',
    LoadWorkHistorySuccess = '[Common Form] Load Work History Successfully',
    LoadInsurance = '[Common Form] Load Insurance',
    LoadInsuranceSuccess = '[Common Form] Load Insurance Successfully',
    LoadMilitaryService = '[Common Form] Load Military Service',
    LoadMilitaryServiceSuccess = '[Common Form] Load Military Service Successfully',
    LoadProfessionalReference = '[Common Form] Load Professional Reference',
    LoadProfessionalReferenceSuccess = '[Common Form] Load Professional Reference Successfully',
    LoadDisclosureQuestions = '[Common Form] Load Disclosure Questions',
    LoadDisclosureQuestionsSuccess = '[Common Form] Load Disclosure Questions Successfully',
    LoadSummary = '[Common Form] Load Summary',
    LoadSummarySuccess = '[Common Form] Load Summary Successfully',
    InitiazeCommonForm = '[Common Form] Initiaze Common Form State',
    MetaData='[Common Form] Get other states from Meta Data'
}



export class LoadPersonalInfo implements Action {
    readonly type = CommonFormActionTypes.LoadPersonalInfo;
}
export class LoadPersonalInfoSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadPersonalInfoSuccess;
    constructor(public payload: PersonalInformation){}
}
export class LoadEducationalInfo implements Action {
    readonly type = CommonFormActionTypes.LoadEducationalInfo;
}
export class LoadEducationalInfoSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadEducationalInfoSuccess;
    constructor(public payload: EducationalInfo){}
}
export class LoadTraining implements Action {
    readonly type = CommonFormActionTypes.LoadTraining;
}
export class LoadTrainingSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadTrainingSuccess;
    constructor(public payload: Training){}
}
export class LoadCertification implements Action {
    readonly type = CommonFormActionTypes.LoadCertification;
}
export class LoadCertificationSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadCertificationSuccess;
    constructor(public payload: Certification){}
}
export class LoadSpecialties implements Action {
    readonly type = CommonFormActionTypes.LoadSpecialties;
}
export class LoadSpecialtiesSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadSpecialtiesSuccess;
    constructor(public payload: Specialties){}
}
export class LoadCurrentPrivilege implements Action {
    readonly type = CommonFormActionTypes.LoadCurrentPrivilege;
}
export class LoadCurrentPrivilegeSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadCurrentPrivilegeSuccess;
    constructor(public payload: CurrentPrivilege){}
}
export class LoadPrimaryPractice implements Action {
    readonly type = CommonFormActionTypes.LoadPrimaryPractice;
}
export class LoadPrimaryPracticeSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadPrimaryPracticeSuccess;
    constructor(public payload: PrimaryPractice[]){}
}
export class LoadWorkHistory implements Action {
    readonly type = CommonFormActionTypes.LoadWorkHistory;
}
export class LoadWorkHistorySuccess implements Action {
    readonly type = CommonFormActionTypes.LoadWorkHistorySuccess;
    constructor(public payload: WorkHistory){}
}
export class LoadInsurance implements Action {
    readonly type = CommonFormActionTypes.LoadInsurance;
}
export class LoadInsuranceSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadInsuranceSuccess;
    constructor(public payload: Insurance){}
}
export class LoadMilitaryService implements Action {
    readonly type = CommonFormActionTypes.LoadMilitaryService;
}
export class LoadMilitaryServiceSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadMilitaryServiceSuccess;
    constructor(public payload: MilitaryService[]){}
}
export class LoadProfessionalReference implements Action {
    readonly type = CommonFormActionTypes.LoadProfessionalReference;
}
export class LoadProfessionalReferenceSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadProfessionalReferenceSuccess;
    constructor(public payload: Reference[]){}
}
export class LoadDisclosureQuestions implements Action {
    readonly type = CommonFormActionTypes.LoadDisclosureQuestions;
}
export class LoadDisclosureQuestionsSuccess implements Action {
    readonly type = CommonFormActionTypes.LoadDisclosureQuestionsSuccess;
    constructor(public payload: DisclosureQuestions){}
}
export class LoadSummary implements Action {
    readonly type = CommonFormActionTypes.LoadSummary;
}
export class LoadSummarySuccess implements Action {
    readonly type = CommonFormActionTypes.LoadSummarySuccess;
    constructor(public payload: CommonFormStatus){}
}
export class InitiazeCommonForm implements Action {
    readonly type = CommonFormActionTypes.InitiazeCommonForm;
}
export class MetaDataCommonForm implements Action {
    readonly type = CommonFormActionTypes.MetaData;
    constructor(public payload: MetaData){}
}

export type CommonFormActions = InitiazeCommonForm
| LoadPersonalInfo
| LoadPersonalInfoSuccess
| LoadEducationalInfo
| LoadEducationalInfoSuccess
| LoadTraining
| LoadTrainingSuccess
| LoadCertification
| LoadCertificationSuccess
| LoadSpecialties
| LoadSpecialtiesSuccess
| LoadCurrentPrivilege
| LoadCurrentPrivilegeSuccess
| LoadPrimaryPractice
| LoadPrimaryPracticeSuccess
| LoadWorkHistory
| LoadWorkHistorySuccess
| LoadInsurance
| LoadInsuranceSuccess
| LoadMilitaryService
| LoadMilitaryServiceSuccess
| LoadProfessionalReference
| LoadProfessionalReferenceSuccess
| LoadDisclosureQuestions
| LoadDisclosureQuestionsSuccess
| LoadSummary
| LoadSummarySuccess
| MetaDataCommonForm
