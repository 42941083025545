import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonFormService } from 'src/app/services/common-form.service';
import { OrganizationInfoService } from 'src/app/services/organizationInfo.service';
import { NewHospital } from 'src/app/view-models/new-hospital';
import { GoogleAddress } from 'src/app/view-models/google-address';
import { HandleAddressChange } from 'src/util';
const ADDRESS_FIELDS = {
    street_number: 'addressStreet',
    route: 'addressApartment',
    locality: 'addressCity',
    administrative_area_level_1: 'addressState',
    postal_code: 'addressZip',
    country: 'addressCountry'
};
@Component({
    selector: 'eddy-new-hospital',
    templateUrl: 'new-hospital.component.html',
    styleUrls: ['new-hospital.component.scss']
})

export class NewHospitalComponent implements OnInit {
    hospitalForm: FormGroup;
    options: any = {
        position: ['bottom', 'right'],
        timeOut: 400
    };
    constructor(
        private builder: FormBuilder,
        public dialogRef: MatDialogRef<NewHospitalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private CFService: CommonFormService,
        private service: OrganizationInfoService
    ) { }

    ngOnInit() {
        this.CreateForm();
    }
    CreateForm() {
        this.hospitalForm = this.builder.group({
            name: ['', Validators.required],
            streetAddress: ['', Validators.required],
            streetAddress2: [''],
            stateAddress: ['', Validators.required],
            cityAddress: ['', Validators.required],
            zipAddress: ['', Validators.required],
            phone: ['', Validators.required],
            primaryEmail: ['', [Validators.required, Validators.email]],
            countryAddress: ['', Validators.required]
        })
    }
    AddNewHospital() {
        if(this.hospitalForm.valid) {
            let info: NewHospital = this.hospitalForm.value;
            info.providerCreated = true;
            this.service.AddAffiliation(info).subscribe((result)=> {
                this.dialogRef.close(result);
            })
        }else {
            this.CFService.ValidateAllFormFields(this.hospitalForm);
        }
    }
    Cancel() {
        this.dialogRef.close();
    }
    AddressChange({address_components = []}) {
        let addressInfo: GoogleAddress = HandleAddressChange(address_components, ADDRESS_FIELDS);
        this.hospitalForm.patchValue({
            streetAddress: addressInfo.addressStreet,
            streetAddress2: addressInfo.addressApartment,
            cityAddress: addressInfo.addressCity,
            stateAddress: addressInfo.addressState,
            zipAddress: addressInfo.addressZip,
            countryAddress: addressInfo.addressCountry,
        })
    }
}