import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'confirm-create-psv-archive',
    templateUrl: 'confirm-create-psv-archive.component.html',
    styleUrls: ['confirm-create-psv-archive.component.scss'],
})

export class ConfirmCreatePSVArchiveComponent implements OnInit {

    @Input() text: string = 'test';
    public yes: boolean = false;
    public archive: FormGroup;
    public title = new FormControl('', Validators.required);
    
    constructor(
        public modal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.archive = new FormGroup({
            title: this.title,
        });
    }

    setYes(state: boolean) {
        if(!state){
            this.modal.close(state);
            return;
        }
        this.yes = state;
    }

    create(state) {
        if(!state){
            this.modal.close(state);
            return;
        }
        this.modal.close(this.archive.value);
    }
    
}