import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateModel } from '../view-models/date-model';
import * as moment from 'moment';
import { CreateTask } from '../view-models/create-task';
import { Calendar } from '../view-models/calendar';
import { EnrollProvider } from '../view-models/enroll-provider';

@Injectable()
export class ProviderPayerService{
    constructor(
        public http: HttpClient
    ){}

    //provider enrollment
    GetEnrollmentProvider(id): Observable<EnrollProvider[]> {
        return this.http.get(`${environment.API_URL}provider-payer/` + id)
            .pipe(
                map((res: EnrollProvider[]) => {
                    return res;
                })
            )
    }
    GetSpecificEnrollment(payerId): Observable<EnrollProvider>{
        return this.http.get(`${environment.API_URL}provider-payer/payer/` + payerId)
            .pipe(
                map((res: EnrollProvider) => {
                    return res;
                })
            )
    }
    DeleteEnrollmentProvider(id): Observable<any> {
        return this.http.delete(`${environment.API_URL}provider-payer/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            )
    }

    EnrollProvider(info: EnrollProvider): Observable<any> {
        return this.http.post(`${environment.API_URL}provider-payer/`, info)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

    EditEnrollProvider(detail): Observable<any> {
        return this.http.put(`${environment.API_URL}provider-payer/`, detail)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    GetProviderDayEvents(id: string, detail: any): Observable<any> {
        return this.http.post(`${environment.API_URL}provider-payer/day-events/${id}`, detail)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    GetProviderCalendar(id: string): Observable<Calendar> {
        return this.http.get(`${environment.API_URL}provider-payer/calendar/${id}`)
            .pipe(
                map((result: Calendar) => {
                    return result;
                })
            );
    }
    CreateProviderTasks(id: string, task: CreateTask): Observable<any> {
        return this.http.post(`${environment.API_URL}provider-payer/tasks/${id}`, task)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    UpdateProviderTasks(id: string, detail: any): Observable<any> {
        return this.http.put(`${environment.API_URL}provider-payer/tasks/${id}`, detail)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    GetProviderTasks(id: string): Observable<any> {
        return this.http.get(`${environment.API_URL}provider-payer/tasks/${id}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    DeleteProviderTasks(taskId: string): Observable<any> {
        return this.http.delete(`${environment.API_URL}provider-payer/tasks/${taskId}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    ConvertDateToString(date: DateModel) {
        let tempDate = new Date(`${date.year}-${date.month}-${date.day}`);
        return moment(tempDate).locale('en').format('YYYY-MM-DD');
    }
}
