import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

@Component({
  selector: 'app-current-privilege',
  templateUrl: './current-privilege.component.html',
  styleUrls: ['./current-privilege.component.scss']
})
export class CurrentPrivilegeComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
