import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateModel } from '../view-models/date-model';
import * as moment from 'moment';
import { UpdateTask } from '../view-models/update-task';
import { Task } from '../view-models/task';
import { CreateTask } from '../view-models/create-task';
import { Calendar } from '../view-models/calendar';
import { Payer } from '../view-models/payer';

@Injectable()
export class OrganizationPayerService {
    constructor(
        private http: HttpClient
    ) { }
    GetCalendarInfo(): Observable<Calendar> {
        return this.http.get(`${environment.API_URL}organization-payer/organization/calendar`)
            .pipe(
                map((res: Calendar) => {
                    return res;
                })
            );
    }
    GetDayEvents(detail: any): Observable<any> {
        return this.http.post(`${environment.API_URL}organization-payer/organization/day-events`, detail)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    CreateTask(task: CreateTask): Observable<any> {
        return this.http.post(`${environment.API_URL}organization-payer/organization/tasks/`, task)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    UpdateTask(task: UpdateTask): Observable<any> {
        return this.http.put(`${environment.API_URL}organization-payer/organization/tasks/`, task)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    GetAllTasks(): Observable<Task[]> {
        return this.http.get(`${environment.API_URL}organization-payer/organization/tasks/`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    DeleteProviderTasks(taskId: number): Observable<any> {
        return this.http.delete(`${environment.API_URL}organization-payer/organization/tasks/${taskId}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    ConvertDateToString(date: DateModel) {
        let tempDate = new Date(`${date.year}-${date.month}-${date.day}`);
        return moment(tempDate).locale('en').format('YYYY-MM-DD');
    }
    getAllOrgPayer(): Observable<any>  {
        return this.http.get(`${environment.API_URL}organization-payer/`)
            .pipe(
                map((res: Calendar) => {
                    return res;
                })
            );
    }
    deletePayer(payerId): Observable<any> {
        return this.http.delete(`${environment.API_URL}organization-payer/${payerId}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    updatePayer(payerDetail): Observable<any> {
        return this.http.put(`${environment.API_URL}organization-payer/`, payerDetail)
          .pipe(
            map((res: any) => {
              return res;
            })
          );
      }
}