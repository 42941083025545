import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "certification-flu",
  templateUrl: "Flu.component.html",
  styleUrls: ["Flu.component.scss"]
})
export class ProviderFluComponent implements OnInit {
  @Input('userId') userId: string;
  fluForm: FormGroup;
  fluArray: FormArray;
  fluValue: any[];
  attachments: string[];
  /** Input of Component - flus */
  @Input()
  get flus() {
    let fluArray = this.fluForm.controls
      .fluArray as FormArray;
    this.fluArray = fluArray;
    return this.fluArray.value;
  }

  @Output()
  flusChange = new EventEmitter();

  set flus(val) {
    if (val) {
      if (val.length > 0) {
        this.fluValue = val;
        this.fluArray = this.FluValue(val);
        this.fluForm = this.builder.group({
          fluArray: this.fluArray
        });
        this.flusChange.emit(this.fluArray);
      } else {
        // this.AddFlu();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If flus does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing flus
   */
  CreateForm() {
    this.fluArray = this.fluArray ? this.fluArray : this.builder.array([]);
    this.fluForm = this.builder.group({
      fluArray: this.fluArray
    });

    if (this.fluArray.length == 0)
      this.AddFlu();
    // else this.FluValue(this.fluValue)
  }

  // FLU Form Array
  get flu() {
    return <FormArray>this.fluArray;
  }

  /**
   * Create an empty form for a new flu
   * @returns FormGroup
   */
  CreateFlu(info: any = null): FormGroup {
    let form = this.builder.group({
      issuanceFlu: [info ? info.issuanceFlu : ""],
      attachments: [info ? info.attachments : []],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new flu to form array
   */
  AddFlu(): void {
    let fluArray = this.fluForm.controls
      .fluArray as FormArray;
    fluArray.push(this.CreateFlu());

    this.fluArray = fluArray;
    this.flusChange.emit(this.fluArray);
  }

  /**
   * Remove a flu from form array
   * @param index Index of the flu to remove
   */
  RemoveFlu(index: number): void {
    let fluArray = this.fluForm.get("fluArray") as FormArray;

    if (fluArray.length > 1) {
      fluArray.removeAt(index);
    } else {
      fluArray = this.builder.array([]);
    }
    this.fluArray = fluArray;
    this.flusChange.emit(this.fluArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let fluArray = this.fluForm.controls
      .fluArray as FormArray;
    this.fluArray = fluArray;
    this.flusChange.emit(this.fluArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert flu array to form array
   * @param informations JSON array of flus
   * @returns Array of FormGroup of flus
   */
  FluValue(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          issuanceFlu: [this.service.ConvertStringToDate(info.issuanceFlu)],
          attachments: [info.attachments],
        })
      );
    });
    return formArray;
  }

  getFLUAttachments(index: number) {
    return this.fluArray.at(index).get("attachments").value ? this.fluArray.at(index).get("attachments").value : [];
  }

  onChangeFLUAttachment(event, index: number) {
    console.log(event);
    this.fluArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
