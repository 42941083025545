import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable()
export class ProfileDocumentService {

  constructor(public http: HttpClient) { }

  get(type, id?) {
    let request = this.http.get(`${environment.API_URL}attachments/${type}`)
    if (id) {
      let params = new HttpParams();
      params = params.append('applicantId', id)
      request = this.http.get(`${environment.API_URL}attachments/all`, { params })
    }
    return request.toPromise().then((res: any) => {
      return res;
    });
  }

  getAttachmentCount() {
    return this.http.get(`${environment.API_URL}attachments/total`).toPromise()
      .then((res: any) => {
        return res;
      });
  }

  uploadDocument(formData, type) {
    return this.http.post(`${environment.API_URL}attachments/${type}`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    }));
  }

  delete(fileId) {
    const url = `${environment.API_URL}attachments/${fileId}`
    return this.http.delete(`${environment.API_URL}attachments/${fileId}`).toPromise()
      .then((res: any) => {
        return res;
      })
  }

  postPdf(file, docInfo) {
    const formData: FormData = new FormData();


    formData.append('file', file);
    formData.append('docInfo', JSON.stringify(docInfo));

    return this.http.post(`${environment.API_URL}attachments/all-doc`, formData).toPromise()
      .then((res: any) => {
        return res;
      });
  }
}
