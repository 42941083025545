import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpRequest } from '@angular/common/http';
import { NbMenuItem } from '@nebular/theme';
import { ORGANIZATIONS_LOGO } from '../constants';

@Injectable()
export class AuthService {
    cachedRequests: Array<HttpRequest<any>> = [];

    constructor(
        public jwtHelper: JwtHelperService
    ) {

    }
    public collectFailedRequest(request): void {
        this.cachedRequests.push(request);
    }
    public retryFailedRequests(): void {
        // retry the requests. this method can
        // be called after the token is refreshed
    }
    public getToken(): string {
        return localStorage.getItem('auth_token');
    }
    public SetToken(token: string) {
        localStorage.setItem('auth_token', token);
    }
    public ClearToken() {
        localStorage.clear();
    }
    public isAuthenticated(): boolean {
        const token = this.getToken();
        return this.jwtHelper.isTokenExpired(token);
    }

    public getDomainConfiguration() {
        return window.location.host.indexOf(ORGANIZATIONS_LOGO.MATCH_ME.host) > -1 ? ORGANIZATIONS_LOGO.MATCH_ME : ORGANIZATIONS_LOGO.EDDYNOW
    }
}