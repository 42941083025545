import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationInfoService } from 'src/app/services/organizationInfo.service';

@Component({
    selector: 'confirm-new-psv-archive-file',
    templateUrl: 'confirm-new-psv-archive-file.component.html',
    styleUrls: ['confirm-new-psv-archive-file.component.scss'],
})

export class ConfirmNewPSVArchiveFileComponent implements OnInit {

    @Input() choise: string = '';
    @Input() providerId: any;
    public archives: any [];
    public arciveId: number;
    
    constructor(
        public modal: NgbActiveModal,
        private organizationInfoService: OrganizationInfoService
    ) {}

    ngOnInit() {
        this.getAllArchives();
    }

    getAllArchives() {
        this.organizationInfoService.GetPSVArchives(this.providerId).then(res => {
            this.archives = res;
        })
    }

    setChoise(choise: string) {
        this.choise = choise;
    }

    setFinalAnswer(choise?: string, state?: boolean, arhiveId?: number) {
        if(!state){
            this.modal.close(state);
        }
        let data = {
            choise,
            arhiveId
        }
        this.modal.close(data);
    }
    
}