import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

@Component({
  selector: 'app-professional-liability',
  templateUrl: './professional-liability.component.html',
  styleUrls: ['./professional-liability.component.scss']
})
export class ProfessionalLiabilityComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  formatAmount(amount) {
    // return amount ? numeral(amount).format() : 'N/A';
    return amount
  }
}
