import {NbMenuItem} from "@nebular/theme";

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'icon-dashboard',
    link: '/provider/dashboard',
  },
  {
    title: 'Profile',
    icon: 'icon-profile',
    link: '/provider/profile',
  },
  {
    title: 'Notifications',
    icon: 'icon-notification',
    link: '/provider/notifications',
  },
  {
    title: 'Common Form',
    icon: 'icon-common-form',
    link: '/provider/common-form',
    // expanded: false,
    // children: [
    //   {
    //     title: 'Personal Info',
    //     link: '/provider/common-form/personal-info',
    //   },
    //   {
    //     title: 'Educational Info',
    //     link: '/provider/common-form/educational-info',
    //   },
    //   {
    //     title: 'Training',
    //     link: '/provider/common-form/training',
    //   },
    //   // {
    //   //   title: 'ID Types',
    //   //   link: '/provider/common-form/certifications',
    //   // },
    //   {
    //     title: 'Specialities',
    //     link: '/provider/common-form/specialities',
    //   },
    //   {
    //     title: 'Hospital Affiliations',
    //     link: '/provider/common-form/current-privileges',
    //   },
    //   {
    //     title: 'Practices',
    //     link: '/provider/common-form/primary-practice',
    //   },
    //   {
    //     title: 'Work History',
    //     link: '/provider/common-form/work-history',
    //   },
    //   {
    //     title: 'Military Service',
    //     link: '/provider/common-form/military-service',
    //   },
    //   {
    //     title: 'Insurance',
    //     link: '/provider/common-form/professional-liability-insurance',
    //   },
    //   {
    //     title: 'Professional References',
    //     link: '/provider/common-form/professional-references',
    //   },
    //   {
    //     title: 'Disclosure Questions',
    //     link: '/provider/common-form/disclosure-questions',
    //   },
    //   {
    //     title: 'Summary',
    //     link: '/provider/common-form/summary',
    //   },
    // ],
  },
  // {
  //   title: 'References',
  //   icon: 'icon-references',
  //   link: '/provider/references',
  // },
  // {
  //   title: 'Provider Documents',
  //   icon: 'icon-documentation',
  //   link: '/provider/documentation',
  // },
  {
    title: 'Provider Data Network',
    icon: 'icon-affiliations',
    link: '/provider/affiliations',
  },
  // {
  //   title: 'Faq',
  //   icon: 'icon-faq',
  //   link: '/provider/faq',
  // },
  {
    title: 'Request Support',
    icon: 'icon-request-support',
    link: '/provider/provider-support',
  },
  {
    title: 'Dashboard',
    icon: 'icon-dashboard',
    link: '/organization/dashboard',
  },
  {
    title: 'Settings',
    icon: 'icon-setting',
    link: '/organization/setting',
  },
  {
    title: 'Profile',
    icon: 'icon-profile',
    link: '/organization/profile',
  },
  {
    title: 'Providers',
    icon: 'icon-provider',
    link: '/organization/provider',
  },
  {
    title: 'Reporting',
    icon: 'icon-references',
    link: '/organization/reporting',
  },
  {
    title: 'Tools',
    icon: 'icon-documentation',
    link: '/organization/tools',
  },
  // {
  //   title: 'Faq',
  //   icon: 'icon-faq',
  //   link: '/organization/faq',
  // },
  {
    title: 'Request Support',
    icon: 'icon-request-support',
    link: '/organization/request-support',
  },
];
