import { Component, OnInit } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { ProviderPayerService } from 'src/app/services/provider-payer.service';
import { DateModel } from 'src/app/view-models/date-model';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { User } from 'src/app/view-models/user';
import * as MainState from '../../../pages/state/main.reducer';
import { MatDialog } from '@angular/material';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationPayerService } from 'src/app/services/organization-payer.service';
import { Task } from 'src/app/view-models/task';
import { CalendarPayer } from 'src/app/view-models/calendar-payer';
import { Calendar } from 'src/app/view-models/calendar';
import * as moment from 'moment';

@Component({
    selector: 'eddy-calendar',
    templateUrl: 'calendar.component.html',
    styleUrls: ['calendar.component.scss']
})

export class CalendarComponent implements OnInit {
    model;
    user: User;
    date: DateModel;
    tasks: Task[];
    enrollments: CalendarPayer[];
    followUp;
    constructor(
        private sidebarService: NbSidebarService,
        private calendar: NgbCalendar,
        private service: OrganizationPayerService,
        private store: Store<State>,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.store.pipe(select(MainState.GetMyInfo)).subscribe((result: User) => {
            if(result) {
                this.user = result
                this.GetTodayCalendar();
            }
        })
    }
    GetCalendar() {
        this.service.GetCalendarInfo().subscribe((result: Calendar) => {
            this.tasks = result.tasks;
            this.enrollments = result.payers;
            this.followUp = result.follow_up_payers;

        })
    }
    Toggle() {
        this.sidebarService.collapse('right');
    }
    GetTodayCalendar() {
        let newDate = moment(new Date()).locale('en').format('YYYY-MM-DD'); 
        let info = {
            day: newDate
        }
        this.service.GetDayEvents(info).subscribe((result: Calendar) => {
            this.tasks = result.tasks;
            this.enrollments = result.payers;
            this.followUp = result.follow_up_payers;

        })
    }
    DateSelection(date: DateModel) {
        let info = {
            day: this.service.ConvertDateToString(date)
        }
        this.service.GetDayEvents(info).subscribe((result: Calendar) => {
            this.tasks = result.tasks;
            this.enrollments = result.payers;
            this.followUp = result.follow_up_payers;
        })
    }
    CreateTask() {
        const dialogRef = this.dialog.open(CreateTaskComponent, {
            width: '70%',
            data: { 
                id: this.user.id,
                status: 'new' 
            }
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.GetCalendar();
            }
          });
    }
    UpdateTask(task: Task) {
        const dialogRef = this.dialog.open(CreateTaskComponent, {
            width: '70%',
            data: { 
                id: this.user.id,
                detail: task,
                status: 'update' 
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.GetCalendar();
            }
        });
    }
    DeleteTask(task: Task) {
      this.service.DeleteProviderTasks(task.id).subscribe((result) => {
          if(this.model) {
            this.DateSelection(this.model);
          }else {
              this.GetCalendar();
          }
      } )
    }
}