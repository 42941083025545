import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "certification-tb",
  templateUrl: "TB.component.html",
  styleUrls: ["TB.component.scss"]
})
export class ProviderTBComponent implements OnInit {
  @Input('userId') userId: string;
  TBForm: FormGroup;
  TBArray: FormArray;
  TBValue: any[];
  attachments: string[];
  /** Input of Component - TBs */
  @Input()
  get TBs() {
    let TBArray = this.TBForm.controls
      .TBArray as FormArray;
    this.TBArray = TBArray;
    return this.TBArray.value;
  }

  @Output()
  TBsChange = new EventEmitter();

  set TBs(val) {
    if (val) {
      if (val.length > 0) {
        this.TBValue = val;
        this.TBArray = this.TB_Value(val);
        this.TBForm = this.builder.group({
          TBArray: this.TBArray
        });
        this.TBsChange.emit(this.TBArray);
      } else {
        // this.AddTB();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If TBs does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing TBs
   */
  CreateForm() {
    this.TBArray = this.TBArray ? this.TBArray : this.builder.array([]);
    this.TBForm = this.builder.group({
      TBArray: this.TBArray
    });

    if (this.TBArray.length == 0)
      this.AddTB();
    // else this.TBValue(this.TBValue)
  }

  // Coverage Plan Form Array
  get TB() {
    return <FormArray>this.TBArray;
  }

  /**
   * Create an empty form for a new TB
   * @returns FormGroup
   */
  CreateTB(info: any = null): FormGroup {
    let form = this.builder.group({
      issuanceTB: [info ? info.issuanceTB : ""],
      attachments: [info ? info.attachments : []],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new TB to form array
   */
  AddTB(): void {
    let TBArray = this.TBForm.controls
      .TBArray as FormArray;
    TBArray.push(this.CreateTB());

    this.TBArray = TBArray;
    this.TBsChange.emit(this.TBArray);
  }

  /**
   * Remove a TB from form array
   * @param index Index of the TB to remove
   */
  RemoveTB(index: number): void {
    let TBArray = this.TBForm.get("TBArray") as FormArray;

    if (TBArray.length > 1) {
      TBArray.removeAt(index);
    } else {
      TBArray = this.builder.array([]);
    }
    this.TBArray = TBArray;
    this.TBsChange.emit(this.TBArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let TBArray = this.TBForm.controls
      .TBArray as FormArray;
    this.TBArray = TBArray;
    this.TBsChange.emit(this.TBArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert TB array to form array
   * @param informations JSON array of TBs
   * @returns Array of FormGroup of TBs
   */
  TB_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          issuanceTB: [this.service.ConvertStringToDate(info.issuanceTB)],
          attachments: [info.attachments],
        })
      );
    });
    return formArray;
  }

  getTBAttachments(index: number) {
    return this.TBArray.at(index).get("attachments").value ? this.TBArray.at(index).get("attachments").value : [];
  }

  onChangeTBAttachment(event, index: number) {
    console.log(event);
    this.TBArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
