import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AgentService } from 'src/app/services/agent.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import * as ProviderActions from '../../../pages/provider/state/provider.actions';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/view-models/user';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'eddy-add-agent',
    templateUrl: 'add-agent.component.html',
    styleUrls: ['add-agent.component.scss']
})

export class AddAgentComponent implements OnInit {
    agent: FormGroup;
    user;
    userId
    constructor(
        private builder: FormBuilder,
        private agentService: AgentService,
        public dialogRef: MatDialogRef<AddAgentComponent>,
        private store: Store<State>,
        private service: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
        this.userId = data && data.userId ? data.userId : null;
    }

    ngOnInit() {
        this.AgentForm();
        this.getRole();
    }
    AgentForm() {
        this.agent = this.builder.group({
            email: ['', [Validators.email, Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            isCheckTerms: [false, [Validators.requiredTrue, Validators.required]],
        });

    }
    AddNewAgent() {
        if (this.user != 'providerAgent') {
            const body = this.userId ? { ...this.agent.value, isAddByOrg: true } : this.agent.value;
            this.agentService.AddNewAgent(body, this.userId).subscribe((result) => {

                this.store.dispatch(new ProviderActions.LoadAgents());
                this.dialogRef.close();
            })
        }

    }
    getRole() {

        this.service.MyUserInfo().subscribe((userInfo: User) => {
            if (userInfo) {
                this.user = userInfo.role;
            }
        })
    }
}