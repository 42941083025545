import { Pipe, PipeTransform } from '@angular/core';
import { DPM, PROVIDER_TYPE_ABBR } from '../constants';

@Pipe({
  name: 'PrimarySpecialty'
})
export class PrimarySpecialtyPipe implements PipeTransform {
  transform(key) {
    // if(!key) return key;
    if (key && key.indexOf('0') > -1)
      return PROVIDER_TYPE_ABBR[key];
    return key;
  }
}
