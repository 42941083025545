import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { PaymentComponent } from './payment/payment.component';

const routes: Routes = [
  { 
    path: '', 
    canActivate: [AuthGuard], 
    loadChildren: './pages/pages.module#PagesModule' 
  },
  { 
    path: 'auth', 
    loadChildren: './authentication/authentication.module#AuthenticationModule' 
  },
  {
    path: 'payment/:status',
    canActivate: [AuthGuard], 
    component: PaymentComponent
  },
  { 
    path: '', 
    loadChildren: './free-page/free-page.module#FreePageModule' 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
