import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privileges',
  templateUrl: './privileges.component.html',
  styleUrls: ['./privileges.component.scss']
})
export class PrivilegesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
