import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    text: string;
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    if(value.length>limit) {
        this.text = `${value.substr(0, limit)}${ellipsis}`;
    }else {
        this.text = value;
    }

    return this.text;
  }
}