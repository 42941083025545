import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";

@Component({
  selector: "certification-passport",
  templateUrl: "passport.component.html",
  styleUrls: ["passport.component.scss"]
})
export class ProviderPassportComponent implements OnInit {
  @Input('userId') userId: string;
  passportForm: FormGroup;
  passportArray: FormArray;
  passportValue: any[];

  /** Input of Component - passports */
  @Input()
  get passports() {
    let passportArray = this.passportForm.controls
      .passportArray as FormArray;
    this.passportArray = passportArray;
    return this.passportArray;
  }

  @Output()
  passportsChange = new EventEmitter();

  set passports(val) {
    if (val) {
      if (val.length > 0) {
        this.passportArray = val;
        this.passportForm.setControl("passportArray", val);
        this.passportsChange.emit(this.passportArray);
      } else {
        // this.AddPassport();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
  }

  /**
   * Create Form
   *  If passports does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing passports
   */
  CreateForm() {
    this.passportArray = this.passportArray ? this.passportArray : this.builder.array([]);
    this.passportForm = this.builder.group({
      passportArray: this.passportArray
    });

    if (this.passportArray.length == 0)
      this.AddPassport();
    // else this.PassportValue(this.passportValue)
  }

  // Coverage Plan Form Array
  get passport() {
    return <FormArray>this.passportArray;
  }

  /**
   * Create an empty form for a new passport
   * @returns FormGroup
   */
  CreatePassport(info: any = null): FormGroup {
    let form = this.builder.group({
      expirePassport: [info ? info.expirePassport : ""],
      passportNum: [info ? info.passportNum : ""],
      issuancePassport: [info ? info.issuancePassport : ""],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new passport to form array
   */
  AddPassport(): void {
    let passportArray = this.passportForm.controls
      .passportArray as FormArray;
    passportArray.push(this.CreatePassport());

    this.passportArray = passportArray;
    this.passportsChange.emit(this.passportArray);
  }

  /**
   * Remove a passport from form array
   * @param index Index of the passport to remove
   */
  RemovePassport(index: number): void {
    let passportArray = this.passportForm.get("passportArray") as FormArray;

    if (passportArray.length > 1) {
      passportArray.removeAt(index);
    } else {
      passportArray = this.builder.array([]);
    }
    this.passportArray = passportArray;
    this.passportsChange.emit(this.passportArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let passportArray = this.passportForm.controls
      .passportArray as FormArray;
    this.passportArray = passportArray;
    this.passportsChange.emit(this.passportArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert passport array to form array
   * @param informations JSON array of passports
   * @returns Array of FormGroup of passports
   */
  PassportValue(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          expirePassport: [info.expirePassport],
          passportNum: [info.passportNum],
          issuancePassport: [info.issuancePassport],
        })
      );
    });
    return formArray;
  }

}
