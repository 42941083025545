import { Action } from '@ngrx/store';
import { User } from 'src/app/view-models/user';

export enum MainActionTypes {
    SetUser = '[Main] Set User Info',
    SetLoading = '[Main] Set Loading Status',
    LoadUser = '[Main] Load User Info',
    LoadUserSuccess = '[Main] Load User Info Successfully',
    InitiazeMain = '[Main] Initiaze Main State',
}


export class SetUser implements Action {
    readonly type = MainActionTypes.SetUser;
    constructor(public payload: User){}
}
export class SetLoading implements Action {
    readonly type = MainActionTypes.SetLoading;
    constructor(public payload: boolean){}
}
export class LoadUser implements Action {
    readonly type = MainActionTypes.LoadUser;
}
export class LoadUserSuccess implements Action {
    readonly type = MainActionTypes.LoadUserSuccess;
    constructor(public payload: User){}
}
export class InitiazeMain implements Action {
    readonly type = MainActionTypes.InitiazeMain;
}
export type MainActions = InitiazeMain
| SetUser
| SetLoading 
| LoadUser
| LoadUserSuccess;