import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/httpconfig.interceptor';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { NbThemeModule, NbLayoutModule, NbSidebarService, NbMenuModule, NbToastrModule } from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './services/user.service';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DashboardService } from './services/dashboard.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { BrowserStorage } from './services/browser-storage.service';
import { AuthorizationService } from './services/authorization.service';
import { ToastrModule } from 'ngx-toastr';
import { ProviderAllowedAuthGuardService } from './guards/provider-allowed-guard.service';
import { OrganizationAllowedAuthGuardService } from './guards/organization-allowed-guard.service';
import { AgentService } from './services/agent.service';
import { NotificationService } from './services/notifications.service';
import { AffiliationService } from './services/affiliation.service';
import { ReferenceService } from './services/reference.service';
import { LocationService } from './services/location.service';
import { OrganizationInfoService } from './services/organizationInfo.service';
import { OrganizationProfileService } from './services/organization-profile.service';
import { AttachmentsService } from './services/attachments.service';
import { SelectOptionService } from './services/select-option.service';
import { CommonFormService } from './services/common-form.service';
import { OrganizationSettingService } from './services/organization-settings.service';
import { ProfileDocumentService } from './services/profile-document.service';
import { RequestSupportService } from './services/request-support.service';
import { FaqsService } from './services/faqs.service';
import { SpinnerService } from './services/spinner.service';
import { ReportingService } from './services/reporting.service';
import { ProviderVerificationService } from './services/provider-verification.service';
import { OrganizationProviderService } from './services/organization-provider.service';
import { SubscriptionService } from './services/subscription.service';
import { PaymentComponent } from './payment/payment.component';
import { SharedModule } from './shared/shared.module';
import { ProviderPayerService } from './services/provider-payer.service';
import { OrganizationPayerService } from './services/organization-payer.service';
import {NgxMaskModule, IConfig} from 'ngx-mask'
import { organizationCorrespondenceService } from './services/organization-correspondence.service';
import { CustomizedAlertsService } from './services/customized-alerts.service';
import { SourceVerificationService } from './services/source-verification.service';
import { ProviderNotesService } from './services/provider-notes.service';
import { RequestSupportReplyService } from './services/request-support-reply.service';
import { ContactService } from './services/contact.service';
import { SideNavService } from './services/side.nav.service';
import { SearchService } from './services/search.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';


export function tokenGetter() {
  return localStorage.getItem('auth_token');
}

@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,

  ],
  imports: [
    NgxMaskModule.forRoot(),
    NbToastrModule.forRoot(),
    NgbModule.forRoot(),
    NbMenuModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // NgxJsonViewerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    }),
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    Ng2TelInputModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'Eddy Now Devtools',
      maxAge: 25,
      logOnly: environment.production
    }),
    ToastrModule.forRoot(),
    SharedModule
  ],
  providers: [
    AuthService,
    NbSidebarService,
    AuthGuard,
    UserService,
    SourceVerificationService,
    LocationService,
    OrganizationInfoService,
    DashboardService,
    AffiliationService,
    AuthorizationService,
    OrganizationProfileService,
    BrowserStorage,
    ProviderAllowedAuthGuardService,
    OrganizationAllowedAuthGuardService,
    AgentService,
    NotificationService,
    AffiliationService,
    ReferenceService,
    AttachmentsService,
    SelectOptionService,
    CommonFormService,
    OrganizationSettingService,
    ProfileDocumentService,
    RequestSupportService,
    RequestSupportReplyService,
    FaqsService,
    ReportingService,
    SpinnerService,
    OrganizationProviderService,
    SideNavService,
    ProviderVerificationService,
    ProviderPayerService,
    OrganizationPayerService,
    SubscriptionService,
    organizationCorrespondenceService,
    CustomizedAlertsService,
    ContactService,
    ProviderNotesService,
    SearchService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }