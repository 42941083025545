import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MatSnackBar } from '@angular/material';
import { ShowMessageComponent } from '../show-message/show-message.component';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import * as ProviderState from '../../../pages/provider/state/provider.reducer';
import * as ProviderAction from '../../../pages/provider/state/provider.actions';
import { AttachmentItemCount } from 'src/app/view-models/attachment-item-count';
@Component({
  selector: 'eddy-upload-file',
  templateUrl: 'upload-file.component.html',
  styleUrls: ['upload-file.component.scss'],
})

export class UploadFileComponent implements OnInit {
  /** If you want upload attachment for another user, set user id. */
  @Input() userId: string = null;
  @Input() docInfo: string;
  @Input() text: string;
  @Input() uploadApi: string = 'attachments';
  @Output() onUploaded: EventEmitter<any> = new EventEmitter();
  count: string = '0';
  constructor(
    private service: AttachmentsService,
    private snackBar: MatSnackBar,
    private store: Store<State>
  ) { }

  ngOnInit() {
    this.GetCount();
  }
  Dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file

          this.service.CFAttachments(file, this.docInfo, this.uploadApi, this.userId).subscribe((result) => {
            this.onUploaded.emit(result);
            this.openSnackBar(result);
            if (!this.userId) // if userId is null, it means user is current provider, then we can get attachments.
              this.store.dispatch(new ProviderAction.LoadAttachments());
          })

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;

      }
    }
  }
  openSnackBar(info) {
    this.snackBar.openFromComponent(ShowMessageComponent, {
      duration: 1000,
      panelClass: 'success-message',
      data: info,
    });
  }
  GetCount() {
    if (!this.userId) // if userId is null, it means user is current provider, then we can get attachment count.
      this.store.pipe(select(ProviderState.GetAttachments)).subscribe((attachments: AttachmentItemCount[]) => {
        for (let item of attachments) {
          if (item.type === this.docInfo) {
            this.count = item.count;
          }
        }
      })
  }

}