import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        this.toInteger(dateParts[0]) > 2030 ? dateParts[0] = '2030' : null;
        return {
          year: this.toInteger(dateParts[0]),
          month: this.toInteger(dateParts[1]), 
          day: this.toInteger(dateParts[2])
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    // date && this.isNumber(date.year) && date.year > 2030 ? date.year = 2030 : null;
    return date
          ? `${this.leadingZero(date.year, 'year')}-${this.isNumber(date.month) ? this.leadingZero(date.month, 'month') : ''}-${this.isNumber(date.day) ? this.leadingZero(date.day, 'day') : ''}`
          : '';
    }
  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
  isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }
  leadingZero(value, type: string): string {
    const digits = ( type === 'year' ) ? 4 : 2;
    if(this.isNumber(value)) {
      return `000${value}`.slice(-1 * digits);
    } else {
      return '';
    }
  }
}
