import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SpinnerService {

    private serviceCalled$ = new BehaviorSubject<boolean>(false);
    public serviceCalled: Observable<boolean> = this.serviceCalled$.asObservable();

    constructor() { }

    set spinner(size: any) {
        this.serviceCalled$.next(size);
    }
    get spinner() {
        return this.serviceCalled;
    }
    onn() {
        this.spinner = true;
    }
    off() {
        this.spinner = false;
    }
}