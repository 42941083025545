import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderVerification } from '../view-models/provider-verification';

@Injectable()
export class ProviderVerificationService{
  constructor(
      public http: HttpClient
  ){}

  GetProviderInfo(userId: string): Observable<ProviderVerification[]> {
    return this.http.get(`${environment.API_URL}provider-verification/${userId}`)
      .pipe(
        map((result: ProviderVerification[]) => {
          return result
        })
      )
  }
  Review(itemId: string,reviewedAt): Observable<any> {
    return this.http.patch(`${environment.API_URL}provider-verification/reviewed/${itemId}`,{reviewedAt})
      .pipe(
        map((result: any) => {
          return result
        })
      )
  }

  Receive(providerId: string, receiveData:any): Observable<any> {
    return this.http.put(`${environment.API_URL}provider-verification/dateRecieved/${providerId}`, receiveData)
      .pipe(
        map((result: any) => {
          return result
        })
      )
  }


  ChangeContact(providerId: string, receiveData:any): Observable<any> {
    return this.http.put(`${environment.API_URL}provider-verification/contact-attempt/${providerId}`, receiveData)
      .pipe(
        map((result: any) => {
          return result
        })
      )
  }

  ClearProviderVerificationItems(userId: string): Observable<ProviderVerification[]> {
    return this.http.get(`${environment.API_URL}provider-verification/clear/${userId}`)
      .pipe(
        map((result: any) => {
          return result
        })
      )
  }

}
