import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Directive({
    selector: '[appHasPermissions]'
})
export class HasPermissionDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
        private userService: UserService,
    ) {
    }


    @Input()
    set appHasPermissions(userPermission: string[]) {
        // get element
        const viewRootElement = this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
        // get current permission
        this.userService.$currentUserInfo.subscribe(currentUserAccessLevel => {
            // check permissions if not found disable or hide section
            if (currentUserAccessLevel && userPermission.findIndex(u => u === currentUserAccessLevel) === -1) {
                viewRootElement.tagName === 'BUTTON' ? this.disableComponent() : this.removeComponent();
            }
        });
    }

    disableComponent(): void {
        this.viewContainerRef.clear();
        const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
        viewRootElement.classList.add("non-active-actionBtn");
        this.renderer.setProperty(viewRootElement, 'disabled', true);
    }

    enableComponent(): void {
        this.viewContainerRef.clear();
        const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
        this.renderer.setProperty(viewRootElement, 'disabled', false);
    }

    removeComponent(): void {
        this.viewContainerRef.clear();
    }

    showComponent(): void {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(
            this.templateRef
        ).rootNodes[0];
    }

}
