import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'eddy-sign-out',
    templateUrl: 'sign-out.component.html',
    styleUrls: ['sign-out.component.scss']
})

export class SignOutComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<SignOutComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() { }
    SignOut(des: boolean) {
        this.dialogRef.close(des);
    }
}