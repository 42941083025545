import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import DevExpress from "devextreme/bundles/dx.all";
import data = DevExpress.data;
import {ProviderNotesService} from "../../../services/provider-notes.service";

@Component({
    selector: 'eddy-show-pdf',
    templateUrl: 'show-pdf.component.html',
    styleUrls: ['show-pdf.component.scss']
})

export class ShowPDFComponent implements OnInit {
    pdfSrc = '';
    constructor(
        public dialogRef: MatDialogRef<ShowPDFComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {urlPDF:string; noteId:string|number; runFlag:boolean;isHasFlag:boolean},
        private providerNotesSerivce: ProviderNotesService,
    ) { }

    ngOnInit() {
        if (this.data) {
            this.pdfSrc = this.data.urlPDF;
        }
    }

  setFlag(){
    this.providerNotesSerivce.setFlag(this.data.noteId,!this.data.runFlag).subscribe((res)=>{
      this.data.runFlag=!this.data.runFlag
    })
  }
}
