import { FormArray, FormGroup } from "@angular/forms";

export class ResetForm {
    static resetFormArray(formArray: FormArray) {
        formArray.reset();
        formArray.controls.forEach((form: FormGroup) => {
            Object.keys(form.controls).forEach(key => {
                form.controls[key].setErrors(null); // remove all validation error
            });
        })
    }

    static resetFrom(form: FormGroup) {
        form.reset();
        Object.keys(form.controls).forEach(key => {
            form.controls[key].setErrors(null);
        });
    }
}