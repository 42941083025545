import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'archive-file-modal',
    templateUrl: 'archive-file-modal.component.html',
    styleUrls: ['archive-file-modal.component.scss'],
})

export class ArchiveFileModalComponent implements OnInit {

    @Input('archiveData') archiveData;
    public archiveLink: string;
    openCredList:boolean = false
    constructor(
        public modal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.archiveLink = `${environment.Base_URL}${this.archiveData.screenshot}`
        this.archiveData.psvNotes.map(note => {
            note.fileName = `${environment.Base_URL}/providers/${note.userId}/documents/verificationNotes/${note.fileName}`;
        })
    }

    openLink(url) {
        let param = `scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,
            width=1200,height=800,left=300,top=100`;
        open(url, 'document-link', param);
    }

}
