import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AttachmentsService } from "src/app/services/attachments.service";
import { LocationService } from "src/app/services/location.service";
import { OrganizationSettingService } from "src/app/services/organization-settings.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { FileTemplate, IFileTemplate, IFileTemplateList } from "src/app/view-models/file-template";
import { Location } from "src/app/view-models/location";
import { IOption } from "src/app/view-models/ng-select-option";
import { environment } from "src/environments/environment";
import { ShowPDFComponent } from "../show-pdf/show-pdf.component";


@Component({
  selector: "app-new-file-template",
  templateUrl: "./add-file-template.component.html",
  styleUrls: ["./add-file-template.component.scss"],
})
export class AddFileTemplateComponent implements OnInit {
  @ViewChild("fileTemplateModal") fileTemplateModal;
  @Output("templateSaved") templateSaved = new EventEmitter<any>();
  @Output("providerDeleted") providerDeleted = new EventEmitter<any>();
  @Input("bulkCreate") bulkCreate : boolean = false;
  templateForm: IFileTemplate = {
    templateName: "",
    fileTemplateProviders: [],
    filetemplateDocuments: [],
    fileTemplateUsers: [],
    locations: [],
    status: "ACTIVE",
  };
  orgLocations: IOption[] = [];
  status: IOption[] = [
    { value: "ACTIVE", label: "Active" },
    { value: "ARCHIVE", label: "Archive" },
  ];
  isEdit: boolean = false;
  providerTypeList: IOption[] = [];
  documentList: Array<IOption>;
  templateId;
  providersList: any[] = [];
  fileTemplateList: IFileTemplateList = [];
  selectedFileTemplate: FileTemplate = null;

  constructor(
    private modalService: NgbModal,
    private locationService: LocationService,
    private selectOptionService: SelectOptionService,
    private orgSettingService: OrganizationSettingService,
    private toast: ToastrService,
    private attachmentService: AttachmentsService,
    public dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.getDocumentList();
  }

  addTemplate(form: NgForm): void {
    let clonedData: IFileTemplate = null;
    clonedData = JSON.parse(JSON.stringify(this.templateForm));
    if (this.isEdit) {
      this.orgSettingService
        .UpdateFileTemplate(clonedData, this.templateId)
        .subscribe((res) => {
          form.reset();
          this.templateForm.filetemplateDocuments = [];
          this.modalService.dismissAll();
          this.templateSaved.emit();
          this.toast.success("Template Updated!");
        });
    } else {
      this.orgSettingService.SaveFileTemplate(clonedData).subscribe((res) => {
        form.reset();
        this.templateForm.filetemplateDocuments = [];
        this.modalService.dismissAll();
        this.templateSaved.emit();
        this.toast.success(res.message);
      });
    }
  }

  public open(templateData: FileTemplate = null, providersList: any[] = null): void {
    this.makeFormEmpty();
    if(!providersList){
      this.getLocationList();
      this.getProviderTypeList();
    }
    if (templateData) {
      this.isEdit = true;
      this.patch(templateData);
      this.templateId = templateData.id;
    } else {
      this.isEdit = false;
    }
    if(providersList){
      this.providersList = providersList;
      this.templateForm.fileTemplateUsers = providersList.map((provider) => provider.value);
      const providers = this.providersList.map((provider) => {
        return { 
        id: provider.value,
        locations: provider.locations.join(","),
        providerTypes: provider.providerType
        }
      })
      this.getFileTemplateList(providers)
    }
    this.modalService
      .open(this.fileTemplateModal, {
        ariaLabelledBy: "add-file-template-modal",
        size: <any>"xl",
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  patch(templateData: FileTemplate) {
    this.templateForm = {
      templateName: templateData.templateName,
      fileTemplateProviders: templateData.fileTemplateProviderInfo.map(
        (provider) => provider.providerType
      ),
      locations: templateData.filetemplateLocations.map(
        (location) => location.locationId
      ),
      filetemplateDocuments: templateData.filetemplateDocumentInfo.map(
        (doc) => doc.type
      ),
      status: templateData.status,
      fileTemplateUsers: templateData.fileTemplateUsers.map((provider) => provider.userId)
    };
  }

  makeFormEmpty() {
    this.templateForm = {
      templateName: "",
      fileTemplateProviders: [],
      filetemplateDocuments: [],
      fileTemplateUsers: [],
      locations: [],
      status: "ACTIVE",
    };
  }

  getLocationList() {
    this.locationService.get().then((result: Location[]) => {
      this.orgLocations = [];
      for (let item of result) {
        let option = {
          value: item.id,
          label: item.locationName,
        };
        this.orgLocations.push(option);
      }
    });
  }

  getProviderTypeList() {
    this.providerTypeList = this.selectOptionService.getProviders();
  }

  getDocumentList() {
    this.attachmentService.GetAttachmentsTypesList().subscribe((res) => {
      this.documentList = this.selectOptionService.getKeyValues(res);
      this.documentList.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    });
  }

  selectAllLocations() {
    this.templateForm.locations = this.orgLocations.map((item) => item.value);
  }

  unselectAllLocations() {
    this.templateForm.locations = null;
  }

  selectAllProviderTypes() {
    this.templateForm.fileTemplateProviders = this.providerTypeList.map(
      (item) => item.value
    );
  }

  unselectAllProviderTypes() {
    this.templateForm.fileTemplateProviders = null;
  }

  check(type: IOption) {
    const index = this.templateForm.filetemplateDocuments.indexOf(type.value);
    if (index >= 0) {
      this.templateForm.filetemplateDocuments.splice(index, 1);
      return;
    }
    this.templateForm.filetemplateDocuments.push(type.value);
  }

  removeProvider(providerId, index) {
    this.providersList.splice(index, 1);
    this.templateForm.fileTemplateUsers.splice(index, 1);
    this.providerDeleted.emit(providerId);
    if(!this.providersList.length) this.modalService.dismissAll();
    else {
      const providers = this.providersList.map((provider) => {
        return { 
        id: provider.value,
        locations: provider.locations.join(","),
        providerTypes: provider.providerType
        }
      })
      this.getFileTemplateList(providers)
    }
  }

  async getFileTemplateList(providers) {
    this.fileTemplateList = await this.orgSettingService
      .GetCommonFileTemplateList(providers).toPromise();
  }

  selectFileTemplate(item) {
    this.selectedFileTemplate = item
  }

  updateFileTemplate() {
    const data = {
      templateName: this.selectedFileTemplate.templateName,
      status: this.selectedFileTemplate.status,
      fileTemplateProviders: this.selectedFileTemplate.fileTemplateProviderInfo.map(
        (provider) => provider.providerType
      ),
      locations: this.selectedFileTemplate.filetemplateLocations.map(
        (location) => location.locationId
      ),
      filetemplateDocuments: this.selectedFileTemplate.filetemplateDocumentInfo.map(
        (doc) => doc.type
      ),
      fileTemplateUsers: this.selectedFileTemplate.fileTemplateUsers.map((provider) => provider.userId)
    };
    this.providersList.forEach((provider)=> {
      if(data.fileTemplateUsers.indexOf(provider.value) < 0)
        data.fileTemplateUsers.push(provider.value)
    })
    this.orgSettingService
        .UpdateFileTemplate(data, this.selectedFileTemplate.id)
        .subscribe((res) => {
          this.templateForm.filetemplateDocuments = [];
          this.modalService.dismissAll();
          this.templateSaved.emit();
          this.toast.success("Template Updated!");
        });
  }

  showDocument(filePath: string, docType?: string, userId?: string) {
    let urlPDF = `${environment.Base_URL}${filePath}`;
    if (docType === "Document" || docType === "Note")
      urlPDF = `${environment.Base_URL}/providers/${userId}/documents/verificationNotes/${filePath}`;
    const dialogRef = this.dialog.open(ShowPDFComponent, {
      width: "90%",
      panelClass: "cred-modal",
      data: {
        urlPDF,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  showFileTemplateDoc(templateId: string, providerId) {
    this.orgSettingService
      .GetFileTemplate(templateId, providerId)
      .subscribe((file) => {
        this.showDocument(file.mergedPdfPath);
      });
  }
}
