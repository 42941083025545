import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

@Component({
  selector: 'app-education-info',
  templateUrl: './education-info.component.html',
  styleUrls: ['./education-info.component.scss']
})
export class EducationInfoComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;

  constructor() {
    super();
  }

  ngOnInit() {
  }
  
}
