import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { QuestionsModel } from 'src/app/constants';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

export interface questionCustom {
  index: number;
  question: string;
  isYes: string;
  note?: string;
}

@Component({
  selector: 'app-disclosure-questions',
  templateUrl: './disclosure-questions.component.html',
  styleUrls: ['./disclosure-questions.component.scss']
})
export class DisclosureQuestionsComponent extends Utilities implements OnChanges, OnInit {

  @Input('applicantDetail') applicantDetail: any = {};
  questionsCustom: questionCustom[] = [];
  public QUESTIONS = QuestionsModel;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.applicantDetail && this.applicantDetail.disclosureQues) {
      let i = 1;
      this.QUESTIONS.forEach(q => {
        if (this.isKeyExist(this.applicantDetail.disclosureQues, q.Answer)) {
          this.questionsCustom.push({
            index: i, question: q.Question,
            isYes: this.applicantDetail.disclosureQues[q.Answer] ? 'Yes' : 'No',
            note: this.applicantDetail.disclosureQues[q.Note]
          });
          i++;
        }
      })
    }
  }

  ngOnInit() {
  }

}
