import { Pipe, PipeTransform } from '@angular/core';
import { DC } from '../constants';

@Pipe({
  name: 'DC'
})
export class DCPipe implements PipeTransform {
  transform(key) {
    return DC[key] || key;
  }
}
