import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):boolean {
    // redirect and return false
      if(!this.auth.getToken()){
        this.router.navigate(['/auth/login']);
        return false;
      }else {
        this.userService.MyUserInfo().subscribe((result) => {
          if(result.organization && result.organization.subscriptionStatus === 'pending') {
            return false
          }
        })
      }
      return true;

  }
}