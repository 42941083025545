import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Affiliation } from '../view-models/affiliation';
import { ListRequest } from '../view-models/list-request';

@Injectable()
export class AffiliationService {

    constructor(
        private http: HttpClient
    ) { }

    GetAllowedOrganizations(): Observable<Affiliation[]> {
        return this.http.get(`${environment.API_URL}allowed-organization`)
            .pipe(
                map((affiliation: Affiliation[]) => {
                    return affiliation;
                })
            )
    }
    ProviderRevokeOrganization(orgId: number): Observable<any> {
        return this.http.post(`${environment.API_URL}provider-allowed-organization/${orgId}/revoked`, {})
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    RevokeOrganization(orgId: number): Observable<any> {
        return this.http.post(`${environment.API_URL}provider-allowed-organization/${orgId}/revoked`, {})
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    ProviderArchiveOrganization(orgId: number): Observable<any> {
        return this.http.post(`${environment.API_URL}provider-allowed-organization/${orgId}/archived`, {})
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    ArchiveOrganization(orgId: number): Observable<any> {
        return this.http.post(`${environment.API_URL}allowed-organization/${orgId}/archived`, {})
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    ArchiveOrganizationByUserId(userId: number): Observable<any> {
        return this.http.get(`${environment.API_URL}allowed-organization/update/${userId}/archived`, {})
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    AddOrganization(id: string, isNewAppointment: boolean, employeeNum:any): Observable<any> {
        return this.http.post(`${environment.API_URL}allowed-organization`, {
            orgId: id,
            isNewAppointment: isNewAppointment,
            employeeNum: employeeNum
        })
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
    SearchOrganization(search: string): Observable<Affiliation[]> {
        let params = new HttpParams();
        params = params.append('search', search);
        return this.http.get(`${environment.API_URL}organization-info/search`, { params })
            .pipe(
                map((affiliations: Affiliation[]) => {
                    return affiliations;
                })
            )
    }
    listAccessRequest(): Promise<ListRequest[]> {
        return this.http.get(`${environment.API_URL}allowed-organization/list-request`)
            .toPromise()
            .then((response: ListRequest[]) => {
                return response;
            })
    }

    requestAction(action, id) {
        return this.http.get(`${environment.API_URL}allowed-organization/${id}/${action}`)
            .pipe(
                map((res: any) => {
                    return res;
                })
            )
    }

    updateEmployeeNumber(providerId, orgId, employeeNumber): Observable<any> {
        return this.http.put(`${environment.API_URL}allowed-organization/add-employeeNum/${providerId}`, {
            orgId: orgId,
            employeeNum: employeeNumber
        }).pipe(
            map((response: any) => {
                return response;
            })
        )
    }
}
