import { Component, Input } from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styles: [`
    :host >>> .alert-custom {
      position: fixed;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      white-space: pre-line;
      color: white;
      background-color: #4895ef;
      border-color: #3f37c9;
    }
    .radio-style{
      margin: 20px 20px 0 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
    }
    .radio-style label{
      margin-left: 5px;
    }
  `]
})
export class NgbdAlertCustom {
  @Input('message') message:string
  @Input('storageName') storageName:string
  dontShowAnyMore:string = localStorage.getItem(`${this.storageName}-alert`);
  readonly  dontShow = 'DONT_SHOW'
  onClose(){
    localStorage.setItem(`${this.storageName}-alert`,this.dontShow)
  }
}
