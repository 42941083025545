import { Pipe, PipeTransform } from '@angular/core';
import { Affiliation } from '../view-models/affiliation';

@Pipe({
    name: 'affiliationStatus',
    pure: false
})
export class AffiliationStatusPipe implements PipeTransform {
    transform(items: Affiliation[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => item.status === filter);
    }
}