import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Reference } from '../view-models/reference';
import { ReferenceModel } from '../view-models/reference-model';

@Injectable()
export class ReferenceService {

    constructor(
        private http: HttpClient
    ) { }

    ScheduleReferences(payload: Reference): Observable<any> {
        return this.http.post(`${environment.API_URL}peer-references/send-later`, payload)
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }

    RemindReferences(payload: any): Observable<any> {
        return this.http.post(`${environment.API_URL}peer-references/remind`, payload)
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }
    ArchiveReferencesStatus(status: string, id: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.post(`${environment.API_URL}peer-references/archived`, { status }, { params })
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }
    SendReferencesStatus(status: string, id: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.post(`${environment.API_URL}peer-references/send-now`, { status }, { params })
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }
    SendReferences(payload: Reference): Observable<any> {
        return this.http.post(`${environment.API_URL}peer-references`, payload)
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }
    SendPeerReferences(payload: Reference, token: string): Observable<any> {
        return this.http.post(`${environment.API_URL}peer-references/${token}`, payload)
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }
    GetReferences(type: string): Observable<ReferenceModel[]> {
        return this.http.get(`${environment.API_URL}peer-references/${type}`)
            .pipe(
                map((References: ReferenceModel[]) => {
                    return References;
                })
            )
    }
    GetReferencesByProviderId(providerId: number): Observable<ReferenceModel[]> {
        return this.http.get(`${environment.API_URL}peer-references/organization/${providerId}`)
            .pipe(
                map((References: ReferenceModel[]) => {
                    return References;
                })
            )
    }
    DeleteReferences(id: string): Observable<any> {
        return this.http.delete(`${environment.API_URL}peer-references/${id}`)
            .pipe(
                map((result) => {
                    return result;
                })
            )
    }
}