import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'eddy-payment',
    templateUrl: 'payment.component.html',
    styleUrls: ['payment.component.scss']
})

export class PaymentComponent implements OnInit {
    public subscriptions: any;
    public wasSuccessfull = false;
    public paymentResult = "Failed";
    public organizationSubscription: any;


  userInfo: any;

  constructor(
        private subscriptionService: SubscriptionService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if ( params['status'] === 'success') {
        this.wasSuccessfull = true;
        this.paymentResult = "Successful";
      }
      else if ( params['status'] === 'cancel') {
        this.wasSuccessfull = false;
        this.paymentResult = "Failed"
      }
      this.getPaymentInfo();
    })
    
  }

  getPaymentInfo() {
    this.subscriptionService.CheckPayment()
      .subscribe(
        res => {
          this.wasSuccessfull = true;
          this.paymentResult = "Successful";
        },
        err => {
          this.wasSuccessfull = false;
          this.paymentResult = "Failed"
        }
      )
  }
  goToPanel() {
    if (this.wasSuccessfull) {
      this.router.navigate(['/organization/dashboard']);
    } else {
      this.router.navigate(['/auth/subscription']);
    }
  }
}