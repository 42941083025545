import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE_CODES } from '../constants';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {
  transform(data:{
    read: boolean
    speak: boolean
    write: boolean
    language:string
  }) {
    const languageKeys=data.language
    if (LANGUAGE_CODES[languageKeys]) {
      return LANGUAGE_CODES[languageKeys].substring(3, LANGUAGE_CODES[languageKeys].length) || languageKeys;
    }
    return '';
  }
}
