import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AttachmentFile } from "../view-models/attachment-file";
import { AttachmentItemCount } from "../view-models/attachment-item-count";
import { IOption } from "../view-models/ng-select-option";

@Injectable()
export class AttachmentsService {
  constructor(private http: HttpClient) {}
  GetAttachmentCount(): Observable<AttachmentItemCount[]> {
    return this.http.get(`${environment.API_URL}attachments/total`).pipe(
      map((response: AttachmentItemCount[]) => {
        return response;
      })
    );
  }

  GetAttachments(type: string): Observable<AttachmentFile[]> {
    return this.http.get(`${environment.API_URL}attachments/${type}`).pipe(
      map((files: AttachmentFile[]) => {
        return files;
      })
    );
  }

  UploadAttachments(file: any, docInfo: any, meta ?: { [key: string]: any }): Observable < AttachmentFile > {
    const formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("docInfo", JSON.stringify(docInfo));
    if (meta) {
      Object.keys(meta).map((key) => {
        if (Array.isArray(meta[key])) {
          for (let i = 0; i < meta[key].length; i++) {
            formData.append(`${key}[]`, meta[key][i]);
          }
        } else {
          formData.append(key, meta[key]);
        }
      });
    }
    return this.http
      .post(`${environment.API_URL}attachments/${docInfo}`, formData)
      .pipe(
        map((res: AttachmentFile) => {
          return res;
        })
      );
  }

  BulkUploadAttachments(file: any, docInfo: any, meta ?: { [key: string]: any }): Observable < AttachmentFile > {
    const formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("docInfo", JSON.stringify(docInfo));
    if (meta) {
      Object.keys(meta).map((key) => {
        if (Array.isArray(meta[key])) {
          for (let i = 0; i < meta[key].length; i++) {
            formData.append(`${key}[]`, meta[key][i]);
          }
        } else {
          formData.append(key, meta[key]);
        }
      });
    }
    return this.http
      .post(`${environment.API_URL}attachments/bulk/${docInfo}`, formData)
      .pipe(
        map((res: AttachmentFile) => {
          return res;
        })
      );
  }

  DeleteAttachment(file: AttachmentFile) {
    return this.http
      .delete(`${environment.API_URL}attachments/${file.fileId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  
  CFAttachments(file: any, docInfo: string, api: string, userId: string = null): Observable<AttachmentFile> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    if (userId) formData.append('userId', userId);
    return this.http.post(`${environment.API_URL}${api}/${docInfo}`, formData)
      .pipe(
        map((res: AttachmentFile) => {
          return res;
        })
      );
  }

  getAllAttachments(type, id) {
    let request = this.http.get(`${environment.API_URL}attachments/${type}`);
    if (id) {
      let params = new HttpParams();
      params = params.append("applicantId", id);
      request = this.http.get(`${environment.API_URL}attachments/all`, {
        params,
      });
    }
    return request.pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  SaveSignature(payload: any): Observable<any> {
    return this.http
      .post(`${environment.API_URL}attachments/caqh-attestation`, payload)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  GetAttachmentsTypesList(): Observable<{ [key: string]: string }> {
    return this.http.get(`${environment.API_URL}attachments/type-lists`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  GetAttachmentsCategorizedTypesList(): Observable<{
    [key: string]: { lable: string; subCategory: Array<IOption> };
  }> {
    return this.http
      .get(`${environment.API_URL}attachments/type-lists-categorized`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getProviderRequiredDocs():Observable<Array<{folderName:string;folderGroup:string}>>{
    return  this.http.get<Array<{folderName:string;folderGroup:string}>>(`${environment.API_URL}provider-document-setting/provider-requirments`)
  }
}
