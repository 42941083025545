import { Pipe, PipeTransform } from '@angular/core';
import { ALL_SPECIALTIES } from '../constants';

@Pipe({
  name: 'AllSpecialties'
})
export class ALLSpecialtiesPipe implements PipeTransform {
  transform(key) {
    return ALL_SPECIALTIES[key] || key;
  }
}
