import { Pipe, PipeTransform } from '@angular/core';
import { ALLIED_PROVIDER, PRIVILEGETYPE } from '../constants';

@Pipe({
  name: 'currentPrivilege'
})
export class CurrentPrivilegePipe implements PipeTransform {
  transform(key) {
    return PRIVILEGETYPE[key] || key;
  }
}