import { Pipe, PipeTransform } from "@angular/core";
import { PROVIDER_TYPE } from "../constants";

@Pipe({
  name: "phoneConvert"
})
export class PhoneConvertPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    // value can be: '1 1234567890' or '1 123-456-7890' or '123 12345678' or '123 123-4578'
    // value should has one space. value could has dash(-).
    value = typeof value === "string" ?
      value : value && value.value ? 
        value.value : "";
    if (!value) return "";
    let temp = value.split("-").join("");
        temp = temp.split(" ");

    // if without space and country code, without (1 12...), for example if value='123-456-7890', then add 1 as code.
    if( temp[0].length>3 ){
      temp[1] = temp[0];
      temp[0] = '1';
    }

    if (temp) {
      if (temp[1].length == 10) {
        return `(${temp[1][0]}${temp[1][1]}${temp[1][2]}) ${temp[1][3]}${temp[1][4]}${temp[1][5]}-${temp[1][6]}${temp[1][7]}${temp[1][8]}${temp[1][9]}`;
      }else if (temp[1].length == 8) {
        temp[1] = temp[1].replace('-', '')
        return `(${temp[0][0]}${temp[0][1]}${temp[0][2]}) ${temp[1][0]}${temp[1][1]}${temp[1][2]}-${temp[1][3]}${temp[1][4]}${temp[1][5]}${temp[1][6]}`;
      }else if (temp[1] == 'undefined') {
        return value;
      }else{
        return value;
      }
    } else return value;
  }
}
