import { Pipe, PipeTransform } from '@angular/core';
import {ALLIED_PROVIDER, ALLIED_PROVIDER_TYPE} from '../constants';

@Pipe({
  name: 'alliedHealth'
})
export class AlliedProviderPipe implements PipeTransform {
  transform(key) {
    return ALLIED_PROVIDER_TYPE[key] || key;
  }
}
