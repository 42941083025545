import { Injectable } from '@angular/core';
import { MENU_ITEMS } from '../pages/pages-menu';
import { NbMenuItem } from '@nebular/theme/components/menu/menu.service';
import * as MainState from '../pages/state/main.reducer';
import { User } from '../view-models/user';
import { State } from '../state/app.state';
import { Store, select } from '@ngrx/store';
@Injectable()
export class AuthorizationService {
    public authorizedMenu: NbMenuItem[];

    constructor(
        private store: Store<State>
    ) {}
    FilterMenuByAuth(userInfo: User): NbMenuItem[] {
        let AuthorizedItems = [];
        let role = userInfo.role;
        if(role === 'providerAgent'){
            role = 'provider'
        }
        for(let item of MENU_ITEMS){
            if(item.link && userInfo){
                if(item.link.indexOf(`${role}/`)>0) {
                    if(item.children && userInfo) {
                        let children = [];
                        for(let submenu of item.children) {
                            if(submenu.link.indexOf(`${role}/`)>0) {
                                children.push(submenu);                
                            } 
                        }
                        item.children = children;
                        AuthorizedItems.push(item); 
                    }else {
                        AuthorizedItems.push(item);     
                    }

                }
                
            }
        }
        this.authorizedMenu = AuthorizedItems;
        return AuthorizedItems;
    }
}
