import { ILocationNames } from './../view-models/bulk-lable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '../view-models/location';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILocationNote } from '../view-models/location-note';
import { IProviderLocationNote } from '../view-models/provider-location-note';

@Injectable()
export class LocationService {

  constructor(
    public http: HttpClient
  ) { }

  post(location): Promise<any> {
    return this.http.post(`${environment.API_URL}location`, location).toPromise()
      .then((response: any) => {
        return response;
      });
  }

  get(): Promise<Location[]> {
    return this.http.get(`${environment.API_URL}location`)
      .toPromise()
      .then((response: Location[]) => {
        return response;
      })
  }

  UpdateLocation(data: Location) {
    return this.http.put(`${environment.API_URL}location/${data.id}`, data).toPromise()
      .then((response: Location) => {
        return response;
      });
  }

  DeleteLocation(locationID: string) {
    return this.http.delete(`${environment.API_URL}location/${locationID}`).toPromise()
      .then((response) => {
        return response;
      });
  }

  ProviderLocations(info: any): Observable<any> {
    return this.http.post(`${environment.API_URL}provider-location`, info)
      .pipe(
        map((result: any) => {
          return result
        }) 
      )
  }
  NameOfLocationAdmin(orgId, locationId): Observable<ILocationNames[]> {
    return this.http.get<ILocationNames[]>(`${environment.API_URL}location-admin/locationUsers/${orgId}/${locationId}`)
      .pipe(
        map((result) => {
          return result
        })
      )
  }

  /** Provider Location Notes APIs */
  getProviderLocationNotes(providerLocationId) {
    return this.http.get(`${environment.API_URL}provider-location-notes/${providerLocationId}`).pipe(
        map((res: IProviderLocationNote[]) => {
            return res;
        })
    );
  }

  getLocationNotes(locationId) {
    return this.http.get(`${environment.API_URL}location-notes/${locationId}`).pipe(
        map((res: ILocationNote[]) => {
            return res;
        })
    );
  }

  createLocationNote(data){
      return this.http
          .post(`${environment.API_URL}location-notes`, data)
          .pipe(map((res: any) => {
                  return res;
              }));
  }

  deleteLocationNote(noteId){
      return this.http
          .delete(`${environment.API_URL}location-notes/${noteId}`)
          .pipe(map((res: any) => {
                  return res;
              }));
  }

}