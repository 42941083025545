import { Pipe, PipeTransform } from '@angular/core';
import { DRUG_SCHEDULES_TYPE } from '../constants';

@Pipe({
  name: 'DrugSchedule'
})
export class DrugSchedulesPipe implements PipeTransform {
  transform(key: string[]) {
    if(!key || !key.length) return null;
    return key.map((drg)=> DRUG_SCHEDULES_TYPE[drg]).join(' ')
  }
}
