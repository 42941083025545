import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let {
      addressCityPrimPract: city,
      addressStatePrimPract: state,
      addressStreet2PrimPract: st2,
      addressStreetPrimPract: st1,
      addressZipPrimPract: zip
    } = value
    if (st1 || st2 || city || state || zip) {
      return `${st1 ? st1 + ' , ' : ''}${st2 ? st2 + ' , ' : ''}${city ? city + ' , ' : ''}${state ? state + ' , ' : ''}${zip || ''}`
    }
    return null;
  }

}
