import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'practiceType'
})
export class PracticeTypePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value==='Non-US/Single Speciality Group'){
     return  value.split('/')[1]
    }
    return value
  }

}
