import { Component, Input } from '@angular/core';

@Component({
    selector: 'provider-references-dialog',
    templateUrl: 'provider-references-dialog.component.html',
    styleUrls: ['provider-references-dialog.component.scss']
})

export class ProviderReferencesDialogComponent {

    @Input('data') data: any;
}