import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { OrganizationProviderService } from 'src/app/services/organization-provider.service';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

const expiryDateModal = "Do you want to update expiration date of state licence?";

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;
  public historyDate = {};

  constructor(
    private modalService: NgbModal,
    private providerService: OrganizationProviderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit() {
  }

  UpdateExpDate(event, index, licenseName, expKeyName, historyKeyName, item?) {
    let modal = this.modalService.open(ConfirmModalComponent);
		modal.componentInstance.text = expiryDateModal;
    modal.componentInstance.button = "Update";
		modal.result.then(result => {
			if (result) {
        let formattedDate = moment(`${event.month} ${event.day} ${event.year}`).format('MM/DD/YYYY');

        if(index !== null) {
          this.document.getElementById(`${licenseName}${index}`).innerHTML = formattedDate;
        } else {
          this.document.getElementById(`${licenseName}`).innerHTML = formattedDate;
        }
        
        this.setHistoryDates(index, formattedDate, licenseName, expKeyName, historyKeyName, item)
			}
		});
  }

  setHistoryDates(index, formattedDate, licenseName, expKeyName, historyKeyName, item) {
    let historyDates = [];
    if(item !== null) {
      if(typeof item === 'string') {
        historyDates.push(item);
      } else {
        historyDates.push(...item);
      }

      historyDates.push(formattedDate);
    } else {
      historyDates.push(formattedDate);
    }

    this.setDataOfLicenseInfo(formattedDate, index, licenseName, expKeyName, historyKeyName, historyDates);
  }

  setDataOfLicenseInfo(newExpDate, index, licenseName, expKeyName, historyKeyName, historyExpDates?) {
    let formattedDates = [];
    newExpDate = moment(newExpDate).format('YYYY-MM-DD');

    for(let date of historyExpDates) {
      if(date !== 'Invalid date') {
        date = moment(date).format('YYYY-MM-DD');
        formattedDates.push(date);
      }
    }

    if(index === null && expKeyName === null) {
      this.applicantDetail.certification[`${licenseName}`] = newExpDate;
      if(this.applicantDetail.certification.historyOfExpDates == undefined) {
        this.applicantDetail.certification.historyOfExpDates = {};
      }
      this.applicantDetail.certification.historyOfExpDates[`${historyKeyName}`] = formattedDates;
    } else if(index !== null && expKeyName !== null) {
      this.applicantDetail.certification[`${licenseName}`][index][`${expKeyName}`] = newExpDate; 
      this.applicantDetail.certification[`${licenseName}`][index][`${historyKeyName}`] = formattedDates;
    }

    this.providerService.updateExpireStLicense(this.applicantDetail.certification, this.applicantDetail.userInfo.id).subscribe(res => res, err => err);
  }

}
