import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'eddy-show-file',
    templateUrl: 'show-file.component.html',
    styleUrls: ['show-file.component.scss'],
})

export class ShowFileComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ShowFileComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() { }
}