import { Component } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [NgbDatepickerConfig]
})
export class AppComponent {
  title = 'Eddy';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  /**
   *
   */
  domainConfig = this.authServices.getDomainConfiguration();
  constructor(datepickerConfig: NgbDatepickerConfig, private titleService: Title,
    private authServices: AuthService) {
    // super();
    datepickerConfig.minDate = { year: 1900, month: 1, day: 1 };
    datepickerConfig.maxDate = { year: 2099, month: 12, day: 31 };
    /** Set FavIcon */
    this.favIcon.href = this.domainConfig.favicon;
    /** Set Title */
    this.titleService.setTitle(this.domainConfig.title);
    /** Set Main Background Color - Only Auth Routes */
    document.documentElement.style.setProperty('--main-bg-color', this.domainConfig.mainColor);
    /** Set second Color  - Only Auth Routes */
    document.documentElement.style.setProperty('--second-color', this.domainConfig.secondColor);
  }
}
