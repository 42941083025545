import {Pipe, PipeTransform} from '@angular/core';
import {STATUS_CODE_LICENSE} from '../constants';

@Pipe({
  name: 'statusCodeLicense'
})
export class StatusCodeLicensePipe implements PipeTransform {
  transform(key) {
    return STATUS_CODE_LICENSE[key] || key;
  }
}
