import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonFormService } from 'src/app/services/common-form.service';

@Component({
    selector: 'eddy-taxonomy-search-box',
    templateUrl: 'taxonomy-search-box.component.html',
    styleUrls: ['taxonomy-search-box.component.scss'],
})

export class TaxonomySearchBoxComponent {
    code: string;
    @Output('onSearch') onSearched = new EventEmitter<any>();
    constructor(
        private service: CommonFormService,
    ) { }

    search() {
        if (this.code) {
            this.service.getTaxonomyInfo(this.code).subscribe((result) => {
                if (this.onSearched) this.onSearched.emit(result ? result : null);
            }, (error) => {
                if (this.onSearched) this.onSearched.emit(null);
            })
        }

    }
}