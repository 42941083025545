import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from 'src/app/pages/organization/components/provider/components/provider-details/components/utilities';

@Component({
  selector: 'app-primary-practice',
  templateUrl: './primary-practice.component.html',
  styleUrls: ['./primary-practice.component.scss']
})
export class PrimaryPracticeComponent extends Utilities implements OnInit {
  @Input() applicantDetail: any;
  time;
  constructor() {
    super();
  }

  ngOnInit() {}
  reformatPhoneNumber(phone){
    if (phone)  return phone.split('-').join('')
    return null
  }
}
