import { Pipe, PipeTransform } from '@angular/core';
import { MD_DP_DC } from '../constants';

@Pipe({
  name: 'MDDPDC'
})
export class MDDPDCPipe implements PipeTransform {
  transform(key) {
    return MD_DP_DC[key] || key;
  }
}
