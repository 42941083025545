import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as _ from 'lodash';
import { OrganizationProviderService } from 'src/app/services/organization-provider.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  breadcrumbs: any;
  textValue: string;
  modal;
  valid: boolean = false;
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private providerService: OrganizationProviderService,
  ) {
    this.router.events
      .subscribe(event => {  // note, we don't use event
      if( event instanceof NavigationEnd) {
        this.breadcrumbs = [];
        let currentRoute = this.route.root,
          url = '';
        do {
          let childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(route => {
            if (route.outlet === 'primary') {
              let routeSnapshot = route.snapshot;
              let path = routeSnapshot.url.map(segment => segment.path);
              if(path.length>0) {
                url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              }
              if (route.snapshot.data.breadcrumb != undefined) {
                let status = true;
                if (route.snapshot.data.status != undefined) {
                  status = route.snapshot.data.status;
                }

                if(route.snapshot.data.breadcrumb === "Provider Details"){
                  this.providerService._providerDetails.subscribe(res => {
                    if(res) {
                      let fullName = `${res.userInfo.firstName} ${res.userInfo.lastName}'s Details`;
                      this.breadcrumbs[2] = {
                        label: fullName,
                        status: status,
                        url: url
                      };
                    }
                  })
                } else {
                  this.breadcrumbs.push({
                    label: route.snapshot.data.breadcrumb,
                    status: status,
                    url: url
                  });
                }
              }
              currentRoute = route;
            }
          })
        } while (currentRoute);
      }
       
      })
  }

  filter(breadCrumbs) {
    return _.uniqBy(breadCrumbs, 'label')
  }
  Close() {
    this.modal.close();
  }
  Navigate(url: string) {
    this.router.navigate([`${url}`]);
    
  }
}
