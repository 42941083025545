import {AttachmentFile} from './../view-models/attachment-file';
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "src/environments/environment";
import {CommonFormSetting} from "../view-models/commonFormSetting";
import {IFileTemplate, IFileTemplateList} from "../view-models/file-template";
import {IAlertConsts} from '../view-models/email-event';
import {IEmailEvents, INewEmailEvent} from '../view-models/email-event';
import {IOrganizationProviderSetting} from "../view-models/organization";

@Injectable()
export class OrganizationSettingService {
  constructor(public http: HttpClient) {
  }

  /*get common-form Info */
  get(): Promise<CommonFormSetting> {
    return this.http
      .get(`${environment.API_URL}organization-info/provider-info`)
      .toPromise()
      .then((res: CommonFormSetting) => {
        return res;
      });
  }

  /* post common-form Info  */
  post(commonInfo: any): Promise<CommonFormSetting> {
    return this.http
      .post(`${environment.API_URL}organization-info/provider-info`, commonInfo)
      .toPromise()
      .then((res: CommonFormSetting) => {
        return res;
      });
  }

  /* post customized verification settings*/
  SetOrganizationVerification(detail): Promise<any> {
    return this.http
      .post(`${environment.API_URL}organization-verification/`, detail)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  /* post customized verification settings*/
  GetOrganizationVerification(orgId: number, formName: string) {
    return this.http
      .get(
        `${environment.API_URL}organization-verification/${orgId}/${formName}`
      )
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  GetEmailAlertConst(): Observable<IAlertConsts> {
    return this.http
      .get<IAlertConsts>(
        `${environment.API_URL}org-custom-alert/get-alert-const`
      )
      .pipe(map((res) => res));
  }
  SaveEmailAlert(data: INewEmailEvent): Observable<{ message: string }> {
    return this.http
      .post<{ message: string }>(`${environment.API_URL}org-custom-alert`, data)
      .pipe(map((res) => res));
  }
  GetEmailAlert(): Observable<Array<IEmailEvents>> {
    return this.http
      .get<Array<IEmailEvents>>(`${environment.API_URL}org-custom-alert`)
      .pipe(map((res) => res));
  }
  UpdateAlert(
    alertId: string,
    data: INewEmailEvent
  ): Observable<{ message: string }> {
    return this.http
      .put<{ message: string }>(
        `${environment.API_URL}org-custom-alert/${alertId}`,
        data
      )
      .pipe(map((res) => res));
  }

  SaveEmailAttachment(file: File): Observable<AttachmentFile> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post<AttachmentFile>(
        `${environment.API_URL}org-custom-alert/attachment`,
        formData
      )
      .pipe(map((res) => res));
  }
  deleteEmailEvent(id): Observable<{ message: string }> {
    return this.http
      .delete<{ message: string }>(
        `${environment.API_URL}org-custom-alert/${id}`
      )
      .pipe(map((res) => res));
  }

  GetFileTemplateDocuments(): Observable<Array<string>> {
    return this.http
      .get<Array<string>>(`${environment.API_URL}file-template/document-list`)
      .pipe(map((res) => res));
  }

  SaveFileTemplate(data: IFileTemplate): Observable<{ message: string }> {
    return this.http
      .post<{ message: string }>(`${environment.API_URL}file-template/`, data)
      .pipe(map((res) => res));
  }

  GetFileTemplateList(
    locations?: string,
    providerTypes?: string,
    status: string = "",
    providers?: string,
    type: string = ""
  ): Observable<IFileTemplateList> {
    let url = `${environment.API_URL}file-template/`;
    if (locations || providerTypes || status)
      url = `${environment.API_URL}file-template/?locations=${locations}&providerTypes=${providerTypes}&status=${status}&providers=${providers}&type=${type}`;
    return this.http.get<IFileTemplateList>(url).pipe(map((res) => res));
  }

  UpdateFileTemplate(data: IFileTemplate, templateId): Observable<any> {
    return this.http.put(
      `${environment.API_URL}file-template/${templateId}`,
      data
    );
  }

  RemoveFileTemplate(templateId): Observable<any> {
    return this.http.delete(
      `${environment.API_URL}file-template/${templateId}`
    );
  }

  GetFileTemplate(
    templateId: string,
    providerId: string
  ): Observable<{ mergedPdfPath: string }> {
    return this.http.get<{ mergedPdfPath: string }>(
      `${environment.API_URL}file-template/${templateId}/provider-document/${providerId}`
    );
  }

  GetCommonFileTemplateList(providers: []): Observable<IFileTemplateList> {
    return this.http.post<IFileTemplateList>(`${environment.API_URL}file-template/common-templates`,
    { providers }).pipe(map((res) => res));
  }

  savePorviderDocSetting(body:{
    providerType: string
    providerGroup: string
    folders: {[key:string]:string[]}
  }):Observable<any>{
   return  this.http.post(`${environment.API_URL}provider-document-setting`,body)
  }
  getProviderDocSetting(providerType:string, providerGroup:string, orgId?:string):Observable<Array<{folderName:string;folderGroup:string}>>{
    return  this.http.get<Array<{folderName:string;folderGroup:string}>>(`${environment.API_URL}provider-document-setting/?providerType=${providerType}&providerGroup=${providerGroup}&orgId=${orgId}`)
  }

  saveOrganizationDocSetting(data:FormData):Observable<{message:string}>{
    return this.http.post<{message:string}>(`${environment.API_URL}organization-document-setting`,data)
  }
  getOrganizationDocSetting(orgId:string,providerType?:string,providerGroup?:string):Observable<Array<
  {digitalSignature: boolean;
    docType: string;
    fileName: string;
    filePath:string;
    id: string;
    title: string;
    createdAt:string
  }>>{
    const param={orgId}

    if (providerType)
    param['providerType']=providerType

    if (providerGroup)
    param['providerGroup']=providerGroup

    return this.http.get<Array<
    {digitalSignature: boolean;
      docType: string;
      fileName: string;
      filePath:string;
      id: string;
      title: string;
      createdAt:string
    }>>(`${environment.API_URL}organization-document-setting`,{params:param})
  }
  archiveOranizationDocument(id:number,docType:'Archive'|'Active'):Observable<any>{
    return this.http.patch(`${environment.API_URL}organization-document-setting`,{id,docType})
  }
  deleteOranizationDocument(id:number):Observable<any>{
    return this.http.delete(`${environment.API_URL}organization-document-setting/${id}`)
  }
}
