import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ListRequest } from '../view-models/list-request';
import { OrganizationInfo } from '../view-models/organization-info';
import { Location } from '../view-models/location';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewHospital } from '../view-models/new-hospital';
import { NewAffiliation } from '../view-models/new-affiliation';
import { Payer } from '../view-models/payer';
import { IOrganizationInfoNote } from '../view-models/organization-info-notes';

@Injectable()
export class OrganizationInfoService {

  constructor(
    public http: HttpClient
  ) { }
  CreateAffiliation(newAffiliation: NewAffiliation): Observable<any> {
    return this.http.post(`${environment.API_URL}organization-info`, newAffiliation)
      .pipe(
        map((result: any) => {
          return result;
        })
      )
  }
  getOrganizationInfo(): Promise<OrganizationInfo> {
    return this.http
      .get(`${environment.API_URL}organization-info`)
      .toPromise()
      .then((response: OrganizationInfo) => {
        return response;
      });
  }

  uploadOrganizationLogo(formdata: FormData): Promise<any> {
    return this.http.put(`${environment.API_URL}organization-info`, formdata).toPromise()
      .then((res: any) => {
        return res;
      })
  }

  AddOrganizationPayer(payerDetail): Observable<any> {
    return this.http.post(`${environment.API_URL}organization-payer/`, payerDetail)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  AddAffiliation(hospitalinfo: NewHospital) {
    return this.http.post(`${environment.API_URL}organization-info`, hospitalinfo)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  GetOrganizationPayer(): Observable<Payer[]> {
    return this.http.get(`${environment.API_URL}organization-payer/`)
      .pipe(
        map((res: Payer[]) => {
          return res;
        })
      )
  }

  GetCTReport(userId: any): Promise<any> {
    return this.http.get(`${environment.API_URL}organization-info/providers/traking/${userId}`)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

  GetAllData(userId: any): Promise<any> {
    return this.http.get(`${environment.API_URL2}organization/providers/${userId}/psv_notes/info`)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

  getPsvNotesDates(providerId: any): Promise<any> {
    return this.http.get(`${environment.API_URL}allowed-organization/providers/${providerId}/psv-notes/dates`)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

  ArchivePSVNotes(data: any, userId: any): Promise<any> {
    return this.http.post(`${environment.API_URL2}organization/providers/${userId}/psv_notes/archive`, data)
      .toPromise()
      .then((response) => {
        return response;
      })
  }


  private providerArchives = new BehaviorSubject<any>(null);
  public _providerArchives: Observable<any> = this.providerArchives.asObservable();

  set archives(data: any) {
    this.providerArchives.next(data);
  }
  get archives() {
    return this._providerArchives;
  }

  GetPSVArchives(userId: any): Promise<any> {
    return this.http.get(`${environment.API_URL2}organization/providers/${+userId}/psv_notes/archives`)
      .toPromise()
      .then((response) => {
        this.archives = response;
        return response;
      })
  }

  // {{url}}api/v2/organization/providers/4107/notes_archives/freeze api/v2/organization/providers/4107/notes_archives
  PSVTrackingFreeze(userId: any): Promise<any> {
    return this.http.post(`${environment.API_URL2}organization/providers/${+userId}/psv_notes/archives/freeze`, null)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

  PSVTrackingUnfreeze(userId: any, fileType:any): Promise<any> {
    return this.http.post(`${environment.API_URL2}organization/providers/${+userId}/psv_notes/archives/unfreeze`, {'fileType': fileType})
      .toPromise()
      .then((response) => {
        return response;
      })
  }

  RenewvalFromPSVArchive(userId: any, archiveId: any): Promise<any> {
    return this.http.post(`${environment.API_URL2}organization/providers/${+userId}/psv_notes/archives/${archiveId}/renewal`, null)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

  updatePSVNotesDates(data: any): Promise<any> {
    return this.http.post(`${environment.API_URL}allowed-organization/psvNotesDate`, data)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

   /** Organization Info Notes APIs */
  getOrganizationInfoNotes(orgId) {
    return this.http.get(`${environment.API_URL}organization-info-notes/${orgId}`).pipe(
        map((res: IOrganizationInfoNote[]) => {
            return res;
        })
    );
  }

  createOrganizationInfoNote(data){
      return this.http
          .post(`${environment.API_URL}organization-info-notes`, data)
          .pipe(map((res: any) => {
                  return res;
              }));
  }

  deleteOrganizationInfoNote(noteId){
      return this.http
          .delete(`${environment.API_URL}organization-info-notes/${noteId}`)
          .pipe(map((res: any) => {
                  return res;
              }));
  }

  GetProviderVerificationDocs(userId: any): Promise<any> {
    return this.http.get(`${environment.API_URL}provider-notes/verification-docs/${+userId}`)
      .toPromise()
      .then((response) => {
        return response;
      })
  }

}
