import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  Form,
  ValidationErrors
} from "@angular/forms";
import { SelectOptionService } from "src/app/services/select-option.service";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { State } from "src/app/state/app.state";
import { PersonalInformation } from "src/app/view-models/personal-information";
import { CommonFormService } from "src/app/services/common-form.service";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "eddy-provider-PersonalInformation-documents",
  templateUrl: "gov-issued.component.html",
  styleUrls: ["gov-issued.component.scss"]
})
export class PersonalInformationDocumentsComponent implements OnInit {
  personalInformationForm: FormGroup;
  personalInformationInfo: PersonalInformation;
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  arrayList = [];
  dateList = [
    "driverLicenseExpireDate"
  ];
  govIssuedTypeSelected: string;
  @Input()
  get personalInformation() {
    let personalInformation = (this.personalInformationForm as FormGroup).value;
    this.ConvertDate(personalInformation);
    return personalInformation;
  }

  @Output()
  onChangePersonalInformation = new EventEmitter();

  set personalInformation(val) {
    if (val) {
      this.personalInformationInfo = val
      this.PatchValue(val)
    } else {
      this.CreateForm();
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private router: Router,
    private store: Store<State>,
    private service: CommonFormService
  ) { }

  ngOnInit() {
    this.CreateForm();
  }

  CreateForm() {
    this.personalInformationForm = this.builder.group({
      driverLicenseExpireDate: [null, Validators.required],
      driverLicenseNo: [''],
      govIssuedType: ['', Validators.required],
      stateDriverLicense: [null],
    });

    if (this.personalInformationInfo) {
      this.PatchValue(this.personalInformationInfo);
    }
  }

  requiredIfValidator(predicate) {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  FormValueChange() {

  }

  PatchValue(personalInformation: PersonalInformation) {
    if (personalInformation && this.personalInformationForm) {
      this.personalInformationInfo = personalInformation;
      for (let item of Object.keys(personalInformation)) {
        if (this.personalInformationForm.get(item)) {
          if (this.arrayList.indexOf(item) < 0) {
            if (this.dateList.indexOf(item) >= 0) {
              this.personalInformationForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(personalInformation[item])
                );
            } else {
              this.personalInformationForm.get(item).patchValue(personalInformation[item]);
            }
          }
        }
        if (item === "govIssuedType") {
          this.govIssuedTypeSelected = personalInformation[item];
        }
      }


    }
  }


  // Other Functions
  ConvertDate(info: PersonalInformation) {
    for (let item of Object.keys(info)) {
      if (this.arrayList.indexOf(item) >= 0) {
        // Check Date Time in Array
        for (let arrayItem of info[item]) {
          for (let key of Object.keys(arrayItem)) {
            if (this.dateList.indexOf(key) >= 0) {
              arrayItem[key] = this.service.ConvertDateToString(arrayItem[key]);
            }
          }
        }
      } else {
        if (this.dateList.indexOf(item) >= 0) {
          info[item] = this.service.ConvertDateToString(info[item]);
        }
      }
    }
  }

  onDateChange(e) {
    // this.service.onDateChange(e)
    this.onChange(null);
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    this.personalInformationForm.get('govIssuedType').patchValue(this.govIssuedTypeSelected)
    let personalInformation = this.personalInformationForm.value;
    this.ConvertDate(personalInformation);

    const isValid = this.personalInformationForm.valid;
    if(!isValid) this.service.ValidateAllFormFields(this.personalInformationForm);
    this.onChangePersonalInformation.emit({ ...personalInformation, isValid });
  }

}
