import { Pipe, PipeTransform } from '@angular/core';
import { DPM } from '../constants';

@Pipe({
  name: 'DPM'
})
export class DPMPipe implements PipeTransform {
  transform(key) {
    return DPM[key] || key;
  }
}
