import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ListRequest } from '../view-models/list-request';
import { OrganizationInfo } from '../view-models/organization-info';
import { Location } from '../view-models/location';

@Injectable()
export class OrganizationProfileService {

  constructor(
    public http: HttpClient
  ) { }

  put(profileInfo: any) {
    const formData: FormData = new FormData();
    const imageArray = ['profilePic', 'picUrl', 'logoUrl', 'logo'];

    for (const key in profileInfo) {
      if (!imageArray.includes(key)) {
        formData.append(key, profileInfo[key]);
      }
    }

    return this.http.put(`${environment.API_URL}organization-info`, formData).toPromise()
      .then(response => {
        return response;
      });
  }
}