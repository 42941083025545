import { Injectable, HostBinding } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { State } from '../state/app.state';
import * as MainAction from '../pages/state/main.actions'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    public router: Router,
    private spinnerService: SpinnerService,
    private toastrService: NbToastrService,
    private store: Store<State>
  ) { }
  list = [
    { url: 'login/' },
    { url: 'recover-password/' },
  ];
  private index: number = 0;
  @HostBinding('class')
  classes = 'example-items-rows';
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.BlockList(request.url)) {
      request = request.clone({
        setHeaders: localStorage.getItem("providerToken")?{
          Authorization: `Bearer ${this.auth.getToken()}`,
          providerToken: localStorage.getItem("providerToken")
        }: {
          Authorization: `Bearer ${this.auth.getToken()}`,
          // providerToken: localStorage.getItem("providerToken")
        }
      });
      this.store.dispatch(new MainAction.SetLoading(true));
    }
    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.store.dispatch(new MainAction.SetLoading(false));
          // do stuff with response if you want
        }
      },
      (error: any) => {
        // Handle errors here!
        if (error instanceof HttpErrorResponse) {
          this.store.dispatch(new MainAction.SetLoading(false));
          if (error.status === 401) {
            this.auth.collectFailedRequest(request);
            // localStorage.removeItem('auth_token');
            // localStorage.removeItem('providerToken');
            // this.router.navigate(['/auth/login']);
          }else if(error.status === 404) {
            // this.DangerToast(error.error.message);
          }else if(error.status === 0) {
            // this.DangerToast(error.error.message);
          }else {
            this.DangerToast(error.error.message);
          }
        }
      }
    ));
  }
  BlockList(url: string): boolean {
    for (let item of this.list) {
      if (url.indexOf(item.url) > 0) {
        return false;
      }
    }
    return true;
  }
  DangerToast(text: string) {
    this.toastrService.danger('', `${text}`, {
      hasIcon: false,
      destroyByClick: false
    });
  }
}