import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'eddy-confirm-delete-modal',
    templateUrl: 'confirm-delete-modal.component.html',
    styleUrls: ['confirm-delete-modal.component.scss'],
})

export class ConfirmDeleteModalComponent implements OnInit {
    @Input() text = 'test';
    constructor(
        private userService: UserService,
        public modal: NgbActiveModal
    ) {}
    ngOnInit() {}

    Delete(state: boolean) {
        this.modal.close(state);
    }
}