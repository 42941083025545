import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-medicare",
  templateUrl: "medicare.component.html",
  styleUrls: ["medicare.component.scss"]
})
export class ProviderMedicareComponent implements OnInit {
  @Input('userId') userId: string;
  MedicareForm: FormGroup;
  MedicareArray: FormArray;
  MedicareValue: any[];
  attachments: string[];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  /** Input of Component - Medicares */
  @Input()
  get Medicares() {
    let MedicareArray = this.MedicareForm.controls
      .MedicareArray as FormArray;
    this.MedicareArray = MedicareArray;
    return this.MedicareArray.value;
  }

  @Output()
  MedicaresChange = new EventEmitter();

  set Medicares(val) {
    if (val) {
      if (val.length > 0) {
        this.MedicareValue = val;
        this.MedicareArray = this.Medicare_Value(val);
        this.MedicareForm = this.builder.group({
          MedicareArray: this.MedicareArray
        });
        this.MedicaresChange.emit(this.MedicareArray);
      } else {
        // this.AddMedicare();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
    // add N/A to biggining of states:
    this.stateOptions.unshift({ label: 'N/A', value: 'N/A' });
  }

  /**
   * Create Form
   *  If Medicares does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing Medicares
   */
  CreateForm() {
    this.MedicareArray = this.MedicareArray ? this.MedicareArray : this.builder.array([]);
    this.MedicareForm = this.builder.group({
      MedicareArray: this.MedicareArray
    });

    if (this.MedicareArray.length == 0)
      this.AddMedicare();
    // else this.MedicareValue(this.MedicareValue)
  }

  // Coverage Plan Form Array
  get Medicare() {
    return <FormArray>this.MedicareArray;
  }

  /**
   * Create an empty form for a new Medicare
   * @returns FormGroup
   */
  CreateMedicare(info: any = null): FormGroup {
    let form = this.builder.group({
      stateIndMedicare: [info ? info.stateIndMedicare : null, Validators.required],
      numIndMedicare: [info ? info.numIndMedicare : "", Validators.required],
      issuanceMedicare: [info ? info.issuanceMedicare : "", Validators.required],
      expireIndMedicare: [info ? info.expireIndMedicare : null, Validators.required],
      attachments: [info ? info.attachments : []],
      id: [info ? info.id : null],
      certificationId: [info ? info.certificationId : null],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new Medicare to form array
   */
  AddMedicare(): void {
    let MedicareArray = this.MedicareForm.controls
      .MedicareArray as FormArray;
    MedicareArray.push(this.CreateMedicare());

    this.MedicareArray = MedicareArray;
    this.MedicaresChange.emit(this.MedicareArray);
  }

  /**
   * Remove a Medicare from form array
   * @param index Index of the Medicare to remove
   */
  RemoveMedicare(index: number): void {
    let MedicareArray = this.MedicareForm.get("MedicareArray") as FormArray;

    if (MedicareArray.length > 1) {
      MedicareArray.removeAt(index);
    } else {
      MedicareArray = this.builder.array([]);
    }
    this.MedicareArray = MedicareArray;
    this.MedicaresChange.emit(this.MedicareArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let MedicareArray = this.MedicareForm.controls
      .MedicareArray as FormArray;
    this.MedicareArray = MedicareArray;
    this.MedicaresChange.emit(this.MedicareArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert Medicare array to form array
   * @param informations JSON array of Medicares
   * @returns Array of FormGroup of Medicares
   */
  Medicare_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          stateIndMedicare: [info.stateIndMedicare, Validators.required],
          numIndMedicare: [info.numIndMedicare, Validators.required],
          issuanceMedicare: [
            this.service.ConvertStringToDate(info.issuanceMedicare),
            Validators.required
          ],
          expireIndMedicare: [this.service.ConvertStringToDate(info.expireIndMedicare), Validators.required],
          attachments: [info.attachments],
          id: [info.id],
          certificationId: [info.certificationId],
        })
      );
    });
    return formArray;
  }

  getMedicareAttachments(index: number) {
    return this.MedicareArray.at(index).get("attachments").value ? this.MedicareArray.at(index).get("attachments").value : [];
  }

  onChangeMedicareAttachment(event, index: number) {
    console.log(event);
    this.MedicareArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
