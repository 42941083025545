import { Pipe, PipeTransform } from '@angular/core';
import { DDS_DMD } from '../constants';

@Pipe({
  name: 'DDSDMD'
})
export class DDSDMDPipe implements PipeTransform {
  transform(key) {
    return DDS_DMD[key] || key;
  }
}
