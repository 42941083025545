import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ShowMessageComponent } from '../show-message/show-message.component';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import * as ProviderState from '../../../pages/provider/state/provider.reducer';
import * as ProviderAction from '../../../pages/provider/state/provider.actions';
import { AttachmentItemCount } from 'src/app/view-models/attachment-item-count';
import { AttachmentFile } from 'src/app/view-models/attachment-file';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDeleteModalComponent } from 'src/app/shared/components/confirm-delete-modal/confirm-delete-modal.component';
import { ShowPDFComponent } from 'src/app/shared/components/show-pdf/show-pdf.component';
import { ShowFileComponent } from 'src/app/shared/components/show-file/show-file.component';

@Component({
  selector: 'eddy-upload-provider-document',
  templateUrl: 'upload-provider-document.component.html',
  styleUrls: ['upload-provider-document.component.scss'],
})

export class UploadProviderDocumentComponent implements OnInit {
  /** If you want upload attachment for another user, set user id. */
  @Input() userId: string = null;
  @Input() docInfo: string;
  @Input() text: string;
  @Input() attachments: string[];
  @Input() archived: false;
  @Input() uploadApi: string = 'attachments';
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  count: string = '0';
  files: AttachmentFile[];
  constructor(
    private service: AttachmentsService,
    public sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private store: Store<State>,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    // this.GetCount();
    this.GetAttachments(this.docInfo);
  }

  Dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file

          this.service.CFAttachments(file, this.docInfo, this.uploadApi, this.userId).subscribe((result) => {
            this.changeAttachment(result);
            this.openSnackBar(result);
            if (!this.userId) { // if userId is null, it means user is current provider, then we can get attachments.
              this.store.dispatch(new ProviderAction.LoadAttachments());
              this.GetAttachments(this.docInfo);
              // this.GetCount();
            }
          })

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;

      }
    }
  }

  openSnackBar(info) {
    this.snackBar.openFromComponent(ShowMessageComponent, {
      duration: 1000,
      panelClass: 'success-message',
      data: info,
    });
  }

  // GetCount() {
  //   if (!this.userId) // if userId is null, it means user is current provider, then we can get attachment count.
  //     this.store.pipe(select(ProviderState.GetAttachments)).subscribe((attachments: AttachmentItemCount[]) => {
  //       for (let item of attachments) {
  //         if (item.type === this.docInfo) {
  //           this.count = item.count;
  //         }
  //       }
  //     })
  // }

  GetAttachments(type: string): void {
    this.service.GetAttachments(type).subscribe((files: AttachmentFile[]) => {
      this.files = files.filter(file => this.isOnAttachments(file.fileId));
      this.count = this.files.length.toString();
    })
  }

  ShowAttachment(file: AttachmentFile) {
    if (file.fileName && file.fileName.toLowerCase().indexOf('.pdf') > 0) {
      const dialogRef = this.dialog.open(ShowPDFComponent, {
        width: '70%',
        panelClass: 'enrollment-modal',
        data: {
          urlPDF: file.url,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
      return;
    }
    // this.url = this.sanitizer.bypassSecurityTrustResourceUrl(document.url);
    // this.docName = document.fileName;
    const dialogRef = this.dialog.open(ShowFileComponent, {
      width: '50%',
      data: {
        url: this.sanitizer.bypassSecurityTrustResourceUrl(file.url),
        Name: file.fileName
      }
    });
  }
  DeleteAttachment(file: AttachmentFile) {
    let modal = this.modalService.open(ConfirmDeleteModalComponent);
    modal.componentInstance.text = 'Are you sure you want to delete this document ?';
    modal.result.then((result) => {
      if (result) {
        this.service.DeleteAttachment(file).subscribe((result) => {
          this.changeAttachment(file, false);
          this.store.dispatch(new ProviderAction.LoadAttachments());
          this.GetAttachments(this.docInfo);
          // this.GetCount();
        });
      }
    });
  }

  isOnAttachments(attachment: string) {
    return !this.attachments ?
      true :
      !this.archived ?
        this.attachments.indexOf(attachment) > -1 :
        this.attachments.indexOf(attachment) < 0;
  }

  changeAttachment(file: AttachmentFile, added: boolean = true) {
    if (!this.attachments) this.attachments = [];
    if (added) {
      this.attachments.push(file.fileId);
    } else {
      this.attachments.splice(this.attachments.indexOf(file.fileId), 1);
    }
    this.onChange.emit(this.attachments);
  }
}