import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestSupportService {
  constructor(public http: HttpClient) {
  }

  get() {
    return this.http.get(`${environment.API_URL}request-support`).toPromise()
      .then(res => {
        return res;
      });
  }

  getRequest(ticketId) {
    return this.http.get(`${environment.API_URL}request-support/${ticketId}`).
      pipe(map(res => {
        return res;
      }));
  }

  post(formData) {
    return this.http.post(`${environment.API_URL}request-support`, formData).toPromise()
      .then(res => {
        return res;
      });
  }
}
