import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'eddy-no-item',
    templateUrl: 'no-item.component.html',
    styleUrls: ['no-item.component.scss']
})

export class NoItemComponent implements OnInit {
    @Input() text: string = 'No information is available';
    constructor() { }

    ngOnInit() { }
}