import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { CommonFormService } from "src/app/services/common-form.service";
import { SelectOptionService } from "src/app/services/select-option.service";
import { IOption } from "src/app/view-models/ng-select-option";

@Component({
  selector: "certification-medicaid",
  templateUrl: "medicaid.component.html",
  styleUrls: ["medicaid.component.scss"]
})
export class ProviderMedicaidComponent implements OnInit {
  @Input('userId') userId: string;
  MedicaidForm: FormGroup;
  MedicaidArray: FormArray;
  MedicaidValue: any[];
  attachments: string[];
  stateOptions: Array<IOption> = this.selectOptionService.getStatesName();
  /** Input of Component - Medicaids */
  @Input()
  get Medicaids() {
    let MedicaidArray = this.MedicaidForm.controls
      .MedicaidArray as FormArray;
    this.MedicaidArray = MedicaidArray;
    return this.MedicaidArray.value;
  }

  @Output()
  MedicaidsChange = new EventEmitter();

  set Medicaids(val) {
    if (val) {
      if (val.length > 0) {
        this.MedicaidValue = val;
        this.MedicaidArray = this.Medicaid_Value(val);
        this.MedicaidForm = this.builder.group({
          MedicaidArray: this.MedicaidArray
        });
        this.MedicaidsChange.emit(this.MedicaidArray);
      } else {
        // this.AddMedicaid();
      }
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private service: CommonFormService,
  ) {
    this.CreateForm();
  }

  ngOnInit() {
    // add N/A to biggining of states:
    this.stateOptions.unshift({ label: 'N/A', value: 'N/A' });
  }

  /**
   * Create Form
   *  If Medicaids does not exist, create a new FormArray with one empty form
   *  else create a new FormArray with the existing Medicaids
   */
  CreateForm() {
    this.MedicaidArray = this.MedicaidArray ? this.MedicaidArray : this.builder.array([]);
    this.MedicaidForm = this.builder.group({
      MedicaidArray: this.MedicaidArray
    });

    if (this.MedicaidArray.length == 0)
      this.AddMedicaid();
    // else this.MedicaidValue(this.MedicaidValue)
  }

  // Coverage Plan Form Array
  get Medicaid() {
    return <FormArray>this.MedicaidArray;
  }

  /**
   * Create an empty form for a new Medicaid
   * @returns FormGroup
   */
  CreateMedicaid(info: any = null): FormGroup {
    let form = this.builder.group({
      medicadeState: [info ? info.medicadeState : "", Validators.required],
      medicadeNo: [info ? info.medicadeNo : "", Validators.required],
      issuanceMedicaid: [info ? info.issuanceMedicaid : "", Validators.required],
      expireIndMedicaid: [info ? info.expireIndMedicaid : null, Validators.required],
      attachments: [info ? info.attachments : []],
    });

    // form.valueChanges.subscribe(selectedValue => {
    //   this.onChange(selectedValue)
    // })

    return form;
  }

  /**
   * Add a new Medicaid to form array
   */
  AddMedicaid(): void {
    let MedicaidArray = this.MedicaidForm.controls
      .MedicaidArray as FormArray;
    MedicaidArray.push(this.CreateMedicaid());

    this.MedicaidArray = MedicaidArray;
    this.MedicaidsChange.emit(this.MedicaidArray);
  }

  /**
   * Remove a Medicaid from form array
   * @param index Index of the Medicaid to remove
   */
  RemoveMedicaid(index: number): void {
    let MedicaidArray = this.MedicaidForm.get("MedicaidArray") as FormArray;

    if (MedicaidArray.length > 1) {
      MedicaidArray.removeAt(index);
    } else {
      MedicaidArray = this.builder.array([]);
    }
    this.MedicaidArray = MedicaidArray;
    this.MedicaidsChange.emit(this.MedicaidArray);
  }

  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let MedicaidArray = this.MedicaidForm.controls
      .MedicaidArray as FormArray;
    this.MedicaidArray = MedicaidArray;
    this.MedicaidsChange.emit(this.MedicaidArray);
  }

  onDateChange(e) {
    this.service.onDateChange(e);
    this.onChange(e);
  }

  /**
   * Convert Medicaid array to form array
   * @param informations JSON array of Medicaids
   * @returns Array of FormGroup of Medicaids
   */
  Medicaid_Value(informations: any[]): FormArray {
    const formArray = this.builder.array([]);
    informations.forEach(info => {
      formArray.push(
        this.builder.group({
          medicadeState: [info.medicadeState, Validators.required],
          medicadeNo: [info.medicadeNo, Validators.required],
          issuanceMedicaid: [
            this.service.ConvertStringToDate(info.issuanceMedicaid),
            Validators.required
          ],
          expireIndMedicaid: [this.service.ConvertStringToDate(info.expireIndMedicaid), Validators.required],
          attachments: [info.attachments],
        })
      );
    });
    return formArray;
  }

  getMedicaidAttachments(index: number) {
    return this.MedicaidArray.at(index).get("attachments").value ? this.MedicaidArray.at(index).get("attachments").value : [];
  }

  onChangeMedicaidAttachment(event, index: number) {
    console.log(event);
    this.MedicaidArray.at(index).get("attachments").setValue(event);
    this.onChange(event);
  }

}
