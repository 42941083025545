import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailInfo } from '../view-models/email-info';
import { AffiliatesInfo } from '../view-models/affiliates-info';

@Injectable()
export class organizationCorrespondenceService {
    constructor(
        private http: HttpClient
    ) { }
    UploadAffiliateFile(file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.API_URL}allowedOrg-correspondence`, formData)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    UpdateAffiliateFile(file: any, id: number): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.put(`${environment.API_URL}allowedOrg-correspondence/file/${id}`, formData)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
    GetAllAffiliates(): Observable<AffiliatesInfo> {
        return this.http.get(`${environment.API_URL}allowedOrg-correspondence`)
            .pipe(
                map((res: AffiliatesInfo) => {
                    return res;
                })
            );
    }
    AffiliateEmailInfo(payload: EmailInfo): Observable<any> {
        return this.http.put(`${environment.API_URL}allowedOrg-correspondence/email-info`, payload)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }

}