import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-events-side',
    templateUrl: 'events-sidebar.component.html',
    styleUrls: ['events-sidebar.component.scss']
})

export class EventsSideBarComponent implements OnInit {

    @Output('toggleSide') toggleSideBar = new EventEmitter<any>();

    public _isOpen: boolean;
    @Input() set isOpen(value: boolean) {
        this._isOpen = value;
    }

    get categoryId(): boolean {
        return this._isOpen;
    }

    model;

    constructor(private calendar: NgbCalendar) { 
        this.model = calendar.getToday();
    }

    ngOnInit() { }

    toggleSide() {
        this.toggleSideBar.emit();
    }
}