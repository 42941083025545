import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  Form,
  ValidationErrors
} from "@angular/forms";
import { SelectOptionService } from "src/app/services/select-option.service";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { State } from "src/app/state/app.state";
import { Insurance } from "src/app/view-models/insurance";
import { CommonFormService } from "src/app/services/common-form.service";
import { CarrInfo } from "src/app/view-models/carr-info";
import { GoogleAddress } from "src/app/view-models/google-address";
import { HandleAddressChange } from "src/util";

const ADDRESS_FIELDS = {
  street_number: 'addressStreet',
  route: 'addressStreet2',
  locality: 'addressCity',
  administrative_area_level_1: 'addressState',
  postal_code: 'addressZip'
};
@Component({
  selector: "eddy-provider-Insurance-documents",
  templateUrl: "Insurance.component.html",
  styleUrls: ["Insurance.component.scss"]
})
export class InsuranceDocumentsComponent implements OnInit {
  insuranceForm: FormGroup;
  insuranceInfo: Insurance;
  dateList = ['startingCarr', 'endingCarr', 'retroactiveDate', 'dateOcc', 'dateclaimfilled', 'dateSettl'];
  @Input()
  get insurance() {
    let insurance = (this.insuranceForm as FormGroup).value;
    this.ConvertDate(insurance);
    return insurance;
  }

  @Output()
  onChangeInsurance = new EventEmitter();

  set insurance(val) {
    if (val) {
      this.insuranceInfo = val;
      this.PatchValue(val)
    } else {
      this.CreateForm();
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private router: Router,
    private store: Store<State>,
    private service: CommonFormService
  ) {
  }

  ngOnInit() {
    this.CreateForm();
  }

  CreateForm() {
    this.insuranceForm = this.builder.group({
      carrInfo: this.builder.array([])
    });

    if (this.insuranceInfo) {
      this.PatchValue(this.insuranceInfo);
    }
  }

 
  PatchValue(insurance: Insurance) {
    if (insurance && this.insuranceForm) {
      this.insuranceInfo = insurance;
      for (let item of Object.keys(insurance)) {
        if (this.insuranceForm.get(item)) {
            if (this.dateList.indexOf(item) >= 0) {
              this.insuranceForm
                .get(item)
                .patchValue(
                  this.service.ConvertStringToDate(insurance[item])
                );
            } else {
              this.insuranceForm.get(item).patchValue(insurance[item]);
            }
          // else if (this.arrayList.indexOf(item) >= 0) {
          //   this.FormArrayPatchValue(item, insurance[item]);
          // }
        }
      }
    }
  }

  isMalpracticeValue() {
    (this.insuranceForm.get("carrInfo") as FormArray).controls.forEach((CIFG: FormGroup) => {
      if (CIFG.controls.isMalpractice.value) {
        CIFG.controls.claimStatus.setValidators(Validators.required);
      } else {
        CIFG.controls.claimStatus.clearValidators();
        CIFG.controls.claimStatus.setErrors(null);
      }
      CIFG.controls.claimStatus.updateValueAndValidity();
    })
  }
  requiredIfValidator(predicate) {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }
 
  FormArrayPatchValue(item: string, arg1: any) {
    throw new Error("Method not implemented.");
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let insurance = this.insuranceForm.value;
    this.ConvertDate(insurance)
    const isValid = this.insuranceForm.valid;
    if(!isValid) this.service.ValidateAllFormFields(this.insuranceForm);
    this.onChangeInsurance.emit({ ...insurance, isValid });
  }

  ConvertDate(info: Insurance) {
    for (let arrayItem of info.carrInfo) {
      for (let key of Object.keys(arrayItem)) {
        if (this.dateList.indexOf(key) >= 0) {
          arrayItem[key] = this.service.ConvertDateToString(arrayItem[key]);
        }
      }
    }
  }

  getArchivedInsuranceAttachments() {
    let attachments = [];
    if (this.insuranceInfo && this.insuranceInfo.carrInfo) {
      for (let carrInfo of this.insuranceInfo.carrInfo) {
        if (carrInfo.attachments) {
          attachments = attachments.concat(carrInfo.attachments);
        }
      }
    }
    return attachments;
  }

  isPrimaryChange(event: any, index: number) {
    event && this.setAllPrimariesFalse();
    (this.insuranceForm.get('carrInfo') as FormArray).at(index).get("isPrimary").setValue(event);
    this.onChange(null);
  }

  setAllPrimariesFalse() {
    for (var item in (this.insuranceForm.get('carrInfo') as FormArray).controls) {
      (this.insuranceForm.get('carrInfo') as FormArray).at(parseInt(item)).patchValue({
        isPrimary: false
      });
    }
  }

  InsurancesChanged(event: FormArray) {
    this.insuranceForm.setControl("carrInfo", event);
    this.onChange(null);
  }
}
