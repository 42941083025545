import { Pipe, PipeTransform } from '@angular/core';
import { PROVIDER_TYPE, FACILITY_PERSONAL_TYPE, ALL_SPECIALTIES, ALLIED_PROVIDER_TYPE } from '../constants';
import { individualType } from "../pages/organization/components/provider/provider.interface";



@Pipe( {
  name: 'providerType'
} )
export class ProviderTypePipe implements PipeTransform{
  /**
   * @desc filter provider types from provider object
   * @param {string} provider provider data
   * @param {individualType} individual type of provider type
   * @param {string} specialtyCode specialty code (primaryCode1) to replace provider type if its not exist in provider type list
   * */
  transform( provider: string, individual: individualType="Individual",specialtyCode?:string){
    if ( individual == 'Individual' ) return PROVIDER_TYPE[provider] || ALL_SPECIALTIES[specialtyCode] || ALLIED_PROVIDER_TYPE[provider] || 'N/A';
    if ( individual == 'Facility' ) return FACILITY_PERSONAL_TYPE[provider] || 'N/A';
    return  'N/A'
  }
}
