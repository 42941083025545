import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  Form,
  ValidationErrors,
  FormControl
} from "@angular/forms";
import { SelectOptionService } from "src/app/services/select-option.service";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { State } from "src/app/state/app.state";
import { Specialties } from "src/app/view-models/specialities";
import { CommonFormService } from "src/app/services/common-form.service";
import { CarrInfo } from "src/app/view-models/carr-info";
import { IOption } from "src/app/view-models/ng-select-option";
import { Allied_Health_BOARD, ALLIED_PROVIDER_TYPE, ALL_BOARDS, ALL_SPECIALTIES, Areas_Of_Specialization, DC, DC_BOARD, DDS_DMD, DDS_DMD_BOARD, DPM, DPM_BOARD, MD_DO, MD_DO_BOARD, PROVIDER_TYPE_ABBR, Specialty_Certifications, Therapeutic_Modalities } from "src/app/constants";
import { combineLatest } from "rxjs";
import { SpecialityData } from "src/app/view-models/speciality-data";

@Component({
  selector: "eddy-provider-Specialty-documents",
  templateUrl: "Specialty.component.html",
  styleUrls: ["Specialty.component.scss"]
})
export class SpecialtyDocumentsComponent implements OnInit {
  specialityForm: FormGroup;
  specialityInfo: Specialties;
  specialityData: FormArray;

  requiredList = ['isBoardCertPrimarySpec', 'isNotBoardCertPri',];
  dateList = ['dateBoardCertPrimary', 'primaryExpDate', 'primaryInitialCert', 'primaryReCert'];
  disabledList = ['primaryCode1', 'primaryCode2', 'primaryCode3', 'code1BoardCertPri', 'certifyingBoardAlliedHealth']
  MD_DO_Options: Array<IOption>;
  DC_Options: Array<IOption>;
  DPM_Options: Array<IOption>;
  MD_DO_BOARD_OPTION: Array<IOption>;
  supportingOption = [
    'Additional training required', 'Not qualified due to age', 'Not Applicable to Provider', 'Did not pass the exam', 'Intention to test on date below'
  ];
  MD_DP_DC_Options: Array<IOption> = this.selectOptionService.getMD_DP_DC();
  DDS_DMD_Options: Array<IOption>;
  Allied_Health_Options: Array<IOption> = this.selectOptionService.getAllied_Health_BOARD();
  ALLIED_PROVIDERS_Options: Array<IOption>;
  areasOfSpecialization = Areas_Of_Specialization;
  therapeuticModalities = Therapeutic_Modalities;
  specialtyCertifications = Specialty_Certifications;
  taxonomyList;
  allSpecialities_Options: Array<IOption> = this.selectOptionService.getAllSpecialities();
  DDS_DMD_BOARD_OPTION: Array<IOption>
  DPM_BOARD_OPTION: Array<IOption>
  DC_BOARD_OPTION: Array<IOption>
  ALL_BOARDS_OPTION: Array<IOption>

  @Input()
  get speciality() {
    let speciality = (this.specialityForm as FormGroup).value;
    return speciality;
  }

  @Output()
  onChangeSpecialty = new EventEmitter();

  set speciality(val) {
    if (val) {
      this.specialityInfo = val;
      if (val.specialityData && val.specialityData.length > 0) {
        this.specialityForm = this.builder.group({
          specialityData: this.SpecialityValue(val.specialityData, "")
        });
      }
    } else {
      // this.CreateForm();
    }
  }

  constructor(
    private builder: FormBuilder,
    private selectOptionService: SelectOptionService,
    private router: Router,
    private store: Store<State>,
    private service: CommonFormService
  ) {
    this.MD_DO_Options = this.SortSpecialities(this.selectOptionService.getMD_DO());
    this.DC_Options = this.SortSpecialities(this.selectOptionService.getDC());
    this.DPM_Options = this.SortSpecialities(this.selectOptionService.getDPM());
    this.DDS_DMD_Options = this.SortSpecialities(this.selectOptionService.getDDS_DMD());
    this.ALLIED_PROVIDERS_Options = this.SortSpecialities(this.selectOptionService.getAlliedProviders());
    this.MD_DO_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getMD_DO_BOARD())
    this.DDS_DMD_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDDS_DMD_BOARD())
    this.DPM_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDPM_BOARD())
    this.DC_BOARD_OPTION = this.SortSpecialities(this.selectOptionService.getDC_BOARD())
    this.ALL_BOARDS_OPTION = this.SortSpecialities(this.selectOptionService.getALL_BOARDS())
    this.CreateForm();
  }

  ngOnInit() {
    
  }

  CreateForm() {
    this.specialityForm = this.builder.group({
      specialityData: this.builder.array([])
    })
  }

  SortSpecialities(arrToSort) {
    const targetArr: any = [];
    const tempArr: any = [];

    for (let i = 0; i < arrToSort.length; i++) {
      if (arrToSort[i].label === 'N/A') {
        targetArr.push(arrToSort[i])
      } else {
        tempArr.push(arrToSort[i])
      }
    }

    tempArr.sort((opt1: any, opt2: any) => {
      return (opt1.label.toLowerCase() < opt2.label.toLowerCase()) ?
        -1 :
        (opt1.label.toLowerCase() > opt2.label.toLowerCase()) ?
          1 : 0;
    })
    arrToSort = targetArr.concat(tempArr);
    return arrToSort;
  }


  // GroupMedicade No Form Array
  CreateSpecialityData(): FormGroup {
    let formGroup = this.builder.group({
      ADDADHD: [false],
      Addictions: [false],
      Adolescent: [false],
      Adolescents: [false],
      AdoptionFoster: [false],
      Adults: [false],
      AllAges: [false],
      AngerManagement: [false],
      AnxietyDisorders: [false],
      AutisticDisordersAsperger: [false],
      BariatricAssessments: [false],
      BehaviorManagementParentTraining: [false],
      BehaviorManagementParentTrainingP11: [false],
      BehaviorModificationTherapy: [false],
      BilingualTherapy: [false],
      BingeEatingDisorder: [false],
      BipolarDisorder: [false],
      BodyDsmorphicDisorder: [false],
      BriefSolutionFocused: [false],
      BriefTherapy: [false],
      Bullying: [false],
      CAP: [false],
      CBT: [false],
      ChildTherapy: [false],
      ChildTherapyN5: [false],
      ChildhoodEatingDisorders: [false],
      ChildrenA: [false],
      ChildrenB: [false],
      ChronicPainIssues: [false],
      CoOccurring: [false],
      CognitiveBehavioralTherapy: [false],
      CognitiveProcessingForTrauma: [false],
      ConductDisorders: [false],
      CopingWithMedicalIssues: [false],
      CouplesCounseling: [false],
      CrossCulturalAdaptation: [false],
      DBT: [false],
      DepressiveDisorders: [false],
      DevelopmentalDisabilities: [false],
      DialecticalBehavioralTherapy: [false],
      DissociativeIdentifyDisorders: [false],
      DivorceBlendedFamilyIssues: [false],
      DomesticViolenceIntimatePartnerViolence: [false],
      EAP: [false],
      EatingDisordersAnorexiaBulimia: [false],
      EducationalVocationalIssues: [false],
      ElectiveMutism: [false],
      EndOfLifeIssues: [false],
      EnuresisEncopresis: [false],
      ExcoriationDisorder: [false],
      ExpertWitnessTestimony: [false],
      FaithBasedCounseling: [false],
      FaithBasedCounselingFaith: [false],
      FamilyTherapy: [false],
      FitnessForDutyAssessments: [false],
      FitnessForDutyAssignments: [false],
      GeriatricAgingIssues: [false],
      Geriatrics: [false],
      GeropsychiatryAlzheimers: [false],
      Griefloss: [false],
      GroupTherapy: [false],
      Hoarding: [false],
      HumanTrafficking: [false],
      InfertilityIssues: [false],
      InsightOrientedPsychodynamicTherapy: [false],
      LGBTQA: [false],
      LifeCoaching: [false],
      MAT: [false],
      MHDevelopmentalDisabilityAdult: [false],
      MHDevelopmentalDisabilityChild: [false],
      Marital: [false],
      MedicalBehavioral: [false],
      MedicationManagement: [false],
      MenIssues: [false],
      MilitaryMilitaryFamily: [false],
      NeurocognitiveDisorders: [false],
      OCD: [false],
      ODD: [false],
      OtherAreasOfSpecialization: [false],
      OtherTherapeuticModalities: [false],
      PTSDAcute: [false],
      PTSDCchronic: [false],
      PanicDisorder: [false],
      PerpetratorsOfViolenceAbuse: [false],
      PersonalityDisorders: [false],
      PlayTherapy: [false],
      PostPartumDepression: [false],
      PreschoolReadinessInfantMentalHealth: [false],
      PrimarySubstanceAbuse: [false],
      PsychologicalTesting: [false],
      PsychoticDisorders: [false],
      RedCrossDisasterMH: [false],
      RefugeeIssues: [false],
      SchizophrenicDisorders: [false],
      SelfharmBehaviors: [false],
      SeverelyAndPersistentlyMentallyIll: [false],
      SexualDysfunctions: [false],
      SexualPhysicalAbuseAdult: [false],
      SexualPhysicalAbuseChild: [false],
      SleepDisordersInsomnia: [false],
      SocialSkillsTraining: [false],
      SolutionFocusedTherapy: [false],
      SomaticSymptomAndConversionDO: [false],
      SpecialtyCertificationsBariatricAssessments: [false],
      SpecialtyCertificationsBriefSolutionFocused: [false],
      SpecialtyCertificationsCAP: [false],
      SpecialtyCertificationsCBT: [false],
      SpecialtyCertificationsCognitiveProcessingForTrauma: [false],
      SpecialtyCertificationsDBT: [false],
      SpecialtyCertificationsEAP: [false],
      SpecialtyCertificationsLifeCoaching: [false],
      SpecialtyCertificationsOther: [false],
      SpecialtyCertificationsRedCrossDisasterMH: [false],
      SpecificLearningDisabilities: [false],
      SpecificPhobias: [false],
      SubstanceAlcoholChemicalAbuse: [false],
      TBI: [false],
      TFCBT: [false],
      TMS: [false],
      TTCBT: [false],
      TargetedCaseManagement: [false],
      TelementalHealth: [false],
      TicdisordersTourettes: [false],
      TransgenderIssues: [false],
      Trichotillomania: [false],
      WomensIssues: [false],
      WorkerCompDisability: [false],
      WorkersCompDisability: [false],
      ageGroupServed: [''],
      areasOfClinicalSpecialties: [false],
      certNumber: [''],
      code1BoardCertPri: [null],
      certifyingBoardAlliedHealth: [null],
      dateBoardCertPrimary: [null],
      dateIntentionToTest: [''],
      isBoardCertPrimarySpec: [false, [Validators.required]],
      isLifetimeCert: [false],
      // isNotBoardCertPri: ['Additional training required', [Validators.required]],
      isNotBoardCertPri: [
        'Additional training required',
        this.requiredIfValidator(() => !formGroup.get("isBoardCertPrimarySpec").value)
      ],
      mentalHealthType: [''],
      primaryCode1: [null],
      primaryCode2: [null],
      primaryCode3: [null],
      primaryExpDate: [null],
      primaryInitialCert: [null],
      primaryReCert: [null],
      primarySpecialty: [null],
      intentionDate: [null],
      haveTaxCode: [false],
      MOC_Participant: [false],
      taxonomyType: [null],
      taxonomyClassification: [null],
      taxonomySpecialization: [null],
      attachments: [[]]
    });
    formGroup.get('isBoardCertPrimarySpec').valueChanges.subscribe((change) => {
      if (change) {
        // we do the same thing in specialityValue method too but that's not enough, because when that method(specialityValue) is running,
        // we can't access to newly created spacialities, so we have to do it here as well.
        formGroup.get('isNotBoardCertPri').clearValidators();
        formGroup.get('isNotBoardCertPri').patchValue(null);
        formGroup.get('intentionDate').clearValidators();
        formGroup.get('intentionDate').setErrors(null);
      } else {
        formGroup.get('isNotBoardCertPri').setValidators(Validators.required);
        // formGroup.get('code1BoardCertPri').setValidators(Validators.required);
        // formGroup.get('certifyingBoardAlliedHealth').setValidators(Validators.required);
        // formGroup.get('primaryInitialCert').setValidators(Validators.required);
      }
    })
    return formGroup;
  }

  requiredIfValidator(predicate) {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  FormValueChange() {

  }

  SpecialityValue(informations: SpecialityData[], providerType: string): FormArray {
    const formArray = this.builder.array([]);
    if (informations) {
      informations.forEach((info) => {
        let formGroup = this.builder.group({});
        if (!Object.keys(info).includes('primaryExpDate')) {
          formGroup.setControl('primaryExpDate', new FormControl(''))
        }
        for (let item of Object.keys(info)) {
          if (this.requiredList.indexOf(item) >= 0) {
            if (this.dateList.indexOf(item) >= 0) {
              formGroup.setControl(item, new FormControl(this.service.ConvertStringToDate(info[item]), Validators.required));
            } else {
              if (item === 'isNotBoardCertPri') {
                if (info.isBoardCertPrimarySpec === 'Yes' || info.isBoardCertPrimarySpec) {
                  formGroup.setControl(item, new FormControl(info[item]));
                } else {
                  formGroup.setControl(item, new FormControl(info[item], Validators.required));
                  if (formGroup.controls.isNotBoardCertPri.value == 'Intention to test on date below') {
                    formGroup.controls.intentionDate.setValidators(Validators.required);
                  }
                }
              } else {
                formGroup.setControl(item, new FormControl(info[item], Validators.required));
              }
            }
          } else {
            if (this.dateList.indexOf(item) >= 0) {
              formGroup.setControl(item, new FormControl(this.service.ConvertStringToDate(info[item])));
            }
            else {
              if (item === 'isLifetimeCert') {
                if (!info[item]) {

                  formGroup.setControl(item, new FormControl(false));
                } else {
                  formGroup.setControl(item, new FormControl(true));
                }
              } else {
                formGroup.setControl(item, new FormControl(info[item]));
              }
            }
          }
        }
        formGroup.setControl('attachments', new FormControl(info.attachments ? info.attachments : []));
        formGroup.get('isBoardCertPrimarySpec').patchValue(true)
        // formGroup.get('primarySpecialty').patchValue(PROVIDER_TYPE_ABBR[providerType])
        formGroup.get('isBoardCertPrimarySpec').valueChanges.subscribe((change) => {
          if (change) {
            formGroup.get('isNotBoardCertPri').clearValidators();
            formGroup.get('isNotBoardCertPri').setErrors(null);
            // we patch null value to isNotBoardCertPri so if it was on 'intention '
            // we do the same thing in createSpacalityData method too, because when our method(specialityValue) is running,
            // we can't access to newly created spacialities, so we have to do it there as well.
            formGroup.get('isNotBoardCertPri').patchValue(null);
            formGroup.get('intentionDate').clearValidators();
            formGroup.get('intentionDate').setErrors(null);
          } else {
            formGroup.get('isNotBoardCertPri').setValidators(Validators.required);
          }
          formGroup.updateValueAndValidity();
        })
        if (!formGroup.get("dateBoardCertPrimary"))
          formGroup.setControl("dateBoardCertPrimary", new FormControl(null));

        if (!formGroup.get("primaryInitialCert"))
          formGroup.setControl("primaryInitialCert", new FormControl(null));

        if (!formGroup.get("primaryReCert"))
          formGroup.setControl("primaryReCert", new FormControl(null));

        formArray.push(formGroup);
      })
    }
    this.specialityData = formArray;
    return formArray;
  }

  FormArrayPatchValue(item: string, arg1: any) {
    throw new Error("Method not implemented.");
  }

  get specialityDataArray() {
    return <FormArray>(this.specialityForm as FormGroup).get('specialityData');
  }

  get specialityDataArrCntrls() {
    return ((this.specialityForm as FormGroup).get('specialityData') as FormArray).controls as FormGroup[];
  }

  // Actions
  /**
   * Fire when an input field is changed
   *  You must (change)="onChange()" to existing fields on the form
   * @param event input parameter change event
   */
  onChange(event) {
    let speciality = (this.specialityForm as FormGroup).value;
    this.ConvertDate(speciality)
    this.onChangeSpecialty.emit(speciality);
  }

  getSpecialtyAttachments(index: number) {
    return this.specialityForm.controls.specialityData['controls'][index].controls.attachments.value ?
      this.specialityForm.controls.specialityData['controls'][index].controls.attachments.value : [];
  }

  onChangeSpecialtyAttachment(event, index: number) {
    this.specialityForm.controls.specialityData['controls'][index].controls.attachments.setValue(event);
    this.onChange(event);
  }

  ConvertDate(info: Specialties) {
    for (let arrayItem of info.specialityData) {
      for (let key of Object.keys(arrayItem)) {
        if (this.dateList.indexOf(key) >= 0) {
          arrayItem[key] = this.service.ConvertDateToString(arrayItem[key]);
        }
      }
    }
  }

  primarySpecialty(index: number): string {
    let primarySpecialty = this.specialityForm.controls.specialityData['controls'][index].controls.primarySpecialty.value;
    if (!primarySpecialty || primarySpecialty.indexOf('0') < 0) return primarySpecialty;
    return PROVIDER_TYPE_ABBR[primarySpecialty];
  }

  primaryCode3(index: number): string {
    return this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3.value
  }

  getSpecialty(index: number): string {
    let primarySpecialty = this.primarySpecialty(index);
    let specialty = '';
    switch (primarySpecialty) {
      case 'MD/DO':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode1 && this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode1.value ?
          MD_DO[this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode1.value] : '';
        break;
      case 'DPM':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2 && this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2.value ?
          DPM[this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2.value] : '';
        break;
      case 'DC':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2 && this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2.value ?
          DC[this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2.value] : '';
        break;
      case 'DDS/DMD':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2 && this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2.value ?
          DDS_DMD[this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode2.value] : '';
        break;
      case 'Allied Health':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3 && this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3.value ?
          ALLIED_PROVIDER_TYPE[this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3.value] : '';
        break;
      default:
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3 && this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3.value ?
          ALL_SPECIALTIES[this.specialityForm.controls.specialityData['controls'][index].controls.primaryCode3.value] : '';
        break;
    }
    return specialty;
  }

  getCertifyning(index: number): string {
    let primarySpecialty = this.primarySpecialty(index);
    let specialty = '';
    switch (primarySpecialty) {
      case 'MD/DO':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri && this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value ?
          MD_DO_BOARD[this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value] : '';
        break;
      case 'DPM':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri && this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value ?
          DPM_BOARD[this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value] : '';
        break;
      case 'DC':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri && this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value ?
          DC_BOARD[this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value] : '';
        break;
      case 'DDS/DMD':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri && this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value ?
          DDS_DMD_BOARD[this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value] : '';
        break;
      case 'Allied Health':
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.certifyingBoardAlliedHealth && this.specialityForm.controls.specialityData['controls'][index].controls.certifyingBoardAlliedHealth.value ?
          Allied_Health_BOARD[this.specialityForm.controls.specialityData['controls'][index].controls.certifyingBoardAlliedHealth.value] : '';
        break;
      default:
        specialty = this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri && this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value ?
          ALL_BOARDS[this.specialityForm.controls.specialityData['controls'][index].controls.code1BoardCertPri.value] : '';
        break;
    }
    return specialty;
  }

  getSpecialtiesAttachments(){
    let attachments = [];
    if(this.specialityInfo && this.specialityInfo.specialityData){
      for (let i = 0; i < this.specialityInfo.specialityData.length; i++) {
        if (this.specialityInfo.specialityData[i].attachments) {
          attachments = attachments.concat(this.specialityInfo.specialityData[i].attachments);
        }
      }
    }
    return attachments;
  }

}
