import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DashboardState } from '../view-models/dashboard-state';
import { ExpirationAlerts } from '../view-models/expiration-alerts';
import { ReferenceRequests } from '../view-models/reference-requests';
import { OrganizationDashboardState } from '../view-models/organization-dashboard.state';
import { ProviderCertAlerts } from '../view-models/provider-cert-alert';
import { map } from "rxjs/operators";

@Injectable()
export class DashboardService {

    constructor(
        public http: HttpClient
    ) { }
    GetCertExpirations(): Promise<ExpirationAlerts[]> {
        return this.http.get(`${environment.API_URL}users/cert-alerts`)
            .toPromise()
            .then((response: ExpirationAlerts[]) => {
                return response;
            })
    }
    GetState(): Promise<DashboardState> {
        return this.http.get(`${environment.API_URL}users/dashboard-stats`)
            .toPromise()
            .then((response: DashboardState) => {
                return response;
            })
    }
    GetReferences(type): Promise<ReferenceRequests[]> {
        return this.http.get(`${environment.API_URL}peer-references/${type}`)
            .toPromise()
            .then((response: ReferenceRequests[]) => {
                return response;
            });
    }

    GetOrganizationDashboardState(): Promise<OrganizationDashboardState> {
        return this.http.get(`${environment.API_URL}users/dashboard-stats`)
            .toPromise()
            .then((response: OrganizationDashboardState) => {
                return response;
            })
    }

    getProviderCertAlerts(): Promise<ProviderCertAlerts[]> {
        return this.http.get(`${environment.API_URL}users/provider-cert-alerts`)
            .toPromise()
            .then((response: ProviderCertAlerts[]) => {
                return response;
            })
    }

    removeExpiration(data, certId) {
        return this.http.put(`${environment.API_URL}users/provider-cert-alerts/${certId}`, data).pipe(map(
          res => { return res; }
        ));
    }
}